/**
 * Renders the OrdersView of the App
 */
festivalApp.directive("ordersIndex",["Order",function(Order){

    return {
        restrict: "E",
        templateUrl: "/templates/ordersIndex.html",
        link: function(scope) {
            scope.currentPage = 1;
            scope.numPerPage = 20;
            scope.maxSize = 5;
            scope.from = scope.currentPage - 1;

            scope.pageChanged = function () {
                scope.from = (scope.currentPage - 1) * scope.numPerPage;
            };


        }

    }

}]);
