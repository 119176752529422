/**
 * Kommentarliste
 */
festivalApp.directive("commentList",["$rootScope","Usernames","Comment",function($rootScope,Usernames,Comment){

    return {
        restrict: "E",
        templateUrl: "/templates/commentList.html?7",
        scope: {
            model: "=",
            show: "@"
        },
        link: function (scope) {


            scope.user = $rootScope.myself;

            scope.showAll = false;

            scope.triggerShowAll = function(){
                scope.showAll = true;
            };

            scope.removeComment = function(index) {
                var comment = new Comment(scope.model.comments[index]);
                comment.$delete(function(){
                    scope.model.comments.splice(index,1);
                });
            };

        }
    }

}]);
