/**
 * Shows a Favicon
 */
festivalApp.directive("assignmentOverview",[function(){

    return {
        restrict: "EA",
        templateUrl: "/templates/volunteerAssignmentOverview.html",
        link:function(scope) {

            scope.resolvedType = function(type) {
                return (type == "A") ? "Zugewiesen" : (type == "S") ? "Empfehlung" : "Empfohlen und Zugewiesen";
            };

            scope.resolvedColor = function(type) {
                return (type == "A") ? "btn-success" : (type == "S") ? "btn-primary" : "btn-success";
            };

        }
    }

}]);
