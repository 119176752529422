/**
 * @ngdoc controller
 * @name artists:artistDetailCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('artists')
    .controller('artistDetailCtrl',
        ["$rootScope","$scope","$confirm","$routeParams","$uibModal","RequestError","Artist",
            function($rootScope,$scope,$confirm,$routeParams,$uibModal,RequestError,Artist){

                if ($routeParams.artistId !== "new") {
                    $scope.artist = Artist.get({id:$routeParams.artistId});
                } else {
                    $scope.artist = new Artist();
                }


                /**
                 * Performs the SAVE ARTIST action
                 * @param member
                 */
                $scope.saveArtist = function(artist) {
                    if (artist.id) {
                        artist.$update(function(artist){
                            $scope.artist = artist;
                        });
                    } else {
                        artist.$save(function(artist){
                            $scope.artist = artist;
                        });
                    }
                };

                /**
                 * Performs the DELETE MEMBER action
                 * @param member
                 */
                $scope.deleteArtist = function(artist) {
                    $confirm({text:"Möchtest Du diese Band wirklich löschen?"}).then(function(){
                        var id = artist.id;
                        artist.$delete();
                    });
                };

            }]);
