angular.module('persons')
    .factory('Image', function ($resource) {
        return $resource('/api/web/images/:id/', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            }
        });
    }).$inject = ["$resource"];