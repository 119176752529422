angular.module('team')
    .factory('TeamMember',["$resource","DateConverter", function ($resource,DateConverter) {
        var TeamMember = $resource('/api/web/team_members/:id', { id: '@id' }, {
            filtered: {
                method: "GET",
                params: { filter: '@filter', value: "@value"},
                isArray: true,
                url: "/api/web/team_members/:filter/:value",
                interceptor: { response: DateConverter.parseResponseDates}
            },
            update : {
                method: 'PUT',
                interceptor: { response: DateConverter.parseResponseDates}
            },
            query: {
                method: 'GET',
                isArray: true,
                interceptor: { response: DateConverter.parseResponseDates}
            },
	        makeCurrentPerson: {
		        method: 'GET',
		        url: "/api/web/team_members/makeCurrent/:id/",
		        interceptor: {response: DateConverter.parseResponseDates}
	        }
        });

        //TeamMember.prototype.filtered = function(filter,value) {
        //    ret
        //};

        /**
         * Adds member to given group
         * @param group
         */
        TeamMember.prototype.appendToGroup = function(group) {
            if (typeof(this.team_groups) == "undefined") this.team_groups = [];
            this.team_groups.push(group);
            if (this.id) this.$update();
        };
        /**
         * Removes the member from given group
         * @param group
         * @returns {boolean}
         */
        TeamMember.prototype.removeFromGroup = function(group) {
            if (typeof(this.team_groups) == "object")
            for (var i=0;i<this.team_groups.length;i++) {
                if (group.id == this.team_groups[i].id) {
                    this.team_groups.splice(i,1);
                    if (this.id) this.$update();
                    return true;
                }
            }
        };
        /**
         * Toggles the group Membership
         * @param group
         */
        TeamMember.prototype.toggleGroup = function(group){
            if (this.hasGroup(group)) this.removeFromGroup(group);
            else this.appendToGroup(group);
        };
        /**
         * Checks if Member belongs to given group
         * @param group
         * @returns {boolean}
         */
        TeamMember.prototype.hasGroup = function(group){
            if (typeof(this.team_groups) == "object")
            for (var i=0;i<this.team_groups.length;i++) {
                if (group.id == this.team_groups[i].id) return true;
            }
            return false;
        };

        return TeamMember;
    }]);