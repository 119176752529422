angular.module('team')
    .factory('TeamGroupMember',["$resource",function ($resource) {
        return $resource('/api/web/team_group_members/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            }
        });
    }]);