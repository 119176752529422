angular.module('dashboards')
    .factory('Dashboard', function ($resource) {
        return $resource('/api/web/dashboard/:id', { id: '@id' }, {
            query: {
                method: 'GET',
                isArray: false,
            },
            update: {
                method: "PUT",
            }
        });
    }).$inject = ["$resource"];