(function(){
    "use strict";

    /**
     * @ngdoc controller
     * @name workshops:workshopApplicationsCtrl
     *
     * @description
     *
     *
     * */
    angular.module('workshops').controller('workshopApplicationsCtrl',workshopApplicationsCtrl);


    workshopApplicationsCtrl.$inject = ["$location","$rootScope","$confirm","$route","RequestError","WorkshopApplication","WorkshopApplicationGenres","$uibModal"];

    function workshopApplicationsCtrl($location,$rootScope,$confirm,$route,RequestError,WorkshopApplication,WorkshopApplicationGenres, $uibModal) {

        var self = this;

        self.listsCurrentEvent = $route.current.locals.currentEvent;

        self.applications = self.listsCurrentEvent ?
            WorkshopApplication.query(angular.noop,RequestError.handle) :
            WorkshopApplication.past(angular.noop,RequestError.handle);

        self.genreOptions = WorkshopApplicationGenres;
        self.filteredApplications = filteredApplications;

	    self.stateFilter = false;
	    self.genreFilter = false;
	    self.ratingFilter = false;
	    self.setStateFilter = setStateFilter;
	    self.setGenreFilter = setGenreFilter;
	    self.setRatingFilter = setRatingFilter;

	    self.pageChanged = pageChanged;
        self.genreFor = genreFor;
        self.stateOptionsClass = stateOptionsClass;
        self.stateOptionsName = stateOptionsName;
	    self.setApplicationState = setApplicationState;
	    self.newWorkshopApplication = newWorkshopApplication;
	    self.delete = deleteApplication;
	    self.cloneToCurrent = cloneToCurrent;
	    self.updateRating = updateRating;
	    self.hasRightToEdit = hasRightToEdit;

        self.stateOptions = [
            {value: "eingegangen",name: "Unbearbeitet",css:"primary"},
            {value: "abgelehnt",name: "Abgelehnt",css:"danger"},
            {value: "angenommen",name: "Angenommen",css:"success"},
            {value: "abgewickelt",name: "Abgerechnet",css:"warning"}
        ];

	    function hasRightToEdit() {
		    return $rootScope.can(['admin-administrate', 'workshops-manage']);
	    }


	    /**
         * Setter for state filter
	     * @param state
	     */
	    function setStateFilter(state) {
		    self.stateFilter = state;
	    }

	    /**
         * Setter for genre filter
	     * @param genre
	     */
	    function setGenreFilter(genre) {
		    self.genreFilter = genre;
	    }

	    function setRatingFilter(rating) {
		    self.ratingFilter = rating;
	    }

	    /**
         * For Pagination (if we implement it at one point)
	     */
	    function pageChanged() {
            self.from = (self.currentPage - 1) * self.numPerPage;
        }

	    /**
         * Returns the value for the genre selector
	     * @param application
	     * @return {*}
	     */
        function genreFor(application) {
            switch (application.genre) {
                case 'music' : return "Instrumente / Gesang";
                case 'dance' : return "Körperarbeit / Tanz";
                case 'handicraft' : return "Handwerk / Kunst";
                case 'healing' : return "Geist und Seele";
            }
        }

	    /**
         * Returns the value for the color of the state options
	     * @param value
	     * @return {*}
	     */
        function stateOptionsClass(value) {
            for (var i=0;i<self.stateOptions.length;i++) {
                if (self.stateOptions[i].value == value) return "btn-"+self.stateOptions[i].css;
            }
            return "btn-default";
        }

	    /**
         * Returns the value for the name of the state options
	     * @param value
	     * @return {*}
	     */
        function stateOptionsName(value) {
            for (var i=0;i<self.stateOptions.length;i++) {
                if (self.stateOptions[i].value == value) return self.stateOptions[i].name;
            }
            return "Status setzen!";
        }

	    /**
         * This is the filter that is used here
	     * @return {*}
	     */
	    function filteredApplications() {
            if (self.stateFilter === false && self.genreFilter === false && self.ratingFilter === false) return self.applications;
            var applications = [];
            angular.forEach(self.applications,function(application) {
                if ((!self.stateFilter || application.status == self.stateFilter.value) &&
                    (!self.genreFilter || application.genre == self.genreFilter.value) &&
	                (!self.ratingFilter || application.internes_rating >= self.ratingFilter)) {
                    applications.push(application);
                }
            });

            return applications;

        }

	    /**
         * Setter for the application state
	     * @param application
	     * @param state
	     */
	    function setApplicationState(application,state) {
		    if (self.hasRightToEdit()) {
			    if (state == "angenommen") {
				    application.status = state;
				    application.activated = 1;
				    application.$update();
			    } else if (state != "abgewickelt" && application.activated == 1) {
				    $confirm({
					    text: 'Möchtest Du den Status des Workshops wirklich ändern? Alle Termin- und Raumzuweisungen gehen verloren?'
				    }).then(function () {
					    application.status = state;
					    application.activated = 0;
					    application.raum = null;
					    application.room = null;
					    application.date = null;
					    application.start_time = null;
					    application.end_time = null;
					    application.$update();
				    });
			    } else {
				    application.status = state;
				    application.$update();
			    }
		    }
        }

	    /**
         * New Workshop creator
	     */
	    function newWorkshopApplication(){
            self.application = new WorkshopApplication();
        }


	    /**
         * deletes a workshop application
	     * @param application
	     * @param idx
	     */
        function deleteApplication(application,idx) {

		    if (self.hasRightToEdit()) {
			    $confirm({text: "Möchtest Du diesen Eintrag wirklich komplett löschen?"}).then(function () {
				    application.$delete(function () {
					    self.applications.splice(idx, 1);
				    });
			    });
		    }
        }


	    /**
         * Clones a past application to the current year
	     * @param application
	     */
	    function cloneToCurrent(application) {
		    if (self.hasRightToEdit()) {
			    $confirm({text: "Möchtest Du diesen Workshop für das aktuelle Jahr übernehmen?"}).then(function () {
				    application.$cloneToCurrent(function (data) {
					    alert("Der Workshop wurde dem aktuellen Jahr hinzugefügt.");
					    $location.path("/workshops/applications/" + data.id);
				    });
			    });
		    }
        }

        function updateRating(application) {
	        if (self.hasRightToEdit()) {
		        application.$update();
	        }
        }

        self.notificate = function(application) {
	        if (self.hasRightToEdit()) {
		        self.createEmailNotificationModal = $uibModal.open({
			        animation: true,
			        controller: "messageCreatorCtrl",
			        controllerAs: "ctrl",
			        templateUrl: "/templates/messageCreatorFormWorkshop.html",
			        resolve: {
				        application: function () {
					        return application;
				        },
				        route: function () {
					        return "/api/web/workshop_applications/" + application.id + "/send_mail";
				        },
				        message: function () {
					        return {
						        placeholder: "Gebe hier zusätzliche Infos für den Workshopleiter ein.",
						        button: "Abschicken",
						        title: "Workshopleiter informieren"
					        };
				        }
			        }
		        });
	        }
        }
    }

})();