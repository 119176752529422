(function() {

	'use strict';

	angular.module('dashboards')
		.value('DashboardPanels', [

		{
			content : {
				icon:  "fa-users",
				title: "Kontakte",
				link: "#/team/members",
				text:  "Die Liste aller Orgamitglieder mit ihren Kontaktdaten"
			},
			typeClass: "primary",
			group: 1
		},

		{
			content : {
				icon:  "fa-calendar",
				title: "Kalender",
				link: "https://basecamp.com/1779406/calendar",
				text: "Alle wichtigen Termine auf einen Blick"
			},
			typeClass: "warning",
			group: 1
		},

		{
			content : {
				icon:  "fa-file",
				title: "Protokolle",
				link: "https://ancient-trance.de/owncloud/index.php/apps/files/?dir= %2F_PROTOKOLLE",
				text: "Die Protokolle aller Kreistreffen für alle zur Einsicht"
			},
			typeClass: "danger",
			group: 1
		},

		{
			content : {
				icon:  "fa-comments-o",
				title: "Forum",
				link: "https://basecamp.com/1779406/projects/3663030",
				text: "Plattform für kreisübergreifende Themen und Diskussionen"
			},
			typeClass: "warning",
			group: 1
		},

		{
			content : {
				icon:  "fa-map",
				title: "Organigramm",
				link: "http://prezi.com/swc3nmipbve5/?utm_campaign=share&utm_medium=copy",
				text: "Eine Übersicht über unsere Kreisstruktur"
			},
			typeClass: "info",
			group: 1
		},

		{
			content : {
				icon:  "fa-tree",
				title: "Kreise",
				link: "#/team/groups",
				text: "Übersicht unserer Kreise mit ihren Mitgliedern und Rollen"
			},
			typeClass: "primary",
			group: 1
		},

		{
			content : {
				icon:  "fa-suitcase",
				title: "Stellenbörse",
				link: "https://basecamp.com/1779406/projects/3663030/todolists/45872424",
				text: "Monentan unbesetzten Stellen / Fokusse / Kreisrollen"
			},
			typeClass: "warning",
			group: 1
		},

		{
			content : {
				icon:  "fa-file-o",
				title: "Dokumente",
				link: "https://www.ancient-trance.de/owncloud/index.php/apps/files/",
				text: "Zentrale, auf dem Festivalserver gespeicherte Dokumente."
			},
			typeClass: "danger",
			group: 1
		},

		{
			content : {
				icon:  "fa-envelope-o",
				title: "Mails",
				link: "https://ancient-trance.de/webmail/",
				text: "Lese / Schreibe Deine E-Mails online."
			},
			typeClass: "info",
			group: 1
		},

		{
			content : {
				icon:  "fa-fire-extinguisher",
				title: "Konfliktmanagement",
				modal: "help.conflicts.html",
				text: "Hilfestellung bei der Lösungssuche für Probleme im Team (eigene oder beobachtete)"
			},
			typeClass: "info",
			group: 1
		},

		{
			content : {
				icon:  "fa-globe",
				title: "Homepage",
				link: "https://ancient-trance.de",
				text: "Die offizielle Homepage des Festivals."
			},
			typeClass: "info",
			group: 1
		},

		{
			content : {
				icon:  "fa-bell-slash",
				title: "Abwesenheit",
				link: "https://basecamp.com/1779406/projects/3663030/todolists/39567705",
				text: "Welche Orgas sind zur Zeit nicht in Leipzig / nicht erreichbar <em>(und wer vertritt sie)</em>?"
			},
			typeClass: "warning",
			group: 1
		},

		{
			content : {
				icon:  "fa-question",
				title: "Wiki",
				link: "https://wiki.ancient-trance.de",
				text: "Unser „Festival-Wikipedia“ mit Antworten auf fast alle Fragen und viel Anreiz zum stöbern."
			},
			typeClass: "success",
			group: 1
		},

		{
			content : {
				icon:  "fa-photo",
				title: "Teamportraits",
				link: "https://wiki.ancient-trance.de/teamportrait",
				text: "Ein Album mit viel unnützem Wissen über die „Gesichter des Festivals“."
			},
			typeClass: "success",
			group: 1

		}
	]);

})();