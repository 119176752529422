/**
 * Shows a Favicon
 */
festivalApp.directive("merchantTechnical",function(){

	return {
		restrict: "A",
		template:
		"<span ng-repeat='item in items'><span ng-bind-html='captionFor(item)'></span>" +
        "<span ng-if='!$last'>,</span>"+
		"<br ng-if=\"(br!=='false')\"><span ng-if=\"(br==='false')\">&nbsp;</span>" +
		"</span>",
		scope: {
			data: "@",
			br: "@"
		},
		link: function(scope) {
			scope.items = angular.fromJson(scope.data);

			var captions = {
				"600W" : "SchuKo 600W",
				"3kW" : "SchuKo 3kW",
				"9kW" : "CEE 9kW",
				"wasser" : "Wasseranschluss",
				"abwasser" : "Abwasseranschluss",
                "unversorgt" : "Kein Strom/Wasser/Abwasser nötig",
                "gas" : "Betreibt eine Gasanlage"
			};

			scope.captionFor = function(item) {
				if (captions[item]) {
					return captions[item];
				} else return item;
			}

		}
	}


});
