festivalApp.directive("helpWhenSymbol",function(){
    return {
        restrict:"AE",
        template:"<i ng-repeat='symbol in symbols()' ng-class='symbol'></i>&nbsp;<small ng-bind='caption()'></small><br>" +
        "<small><small>{{tooltip()}}</small></small>",
        scope: {
            volunteer:"="
        },
        link:function(scope){

            scope.symbols = [];

            var data = {
                immediately :
                    {
                        tooltip: "Ab sofort, ich bin in Leipzig und gern bereit mich schon langfristig vorher einzubringen.",
                        caption: "SOFORT",
                        symbols: [["fa","fa-flash","text-success"]]
                    },
                setup_only :
                    {
                        tooltip: "Ausschließlich vor dem Festival",
                        caption: "VORHER",
                        symbols: [["fa","fa-arrow-up","text-primary"]]
                    },
                setup_during :
                    {
                        tooltip: "Vor und während des Festivals (Anreise bis spätestens Mittwoch abend, 10. Aug)",
                        caption: "VORHER, WÄHREND",
                        symbols: [["fa", "fa-arrow-up", "text-primary"]]
                    },
                during_only :
                    {
                        tooltip: "Ausschließlich während des Festivals",
                        caption: "nur WÄHREND",
                        symbols: [["fa", "fa-spinner","fa-spin", "text-warning"]]
                    },
                during_after :
                    {
                        tooltip: "Während und nach dem Festival (Abreise frühestens Dienstag Nachmittag, 16. Aug)",
                        caption: "WÄHREND und DANACH",
                        symbols: [["fa", "fa-spinner","fa-spin", "text-warning"],["fa", "fa-arrow-down","text-danger"]]
                    },
                after_only :
                    {
                        tooltip: "Ausschließlich nach dem Festival / Abbauphase (Abreise frühestens Mittwoch abend, 17. Aug)",
                        caption: "nur DANACH",
                        symbols:  [["fa", "fa-arrow-down","text-danger"]]
                    },
                setup_after :
                    {
                        tooltip: "Vor und nach dem Festival/ Auf-& Abbauphase (Insgesamt mindestens drei Tage)",
                        caption: "VORHER und DANACH",
                        symbols: [["fa", "fa-arrow-down","text-danger"],["fa","fa-arrow-up","text-primary"]]
                    }
            };

            scope.tooltip = function(){
                return (data[scope.volunteer.help_when]) ? data[scope.volunteer.help_when].caption : "";
            };

            scope.caption = function(){
                return (data[scope.volunteer.help_when]) ? data[scope.volunteer.help_when].caption : "N/A";
            };

            scope.symbols = function(){
                return (data[scope.volunteer.help_when]) ? data[scope.volunteer.help_when].symbols : [];
            };



        }
    }
});