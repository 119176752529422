festivalApp.directive("fileUpload", [function () {
    return {
        scope: {
            onUpdate: "&",
            model: "="
        },
        link: function (scope, element) {
            element.bind("change", function (changeEvent) {
                var reader = new FileReader();
                reader.onload = function (loadEvent) {
                    scope.onUpdate({file:loadEvent.target.result, model: scope.model});
                };
                reader.readAsDataURL(changeEvent.target.files[0]);
            });
        }
    }
}]);