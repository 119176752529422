(function () {
	"use strict";


	angular.module('team').controller('teamMembersCtrl', teamMembersCtrl);
	teamMembersCtrl.$inject = ["$rootScope", "$uibModal", "$confirm", "$filter", "RequestError", "TeamMember", "TeamGroup", "TeamGroupType", "TeamMemberStatus", "localStorageService"];

	function teamMembersCtrl($rootScope, $uibModal, $confirm, $filter, RequestError, TeamMember, TeamGroup, TeamGroupType, TeamMemberStatus, localStorageService) {

		var self = this;

		self.groups = [];
		self.members = [];
		self.groupTypes = [];
		self.filteredMembers = [];

		self.can = $rootScope.can;

		/**
         * Get All the team (initially)
         */
		self.members = TeamMember.query(function () {
			applyFilters();

		}, RequestError.handle);

		self.membersChecked = [];

		self.groupTypes = TeamGroupType.query(function () {
			applyFilters();
		});

		self.memberStates = TeamMemberStatus.query();
		self.typesSelected = localStorageService.get("teamMembersCtrl.typesSelected") || [];

		self.toggleTypeFilter = function (type) {
			if (self.typesSelected.indexOf(type) >= 0) {
				self.typesSelected.splice(self.typesSelected.indexOf(type), 1);
			} else {
				self.typesSelected.push(type);
			}
			localStorageService.set("teamMembersCtrl.typesSelected", self.typesSelected);
			applyFilters();
		};

		self.isActiveTypeFilter = function (type) {
			return self.typesSelected.indexOf(type) >= 0 ? "active" : "";
		};

        /**
         * Member list for exportbutton
         * @returns {*}
         */
        self.exportMembers = function () {
	        return (self.membersChecked.length) ? self.membersChecked : self.filteredMembers;
        };

		self.makeCurrentPerson = function (member) {
			new TeamMember(member).$makeCurrentPerson(function (result) {
				member.is_current_person = true;
			});
		};

		self.hasRightToEdit = function (id) {
            return ($rootScope.hasRole(["admin","team_manager"]) || id == $rootScope.myself.id);
        };

		self.groups = TeamGroup.query(function () {
			applyFilters();
		});

		self.groupSelected = null;

		self.selectGroup = function (group) {
			self.groupSelected = group;
			applyFilters();
        };

		self.groupTypeColorFor = function (group) {

			for (var i = 0; i < self.groupTypes.length; i++) {
				if (group.team_group_type_id === self.groupTypes[i].id) {
					return self.groupTypes[i].color;
				}
			}

			return 'info';
		};

		function applyFilters() {

			if (self.members.length && (self.groups.length && self.groupSelected) || (self.memberStates && self.typesSelected.length)) {

				self.filteredMembers = $filter("filter")(self.members, function (member) {

					var hasGroup = false;
					var hasType = self.typesSelected.indexOf(member.status_id) >= 0 || !self.typesSelected.length;

					if (self.groups.length && self.groupSelected) {
						angular.forEach(member.team_groups, function (group) {
							if (group.id === self.groupSelected.id) {
								hasGroup = true;
							}
						});
					} else {
						hasGroup = true;
					}

					return hasGroup && hasType;

				});

			} else {

				self.filteredMembers = self.members;

			}

		}

	}

})();