angular.module('market').controller("parkingPermissionGeneratorCtrl",[
	"$scope", "$uibModalInstance", "$http", "$window", "Person", "ParkingPermission", "assignedPerson",
	function ($scope, $uibModalInstance, $http, $window, Person, ParkingPermission, assignedPerson) {
        var self = this;

		console.log(assignedPerson);

		self.permission = null;

		if (assignedPerson) {
			var name = assignedPerson.firstname + " " + assignedPerson.lastname;
			var type;
			var areas;
			var phone;
			switch (assignedPerson.type.name) {
				case "artist" :
				case "teacher" :
					type = "artist";
					break;
				case "stage" :
				case "security" :
				case "serviceprovider" :
					type = "service";
					break;
				case "vendor" :
					type = "merchant";
					name = assignedPerson.market_applications[0].firma;
					phone = assignedPerson.market_applications[0].festival_telefon;
					break;
				case "staff" :
				case "crew" :
					type = "orga";
					areas = "aaa";
					if (assignedPerson.team_members.length) {
						phone = assignedPerson.team_members[0].mobile;
					} else if (assignedPerson.volunteer_applications.length) {
						phone = assignedPerson.volunteer_applications[0].mobile;
					}
					break;
			}

			if (assignedPerson.parking_permission) {
				self.permission = assignedPerson.parking_permission;
			}

		}


		if (!self.permission) {
			self.permission = {
				days: ['thursday', 'friday', 'saturday', 'sunday'],
				areas: areas || "",
				type: type || "",
				assigned_to: name || "",
				car_plate: "",
				phone: phone || ""
			};
		}


        var days = ["thursday","friday","saturday","sunday"];


        self.toggleDaySelection = function(day){
            var i = self.permission.days.indexOf(day);
            if (i>-1) self.permission.days.splice(i,1);
            else self.permission.days.push(day);
        };

        self.dayChecked = function(day){
            return self.permission.days.indexOf(day) > -1;
        };


        /**
         * On Save the pass
         */
        self.create = function(){

            var pass = new ParkingPermission(self.permission);

	        if (assignedPerson) {
		        pass.parkable_id = assignedPerson.id;
		        pass.parkable_type = assignedPerson.model;
	        }

	        if (pass.id) {
		        pass.$update(function (permission) {
			        $uibModalInstance.close();
			        $window.open('/parking/' + permission.id + '.pdf');
		        });
	        } else {
		        pass.$save(function (permission) {
			        $uibModalInstance.close();
			        $window.open('/parking/' + permission.id + '.pdf');
		        });
	        }

        };

        /**
         * On Close the modal
         */
        self.closeModal = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }]);