/**
 * Renders an icon for a gender
 */
festivalApp.directive("image", function () {

	return {
		restrict: "A",
		template: "<img ng-if='imageSrc' ng-src='{{imageSrc}}' style='width:100%'>",
		scope: {
			imageModel: "=",
			width: "@"
		},
		link: function (scope) {

			scope.width = (scope.width) ? scope.width : 250;
			console.log(scope.width);
			scope.imageSrc = scope.imageModel && scope.imageModel.id ? "/api/web/images/" + scope.imageModel.id + "/" + scope.width : false;

		}
	}

});
