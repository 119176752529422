(function () {

	"use strict";

	angular.module("pointOfSale").value("ionicColors", {

		stable: {
			name: "item-stable",
			description: "Grau",
			color: "#f8f8f8",
		},
		positive: {
			name: "item-positive",
			description: "Blau",
			color: "#387ef5",
		},
		calm: {
			name: "item-calm",
			description: "Türkis",
			color: "#11c1f3",
		},
		balanced: {
			name: "item-balanced",
			description: "Grün",
			color: "#33cd5f",
		},
		energized: {
			name: "item-energized",
			description: "Gelb",
			color: "#ffc900",
		},
		assertive: {
			name: "item-assertive",
			description: "Rot",
			color: "#ef473a",
		},
		royal: {
			name: "item-royal",
			description: "Lila",
			color: "#886aea",
		},
		dark: {
			name: "item-dark",
			description: "Dunkel",
			color: "#444",
		}
	});

})();