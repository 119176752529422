/**
 * @ngdoc controller
 * @name volunteers:volunteerConfirmedCtrl
 *
 * @description
 *
 *
 * */
angular.module('volunteers')
    .controller('volunteerConfirmedCtrl',
        ["$rootScope","$confirm","$route","$filter","$uibModal","localStorageService","RequestError","Volunteer","VolunteerResort","volunteerStatusValue",
            function($rootScope,$confirm,$route,$filter,$uibModal,localStorageService,RequestError,Volunteer,VolunteerResort,volunteerStatusValue){

                var self = this;

                Volunteer.query(function(r){
                    self.applications = r;
                    self.stateObj = self.filterByState(null);
                },RequestError.handle);

                VolunteerResort.query(function(r){
                    self.resorts = r;
                },RequestError.handle);


                self.panelTitle = "Bestätigte Helfer";

                self.panelSubline = "<p><em>Bereits bestätigte Helfer.</em><br>" +
                    "Filtere sie gerne nach Status. Es gibt u.U. bestätigte Helfer, die, noch keine feste Zuordnung haben.<br>" +
                    "Wenn Du wissen willst, welche Helfer einem bestimmten Bereich zugeordnet sind, wähle im Menü Helfer->Bereiche aus." +
                    "Dort findest Du eine Übersicht über alle Bereiche, inklusive Helfern.</p>" +
                    "<p><strong>Neu:</strong> die <em>bestätigten</em> Helfer sind nach Status und Vorname sortiert.<br>" +
                    "<strong class='text-danger'>Ordnung bitte!:</strong> Bitte entfernt eure nicht mehr benötigten Kommentare!</p>";

                self.showingConfirmed = true;

                self.applicationsChecked = [];
                self.exportVolunteers = function(){
                    return (self.applicationsChecked.length) ? self.applicationsChecked : self.applications;
                };

                self.orderBy = ['stateId','firstname'];
                self.volunteerFilter = localStorageService.get("volunteerFilter");
                self.setFilterPreset = function(value) {
                    localStorageService.set("volunteerFilter",value);
                };

                self.statusFilter = localStorageService.get("volunteerStatusFilter");
                if (!self.statusFilter) self.statusFilter = [];

                self.assignmentFilterStatusCode = localStorageService.get("volunteerByStateFilter");
                self.filterByState = function(state){
                    if (state) {
                        self.assignmentFilterStatusCode = state;
                        localStorageService.set("volunteerByStateFilter",state);
                    }

                    switch (self.assignmentFilterStatusCode) {
                        case "no|no" :
                            self.assignmentFilterStatus = "Ohne zugewiesenem Bereich";
                            self.filteredApplications = $filter('filter')(self.applications,function(application){
                                return (
                                !application.resorts_suggested.length &&
                                !application.resorts_assigned.length &&
                                (!self.statusFilter.length || self.statusFilter.indexOf(application.state) >= 0) &&
                                (!self.helpWhenFilter || self.helpWhenFilter == application.help_when)
                                );
                            });
                            break;
                        case "yes|no" :
                            self.assignmentFilterStatus = "Empfohlen und noch nicht zugewiesen";
                            self.filteredApplications = $filter('filter')(self.applications,function(application){
                                return (
                                application.resorts_suggested.length &&
                                !application.resorts_assigned.length &&
                                (!self.statusFilter.length || self.statusFilter.indexOf(application.state) >= 0) &&
                                (!self.helpWhenFilter || self.helpWhenFilter == application.help_when)
                                );
                            });
                            break;
                        case "yes|yes" :
                            self.assignmentFilterStatus = "Bereits einem Bereich zugewiesen";
                            self.filteredApplications = $filter('filter')(self.applications,function(application){
                                return (
                                application.resorts_suggested.length &&
                                application.resorts_assigned.length &&
                                (!self.statusFilter.length || self.statusFilter.indexOf(application.state) >= 0) &&
                                (!self.helpWhenFilter || self.helpWhenFilter == application.help_when)
                                );
                            });
                            break;
                        case "all" :
                        default:
                            self.assignmentFilterStatus = "Mit und ohne Bereich";
                            self.filteredApplications = $filter("filter")(self.applications,function(application){
                                return (!self.statusFilter.length || self.statusFilter.indexOf(application.state) >= 0) &&
                                (!self.helpWhenFilter || self.helpWhenFilter == application.help_when);
                            });
                            break;
                    }
                };

                self.helpWhenFilter = null;
                self.helpWhenFilterValue ='Alle';
                self.filterByHelpWhen = function(when,value){
                    self.helpWhenFilter = when;
                    self.helpWhenFilterValue = value;
                    self.filterByState();
                };


                self.volunteerStates = volunteerStatusValue;
                self.setVolunteerState = function(application) {
                    new Volunteer(application).$update();
                };

                self.delete = function(application,idx) {
                    $confirm({text:"Möchtest Du diesen Eintrag wirklich komplett löschen? Die Person wird keine Nachricht bekommen!"}).then(function(){
                        application.$delete(function(){
                            self.applications.splice(idx, 1);
                        });
                    });
                };

                self.printPass = function(application) {
                    self.printPassModal = $uibModal.open({
                        animation: true,
                        controller: "passGeneratorCtrl",
                        controllerAs: "ctrl",
                        templateUrl: "/templates/passGenerator.html?7",
                        resolve: {
                            currentPerson: function(){
                                return application.persons[0];
                            },
                            passType: function(){

                                if (application.persons[0].pass) return application.persons[0].pass;

                                var resort = null;
                                for(var i=0;i<self.resorts.length;i++){
                                    if (self.resorts[i].id == application.resorts_assigned[0]) {
                                        resort = self.resorts[i].display_name;
                                        break;
                                    }
                                }
                                return {
                                    catering_options : {
                                        catering_pre:0,
                                        catering_friday:[],
                                        catering_saturday:[],
                                        catering_sunday:[],
                                        catering_post:0
                                    },
                                    days:[],
                                    name: application.firstname,
                                    access: "crew2",
                                    type: "crew",
                                    imageSrc: (application.persons[0].image) ? application.persons[0].image.src : null,
                                    person_id: application.persons[0].id,
                                    resort: resort
                                };

                            }
                        }
                    });
                };


                //self.numPerPage = 50;
                //self.last_page = 1;
                //self.current_page = 1;
                //self.total = null;
                //self.from = 1;
                //self.to = 50;
                //self.pageChanged = function () {
                //    self.from = (self.currentPage - 1) * self.numPerPage;
                //};

        }]);