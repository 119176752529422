angular.module('runs')
    .controller('carsCtrl',
        ["$rootScope","$scope","$confirm","$uibModal","RequestError","Car",
            function($rootScope,$scope,$confirm,$uibModal,RequestError,Car){

                var self = this;

                self.cars = Car.query(angular.noop,RequestError.handle);

                /**
                 * Performs the DELETE MEMBER action
                 * @param member
                 */
                self.delete = function(item) {
                    $confirm({text:"Möchtest Du diesen Eintrag wirklich löschen?"}).then(function(){
                        var id = item.id;
                        item.$delete(function(){
                            for (var i=0;i<self.items.length;i++)
                                if (self.items[i].id == id) self.items.splice(i,1);
                        });
                    });
                };

                self.newCar = function(){
                    $scope.car = {};

                    $scope.save = function(){
                        var car = new Car($scope.car);
                        car.$save(function(car){
                            self.cars.push(car);
                            $scope.modal.close();
                        });
                    };

                    $scope.modal = $uibModal.open({
                        animation: true,
                        templateUrl: "/templates/carForm.html?4",
                        scope: $scope
                    });

                }

            }]);
