(function(){
	"use strict";

	festivalApp.directive('starRating',starRating);

	function starRating() {
		return {
			restrict: "E",
			templateUrl: "/templates/starRating.html",
			scope: {
				model: "=",
				property: "@",
				change: "="
			},
			link: function(scope) {

				scope.classFor = classFor;
				scope.hover = hover;
				scope.setTo = setTo;
				scope.hoverValue = null;

				function classFor(value) {

					var c = ['fa','text-warning'];

					c.push( (value <= parseInt(scope.model[scope.property])  || value <= scope.hoverValue) ? "fa-star" : "fa-star-o" );

					return c;
				}

				function hover(value) {
					scope.hoverValue = value;
				}

				function setTo(value) {
					scope.model[scope.property] = value;
					scope.change(scope.model);
				}


			}
		}
	}

})();