(function(){
    "use strict";

    angular.module('team')
    .controller('teamGroupsDetailCtrl',teamGroupsDetailCtrl);


	teamGroupsDetailCtrl.$inject = ["$rootScope", "$uibModal", "$location", "$confirm", "$routeParams", "RequestError", "TeamMember", "TeamGroup", "TeamGroupRole", "TeamGroupMember", "Role", "TeamGroupType", "Address"];

	function teamGroupsDetailCtrl($rootScope, $uibModal, $location, $confirm, $routeParams, RequestError, TeamMember, TeamGroup, TeamGroupRole, TeamGroupMember, Role, TeamGroupType, Address) {

        var self = this;

        self.messages = [];

        self.groupRoles = TeamGroupRole.query(angular.noop,RequestError.handle);

        self.getGroupRoleName = getGroupRoleName;

	    self.groups = [];
        TeamGroup.query(function(groups){
           self.groups = groups;
           self.groups.unshift({id:null,name:"---",longname:"- kein übergeordneter Kreis -"});
        });
        self.groupTypes = TeamGroupType.query();
        self.members = TeamMember.query();

		self.newAddress = {};

        self.saveGroupMember = saveGroupMember;
	    self.saveGroup = saveGroup;
        self.addMemberToGroup = addMemberToGroup;
        self.hasRightToEdit = hasRightToEdit;
        self.removeMemberFromGroup = removeMemberFromGroup;
        self.deleteGroup = deleteGroup;
		self.addAddress = addAddressToGroup;
		self.deleteAddress = deleteAddressFromGroup;

		if ($routeParams.id !== "new") {
			self.group = {};
			TeamGroup.get({id: $routeParams.id}, function (r) {
				self.group = r;
				sortMembersByGroupRolePosition();
			})
		} else {
			self.group = new TeamGroup();
		}

	    self.editMode = ($routeParams.id !== "new") ? false : true;

        self.newGroup = function() {
            self.group = new TeamGroup();
        };

        self.editGroup = function(group){
            self.group = group;
        };

	    self.backToList = function() {
		    self.group = null;
	    };

        var dropSuccess = function() {
            self.messages = [{message: "Änderungen gespeichert.",type:"success"}];
        };

        var dropError = function(response) {
            self.messages.push({message: "Fehler ("+response.status+"): "+response.data.error,type:"danger"});
        };

        function deleteGroup() {
            $confirm({text:"Möchtest Du diese Gruppe wirklich löschen?"}).then(function(){

                self.group.$delete(function(){
                    alert("Gruppe erfolgreich gelöscht.");
                    $location.path("/team/groups");
                });
            });
        }

	    function saveGroup(group){

            if (!group.parent_id) {
	            group.parent_id = null;
            }

		    if (self.newAddress && self.newAddress.email_corporate) {
			    group.addresses.push(self.newAddress);
		    }

            if (group.id) {
                group.$update(function(group){
                    self.group = group;
                    dropSuccess();
                },function(r){
                    dropError(r);
                });
            } else {
                group.$save(function(group){
                    self.group = group;
                    dropSuccess();
                },function(r){
                    dropError(r);
                });
            }

		    self.editMode = false;

        }

        function hasRightToEdit(id){
	        return $rootScope.can(['admin-administrate', 'team-groups-manage']);
        }

        /**
         * Triggered on form submission
         * @param o
         * @param id
         */
        function addMemberToGroup(o,id) {
            var groupMember = new TeamGroupMember();
            groupMember.id = 1; // Tweak to always send the request via PUT.
            groupMember.team_group_id = id;
            groupMember.team_member_id = o.team_member.id;
            groupMember.team_group_role_id = o.team_group_role.id;
            groupMember.team_group_role_name = o.team_group_role_name;
            groupMember.$update().then(function(){
                self.group = TeamGroup.get({id:id},function(){},RequestError.handle);
                self.messages.push({message:"Mitglied hinzugefügt",type:"success"});
                self.memberAddToGroup = {};
            },function(r){
                dropError(r);
            });
        }

		function addAddressToGroup() {
			self.group.addresses.push(self.newAddress);
			self.group.$update().then(function (g) {
				self.newAddress = {};
			});
		}

        function removeMemberFromGroup(groupMember,idx) {

            var teamGroupMember = TeamGroupMember.get({id:groupMember.pivot.id},function(){
                teamGroupMember.$delete(function(){
                    self.group.group_members.splice(idx, 1);
                });
            });

        }

		function deleteAddressFromGroup(idx) {
			var address = new Address(self.group.addresses[idx]);
			console.log(address);
			address.$delete(function () {
				self.group.addresses.splice(idx, 1);
			});
			self.editMode = false;
		}

	    function getGroupRoleName(id) {
		    for (var i=0;i<self.groupRoles.length;i++) {
			    if (self.groupRoles[i].id == id) return self.groupRoles[i].longname;
		    }
	    }

		function getGroupRolePosition(id) {
			for (var i = 0; i < self.groupRoles.length; i++) {
				if (self.groupRoles[i].id == id) return self.groupRoles[i].position;
			}
		}

	    function saveGroupMember(groupMember, idx) {
		    var m = new TeamGroupMember(groupMember.pivot);
		    m.$update(angular.noop,RequestError.handle);
	    }

		function sortMembersByGroupRolePosition() {
			if (self.group.id && self.groupRoles.length) {
				angular.forEach(self.group.group_members, function (member) {
					member.rolePosition = getGroupRolePosition(member.pivot.team_group_role_id);
				});
			}
		}

        /**
         * Get roles in team groups
         */
        self.groupRoles = TeamGroupRole.query(function (r) {
	        self.groupRoles = r;
	        sortMembersByGroupRolePosition();
        });
        self.roles = Role.query();

    }

})();