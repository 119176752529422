(function () {

	"use strict";

	angular.module('persons').controller('personDetailCtrl', personDetailCtrl);


	personDetailCtrl.$inject = ["$rootScope", "$scope", "$confirm", "$routeParams", "$uibModalInstance", "RequestError", "Person", "Image", "accessRights", "personTypes", "person"];

	function personDetailCtrl($rootScope, $scope, $confirm, $routeParams, $uibModalInstance, RequestError, Person, Image, accessRights, personTypes, person) {

		var self = this;

		self.person = person;
		self.personTypes = personTypes;
		self.accessRights = accessRights;
		self.imageSrc = null;
		self.image = null;

		self.updateImage = function (file) {
			self.imageSrc = file;
			$scope.$apply();
		};

		self.delete = function () {
			self.person.$delete(function () {
				$uibModalInstance.close(null);
			});
		};

		self.save = function () {

			var saveImage = function (person) {
				var image = new Image();
				image.src = self.image;
				image.imageable_id = person.id;
				image.imageable_type = person.model;
				image.alt = "";

				image.$save(function (image) {
					model.image = image;
				});
			};

			var person = new Person(self.person);
			if (person.id) {
				person.$update(function (person) {
					//saveImage(person);
					$uibModalInstance.close(person);
				});
			} else {
				person.$save(function (person) {
					//saveImage(person);
					$uibModalInstance.close(person);
				});
			}

		};

	}

})();