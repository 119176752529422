angular.module('volunteers').controller("volunteerApplicationsExportSepaModalCtrl",[
    "$uibModalInstance","$http",function($uibModalInstance,$http){
        var self = this;
        self.volunteersWithBrokenIBAN = [];

        self.getBrokenIBANs = function() {
            $http.get("/api/web/volunteer_applications/export/refunds?getBrokenIBANs=true")
                .then(function(result) {
                    self.volunteersWithBrokenIBAN = result.data;
                    if (!self.volunteersWithBrokenIBAN.length) {
                        self.message = 'Es wurden keine fehlerhaften IBANs gefunden.';
                    }
                });
        };

        self.close = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }]);