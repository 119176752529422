(function () {

	"use strict";

	angular.module('pointOfSale').factory('PosTransactions', PosTransactions);

	PosTransactions.$inject = ["$http"];

	function PosTransactions($http) {

		var apiRoute = "/api/web/transactionsbyclient/";

		function allDaily(success, fail) {
			$http.get(apiRoute + "daily/all").then(
				function (result) {
					success(result.data);
				}, function (error) {
					fail(error);
				});
		}

		return {

			allDaily: allDaily

		}
	}


})();