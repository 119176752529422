angular.module('fees')
    .factory('Fee',["$resource",function ($resource) {
        return $resource('/api/web/fees/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            }
        });
    }]);