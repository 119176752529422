(function () {

	"use strict";

	angular.module('pointOfSale').controller('posProductDetailCtrl', posProductDetailCtrl);


	posProductDetailCtrl.$inject = ["$rootScope", "$scope", "$confirm", "$routeParams", "$uibModalInstance", "RequestError", "Product", "ionicColors"];

	function posProductDetailCtrl($rootScope, $scope, $confirm, $routeParams, $uibModalInstance, RequestError, Product, ionicColors) {

		var self = this;

		self.product = Product;

		self.delete = function () {

			Product.$destroy();
			$uibModalInstance.close(null);
		};

		self.save = function () {

			Product.$save();
		};

		self.colors = ionicColors;

		console.log(ionicColors);

	}

})();