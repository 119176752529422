angular.module('tickets')
    .controller('guestsCtrl',
        ["$http","$scope","$uibModal", "$window", "$confirm","RequestError",  "Ticket", "Guest",
            function ($http, $scope, $uibModal, $window, $confirm, RequestError, Ticket, Guest) {

                var self = this;

                self.guests = [];

                self.last_page = 1;
                self.current_page = 1;
                self.total = null;
                self.from = 1;
                self.to = 100;
                self.issued_by = null;
                self.textFilter = null;
                self.statusSelected = "Alle Anzeigen";
                self.statusSelectedCode = -1;


                /**
                 * Refreshes the list to paginate
                 * @param term
                 */
                self.fetchList = function() {

                    var params = {};
                    params.page = self.current_page;

                    if (self.statusSelectedCode >= 0) params.state = self.statusSelectedCode;
                    if (self.textFilter) params.filter = self.textFilter;

                    Guest.query(params,function(guests){
                        self.guests = guests;
                    },RequestError.handle);
                };

                self.fetchList();

                /**
                 * Triggered on fulltext search
                 * @param term
                 */
                self.search = function(term) {
                    self.textFilter = term;
                    self.fetchList();
                };

                self.delete = function(guest,idx) {
                    $confirm({text:"Möchtest Du diesen Gast wirklich komplett löschen?"}).then(function(){
                        guest.$delete(function(){
                            self.guests.splice(idx, 1);
                        });
                    });

                };


                self.openModal = function (view) {
                    $uibModal.open({
                            animation: true,
                            templateUrl: view

                        }
                    );
                };

                /**
                 * Filter by State
                 * @param state
                 */
                self.filterByState = function(state) {
                    self.statusSelectedCode = state;
                    if (state == -1) self.statusSelected = "Alle Anzeigen";
                    if (state == 0) self.statusSelected = "Offen";
                    if (state == 1) self.statusSelected = "Eingelöst";
                    self.fetchList();
                };

                self.addGuest = function(){

                    $uibModal.open({
                        animation: true,
                        templateUrl: "/templates/guestCreateForm.html?e",
                        controller: "guestAddModalCtrl",
                        controllerAs: "ctrl"
                    });
                }



            }]);
