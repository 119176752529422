angular.module('persons')
.controller('personsCtrl',
    ["$rootScope","$scope","$filter","$confirm","$routeParams","$uibModal","localStorageService","RequestError","Person","PersonAccessRight","PersonType",
        function($rootScope,$scope,$filter,$confirm,$routeParams,$uibModal,localStorageService,RequestError,Person,PersonAccessRight,PersonType){

            var self = this;

            self.persons = Person.query(angular.noop,RequestError.handle);
            self.filtered = [];

	        self.itemsPerPage = 50;
	        self.paginator = [5, 10, 20, 50, 100];
	        self.setItemsPerPage = function (itemsPerPage) {
		        self.itemsPerPage = itemsPerPage;
		        localStorageService.set("itemsPerPage", itemsPerPage);
	        };

            self.accessRights = PersonAccessRight.query();
            self.personTypes = PersonType.query();

            self.personsFilter = "";
            self.typeFilter = localStorageService.get("personsFilter.typeFilter") ? localStorageService.get("personsFilter.typeFilter") : [];
            self.rightFilter = localStorageService.get("personsFilter.rightFilter") ? localStorageService.get("personsFilter.rightFilter") : [];

	        self.can = $rootScope.can;

            self.toggleFilter = function(typeId,filter) {
                var i = self[filter].indexOf(typeId);
                if (i >= 0) self[filter].splice(i,1);
                else self[filter].push(typeId);
                localStorageService.set("personsFilter."+filter,self[filter]);
            };

            self.isActiveFilter = function(typeId,filter) {
                return self[filter].indexOf(typeId) >= 0 ? "active" : "";
            };

            /**
             * Checks if someone has band or pass
             * @param person
             * @param column
             * @returns {*}
             */
            self.has = function(person,column) {
                return person[column];
            };

            /**
             * Toggles if someone received band or pass
             * @param person
             * @param column
             */
            self.toggleReceived = function(person,column) {

	            if (self.can(['admin-administrate', 'access-manage'])) {
		            person[column] = person[column] ? false : true;
		            self.save(person);
	            }
            };

            /**
             * Filter for types and rights
             * @param item
             * @returns {boolean}
             */
            self.specific = function(item){

                if (self.typeFilter.length && self.typeFilter.indexOf(item.person_type_id) == -1) return false;
                if (self.rightFilter.length && self.rightFilter.indexOf(item.person_access_right_id) == -1) return false;

                return true;
            };

            self.newPerson = function(person) {
                var isNewPerson = person ? false : true;
                self.editPersonModal = $uibModal.open({
                    animation: true,
                    controller
                        : "personDetailCtrl",
                    controllerAs: "ctrl",
	                templateUrl: "/templates/personDetailModal.html?12",
                    resolve: {
                        person: function(){

                            return (person) ? person : {
                                firstname : "",
                                lastname: "",
                                gender: ""
                            };
                        },
                        accessRights: function() {
                            return self.accessRights;
                        },
                        personTypes: function(){
                            return self.personTypes;
                        }
                    }

                })
                self.editPersonModal.result.then(function(p){
	                if (null === p) {
		                for (var i = 0; i < self.persons.length; i++) {
			                if (self.persons[i].id == person.id) {
				                break;
			                }
		                }
		                self.persons.splice(i, 1);
	                } else {
		                if (isNewPerson) {
			                self.persons.push(p);
		                } else {
			                person = p;
		                }
                    }
                });
            };

            self.printPass = function(person) {

                self.printPassModal = $uibModal.open({
                    animation: true,
                    controller: "passGeneratorCtrl",
                    controllerAs: "ctrl",
                    templateUrl: "/templates/passGenerator.html?ABI",
                    resolve: {
                        currentPerson: function(){
                            return person;
                        },
                        passType: function(){

                            if (person.pass) return person.pass;

                            var resort = null;

                            return {
                                catering_options : {
                                    catering_pre:0,
                                    catering_friday:[],
                                    catering_saturday:[],
                                    catering_sunday:[],
                                    catering_post:0
                                },
                                days:[],
                                name: person.firstname,
                                access: person.access_right.name,
                                type: person.type.name,
                                imageSrc: person.image ? person.image.src : person.type == 'staff' && person.team_members.length > 0 && person.team_member[0].image ? person.team_member[0].image.src + '/500' : null,
                                person_id: person.id,
                                resort: resort
                            };

                        }
                    }
                });

            };

	        self.newParkTicket = function (person) {

		        self.createParkTicketModal = $uibModal.open({
			        animation: true,
			        controller: "parkingPermissionGeneratorCtrl",
			        controllerAs: "ctrl",
			        templateUrl: "/templates/parkingPermissionGeneratorForm.html?4",
			        resolve: {
				        assignedPerson: function () {
					        return person;
				        }
			        }
		        });

	        };

	        self.save = function (person) {
		        new Person(person).$update(function (p) {
			        person = p;
		        });
	        };

}]);