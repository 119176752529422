/**
 * DateConverter
 */
angular.module('dateConverter', [])
    .factory('DateConverter', function () {
        return {
            parseResponseDates: function(response) {

                var data = response.data, key, value;
                for (key in data) {
                    if (!data.hasOwnProperty(key) && // don't parse prototype or non-string props
                        toString.call(data[key]) !== '[object String]') continue;
                    value = Date.parse(data[key]); // try to parse to date
                    if (value !== NaN) data[key] = value;
                }
                return response;
            }
        };
    })