(function() {
	'use strict';

	festivalApp.directive("dashboardPanel", dashboardPanel);

	dashboardPanel.$inject = ['$uibModal'];

	function dashboardPanel($uibModal) {
		return {
			restrict: "E",
			templateUrl: "/templates/dashboardPanel.html",
			scope: {
				panel: "="
			},
			link: function (scope) {
				scope.content = scope.panel.content;

				scope.modalWith = function (templateUrl) {
					self.standardModal = $uibModal.open({
						animation: true,
						controller: "staticModalCtrl",
						controllerAs: "ctrl",
						templateUrl: "/templates/" + templateUrl + "?4"
					});
				}

			}
		}
	}

})();