/**
 * Shows a table with fees
 */
festivalApp.directive("feesOverview",["Fee",function(Fee){

    return {
        restrict: "A",
        templateUrl: "/templates/feesOverview.html",
        scope: {
            model: "="
        },
        link: function(scope) {

            if (!scope.model.fees) scope.fees = [];

            scope.newFee = {};

            scope.addFeeToModel = function() {

                var fee = new Fee(scope.newFee);
                fee.feeable_id = scope.model.id;
                fee.feeable_type = scope.model.model;
                fee.$save(function(fee){
                    scope.model.fees.push(fee);
                    scope.newFee = {};
                });

            };

            scope.removeFeeFromModel = function(index) {
                var fee = new Fee(scope.model.fees[index]);
                fee.$delete(function(){
                    scope.model.fees.splice(index, 1);
                });
            };

            scope.editFee = function(fee) {
                scope.newFee = fee;
            };

            scope.updateFee = function() {
                var fee = new Fee(scope.newFee);

                fee.$update(function(fee){
                    for (var i=0;i<scope.model.fees.length;i++) {
                        if (scope.model.fees[i].id==fee.id) {
                            scope.model.fees[i] = fee;
                            break;
                        }
                    }
                });

            }

        }
    }

}]);
