angular.module('users')
    .factory('Usernames', function ($rootScope,$http) {

        var _list = null;

        $http.get("/api/web/usernames").then(function(result){
            _list = result;
            $rootScope.usernames = result;
        });

        return {
            list : function(){
                return _list;
            },
            byId : function(id) {
                return _list[id];
            }
        }

    }).$inject = ["$rootScope","$http"];