/**
 * Renders the ticket's index of the app
 */
festivalApp.directive("ticketsIndex",function(){

    return {
        restrict: "E",
        templateUrl: "/templates/ticketsIndex.html",
        link: function(scope) {
            scope.currentPage = 1;
            scope.numPerPage = 20;
            scope.maxSize = 5;
            scope.from = scope.currentPage - 1;

            scope.pageChanged = function () {
                scope.from = (scope.currentPage - 1) * scope.numPerPage;
            };

        }
    }

});
