/**
 * @ngdoc controller
 *
 * @description
 *
 *
 * @requires self
 * */
angular.module('crud')
    .controller('crudDetailCtrl',
        ["$rootScope","$routeParams","RequestError","Resource","Lists",
            function($rootScope,$routeParams,RequestError,Resource,Lists){

                var self = this;

                self.lists = Lists;

                // todo: this needs to be put somewhere else
                $rootScope.$watch("myself",function(val){
                    if (!self.item.id) {
                        self.item.user = val;
                    }
                });

                if ($routeParams.id != "new") {
                    self.item =  Resource.get({id:$routeParams.id});
                    self.editMode = false;
                } else {
                    self.item = new Resource();
                    self.editMode = true;
                }

                self.toggleEditMode = function(){
                    self.editMode = !self.editMode;
                };

                self.save = function(item) {
                    if (item.id) {
                        item.$update();
                    } else {
                        item.$save(function(item){
                            self.item = item;
                        });

                    }
                    self.editMode = false;
                };

            }]);