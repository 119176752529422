/**
 * Representation of the export for SMS and EMail Adresses
 */
festivalApp.directive("groupMessageButton",["$log","$uibModal",function($log,$uibModal){

    return {
        restrict: "EA",
        templateUrl: "/templates/groupMessageButton.html?29",
        scope: {
            members : "=",
            title: "@",
            buttonSize: "@",
            emailField: "@",
            mobileField: "@"
        },
        link: function(scope) {


            if(!scope.buttonSize) {
                scope.btnSize = "";
            } else {
                scope.btnSize ='btn-'+scope.buttonSize;
            }

            var openModal = function() {

                var modal = $uibModal.open({
                    templateUrl: "/templates/groupMessageModal.html?14",
                    controller: "groupMessageModalCtrl",
                    controllerAs: "ctrl",
                    resolve: {
                        members: function() {
                            return scope.members;
                        },
                        fields: function(){
                            return {
                                email: scope.emailField,
                                mobile: scope.mobileField
                            }
                        }
                    }
                });
            };

            scope.sendMail = function() {
                openModal();
            };

            scope.sendSMS = function() {
                openModal();
            };
        }
    }

}]);