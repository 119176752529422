/**
 * @ngdoc controller
 * @name workshops:workshopRoomDetailCtrl
 *
 * @description
 *
 *
 * @requires self
 * */
angular.module('workshops')
    .controller('workshopRoomDetailCtrl',
        ["$location","$rootScope","$routeParams","$log","$confirm","RequestError","WorkshopRoom",
            function($location,$rootScope,$routeParams,$log,$confirm,RequestError,WorkshopRoom){

                var self = this;

                if ($routeParams.id !== "new") {
                    self.room = WorkshopRoom.get({id:$routeParams.id});
                } else {
                    self.room = new WorkshopRoom();
                }

                /**
                 * Updates a room
                 * @param a - the Room
                 */
                self.saveRoom = function() {
                    if (self.room.id) {
                        self.room.$update();
                    } else {
                        self.room.$save(function(room){
                            self.room = room;
                        });

                    }
                };

                self.deleteRoom = function() {
                    $confirm({text:"Möchtest Du diesen Raum wirklich löschen?"}).then(function(){
                        self.room.$delete(function(){
	                        $location.path("/workshops/rooms");
                            alert("Löschen erfolgreich!");
                        });
                    });
                }

	            self.hasRightToEdit = function () {
		            return $rootScope.can(['admin-administrate', 'workshops-manage']);
	            }

            }]);