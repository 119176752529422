(function () {

	"use strict";

	/**
	 * Link to a mail address
	 */
	festivalApp.directive("mail", mail);

	function mail() {
		return {
			restrict: "E",
			template: "<span class='nowrap'><i ng-if='icon' class='fa fa-envelope-square'></i> <a ng-href=\"mailto:{{email}}\" ng-bind='(caption && caption.length)?caption:email'></a></span>",
			scope: {
				email: "=",
				icon: "=",
				caption: "="
			}
		}
	}


})();