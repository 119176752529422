/**
 * That's the dashboard, babe
 */
festivalApp.directive("dashboardActivityLog",[function($filter){

    return {
        restrict: "E",
        templateUrl: "/templates/dashboard-activity-log.html",
        scope: {
            comments:"="
        },
        link: function (scope) {

            var standardListLimit = 3;

            scope.limit = standardListLimit;
            scope.showMoreButtonClass = "fa-plus-square";

            scope.date = function(created_at) {

                return $filter('date')(c, "dd.MM.yyyy", "+0100");
            };

            scope.showMore = function(){
                if (!scope.limit) {
                    scope.limit = standardListLimit;
                    scope.showMoreButtonClass = "fa-plus-square";
                } else {
                    scope.limit = null;
                    scope.showMoreButtonClass = "fa-minus-square";

                }
            }
        }
    }

}]);
