angular.module('volunteers')
    .value("volunteerSkillsValue",[
        {
            name: 'all',
            label: 'Alle'
        },
        {
            name:"heavy_lifting",
            label:"Schwere Lasten tragen"
        },
        {
            name:"fair_construction",
            label:"Messebau"
        },
        {
            name:"plumbing",
            label:"Wasser verlegen"
        },
        {
            name:"carpenting",
            label:"Tischlerei / Zimmerei"
        },
        {
            name:"decoration",
            label:"Deko kreieren"
        },
        {
            name:"electrics",
            label:"Strom verlegen"
        },
        {
            name:"sound_light",
            label: "Sound-/Lichttechnik"
        },
        {
            name: "it_network",
            label: "IT/Netzwerke installieren"
        },
        {
            name:"cooking",
            label:"Kochen"
        },
        {
            name:"bar",
            label:"Bardienst"
        },
        {
            name:"beer_drawing",
            label:"Bier zapfen"
        },
        {
            name: 'child_care',
            label: "Kinderbetreuung"
        },
        {
            name: 'emotional_care',
            label: "Emotionale Begleitung"

        },
        {
            name: 'body_work',
            label: "Körperarbeit"
        },
        {
            name: 'event_support',
            label: 'Veranstaltungsbetreuung'
        },
        {
            name: 'transport_of_persons',
            label: 'Personenfahrdienst'
        },
        {
            name: 'clerical_work',
            label: 'Bürodienst'
        },
        {
            name: 'reception_service',
            label: 'Empfangsdienst'
        },
        {
            name: 'hygiene',
            label: 'Gesundheitszeugnis'
        },
        {
            name: 'drivers_licence',
            label: 'Führerschein (B)'
        },
        {
            name: 'drivers_licence_truck',
            label: 'Führerschein (7,5t)'
        },
        {
            name: 'drivers_licence_passangers',
            label: 'Personenbeförderungsschein'
        },
        {
            name: 'drivers_licence_wheel_loader',
            label: 'Radladerschein'
        },
        {
            name: 'chain_saw_licence',
            label: 'Kettensägenschein'
        }
    ]);