/**
 * @ngdoc controller
 * @name volunteers:volunteerApplicationsCtrl
 *
 * @description
 *
 *
 * */
angular.module('volunteers')
    .controller('volunteerApplicationsCtrl',
        ["$rootScope","$confirm","$filter","$route","$uibModal","localStorageService","RequestError", "VolunteerApplication","currentEvent","volunteerStatusValue","volunteerSkillsValue","Image","$uibModal",
            function($rootScope,$confirm,$filter,$route,$uibModal,localStorageService,RequestError,VolunteerApplication,currentEvent,volunteerStatusValue, volunteerSkillsValue, Image, uibModal) {

                var self = this;

                self.orderBy = ['-id'];

                self.panelTitle = "Helferanmeldungen";
                self.panelSubline = "<p>Die Helfer sind nach Eingangsdatum absteigend sortiert.</p>";
                self.currentEvent = currentEvent;
                self.volunteerStateTypes = volunteerStatusValue;
                self.volunteerSkills = volunteerSkillsValue;

                self.applicationsChecked = [];
                self.paginator = [5, 10, 20, 50, 100];

                self.exportVolunteers = function(){
                    return (self.applicationsChecked.length) ? self.applicationsChecked : self.applications;
                };

                self.volunteerFilter = localStorageService.get("volunteerFilter");
                self.itemsPerPage = localStorageService.get('itemsPerPage') || 20;

                self.setFilterPreset = function(value) {
                    localStorageService.set("volunteerFilter",value);
                };

                // Get volunteer applications
                self.filteredApplications = self.applicationsByState = self.applications = [];
                if (currentEvent) {
                    VolunteerApplication.query(function(r){
                        self.applications = self.applicationsByState =self.filteredApplications =  r;
                        self.total = self.applications.length;
                    },RequestError.handle);

                } else {
                    VolunteerApplication.past(function(r) {
                        self.filteredApplications = r;
                        self.applications = r;
                        self.total = self.applications.length;
                        self.orderBy = ['-id'];
                        self.panelTitle = "Historie: Helferanmeldungen";

                    },RequestError.handle);
                };

                self.skillFilter = null;
                self.skillFilterValue = 'Alle';
                self.filterBySkill = function(skill, label) {
                    // Reset or select filter
                    skill == 'all' ? self.skillFilter = null : self.skillFilter = skill;
                    self.skillFilterValue = label;
                    self.filterSkillAndHelpWhen();
                };

                self.helpWhenFilter = null;
                self.helpWhenFilterValue = 'Alle';
                self.filterByHelpWhen = function(when,value){
                    // Reset or select filter
                    when == 'all' ? self.helpWhenFilter = null : self.helpWhenFilter = when;
                    self.helpWhenFilterValue = value;
                    self.filterSkillAndHelpWhen();
                };

                self.assignmentFilter = 'all';
                self.assignmentFilterValue = 'Alle';
                self.filterByAssignment = function(assignment, value) {
                    // Reset or select filter
                    self.assignmentFilter = assignment;
                    self.assignmentFilterValue = value;
                    self.filterSkillAndHelpWhen();
                };

                // Skill and help when filter is only applied to applications filtered by state!
                self.filterSkillAndHelpWhen = function(){
                    self.filteredApplications = $filter("filter")(self.applicationsByState,function(application) {
                        var hasSkill = !self.skillFilter || application.skills.indexOf(self.skillFilter) >= 0;
                        var hasTime = !self.helpWhenFilter || self.helpWhenFilter == application.help_when;
                        if (hasSkill && hasTime) {
                            if (self.assignmentFilter === 'all') {
                                return true;
                            } else {
                                return ((self.assignmentFilter === 'assigned' && application.resorts_assigned.length) ||
                                (self.assignmentFilter === 'suggested' && (application.resorts_suggested.length && !application.resorts_assigned.length)) ||
                                (self.assignmentFilter === 'none' && (!application.resorts_suggested.length && !application.resorts_assigned.length)));
                            }
                        } else {
                            return false;
                        }
                    });
                };

                // Filter by states (registration, contact, check-in, refund)
                self.filterByStates = function() {
                    // Get states to be filtered
                    var checkedBoxes = document.querySelectorAll('input[class=states]:checked');
                    var filteredStates = [];
                    var firstStateTypes = [];
                    // Values of checkboxes equal state ids
                    for (var i = 0; i < checkedBoxes.length; i++) {
                        var stateId = parseInt(checkedBoxes[i].value);
                        filteredStates.push(stateId);
                        // Check for first state of type (these are only set manually by user but need to be filtered
                        // if application has no state of this type)
                        if (stateId % 10 == 0) {
                            firstStateTypes.push(stateId / 10);
                        }
                    }

                    // Filter applications
                    self.applicationsByState = $filter("filter")(self.applications, function(application) {
                        // Iterate over application's states
                        var applicationHasState = true;
                        var applicationStates = [];
                        var applicationStateTypes = [];
                        // Get application states and types
                        for (var i = 0; i < application.states.length; i++) {
                            applicationStates.push(parseInt(application.states[i].state));
                            applicationStateTypes.push(parseInt(application.states[i].type));
                        }
                        // Check that application has all required states
                        for (var i = 0; i < filteredStates.length; i++) {
                            var stateId = filteredStates[i];
                            // Application does not have state
                            if (applicationStates.indexOf(stateId) == -1) {
                                // If filtered state is not first of type and application has another state in this
                                // type this application should not be shown.
                                if (!(stateId % 10 == 0 && applicationStateTypes.indexOf(stateId / 10) == -1)) {
                                    applicationHasState = false;
                                }
                            }
                        }
                        return applicationHasState;
                    });
                    // Apply additional filters on applications by state
                    self.filterSkillAndHelpWhen();
                };

                // Reset all state filters
                self.resetStateFilter= function() {
                    // Uncheck checkboxes
                    var checkedBoxes = document.querySelectorAll('input[class=states]:checked');
                    for (var i = 0; i < checkedBoxes.length; i++) {
                        checkedBoxes[i].checked = false;
                    }
                    // Reset filtered applications and apply skill and help filter
                    self.applicationsByState = self.applications;
                    self.filterSkillAndHelpWhen();
                };

                self.newVolunteerApplication = function(){
                    self.application = new VolunteerApplication();
                };

                self.setVolunteerState = function(applicationState, application, action) {
                    if (action == 'accept') {
                        application.$accept();
                    } else if (action == 'waiting') {
                        application.$waiting();
                    }
                    VolunteerApplication.updateState(applicationState);
                };

                self.setApplicationState = function(application,state) {
                    application.$update();
                };

                self.delete = function(application,idx) {
                    $confirm({text:"Möchtest Du diesen Eintrag wirklich komplett löschen?"}).then(function(){
                        application.$delete(function(){
                            self.applications.splice(idx, 1);
                        });
                    });
                };

                self.showImageUploadDialog = function(id) {
                    $('#image-upload-' + id).trigger('click');
                };

	            self.reloadingImgs = [];

                self.updateImage = function(file, model) {

	                self.reloadingImgs.push(model.id);

                    var image = new Image();
                    image.src = file;
                    image.imageable_id = model.id;
                    image.imageable_type = model.model;
                    image.alt = model.fullname;

                    if (model.photo) {
                        image.id = model.photo.id;
	                    image.$update(function () {
		                    model.imageSuffix = '?_ts=' + new Date().getTime();
	                    });
                    } else {
                        image.$save(function(image) {
                            model.photo = image;
	                        model.imageSuffix = '?_ts=' + new Date().getTime();
                        });
                    }
                };

                self.getImageSrc = function(application) {
                    var baseURL = '/api/web/volunteer_applications/image/'+application.id+'/small';
                    return application.imageSuffix ? baseURL + application.imageSuffix : baseURL;
                };

                self.setItemsPerPage = function (itemsPerPage) {
                    self.itemsPerPage = itemsPerPage;
                    localStorageService.set("itemsPerPage", itemsPerPage);
                };

	            self.can = $rootScope.can;

                self.openSepaExportModal = function () {
                    var modal = $uibModal.open({
                        templateUrl: "/templates/volunteerApplicationsExportSepaModal.html",
                        controller: "volunteerApplicationsExportSepaModalCtrl",
                        controllerAs: "ctrl"
                    });
                };

            }]);
