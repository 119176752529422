angular.module('j2storeOrders', [])
    .controller('ordersCtrl',[
	    "$rootScope", "$confirm", "RequestError", "Order",
	    function ($rootScope, $confirm, RequestError, Order) {

        var self = this;

        self.orders = [];
        self.filtered = [];
        self.allFiltered = [];

        self.last_page = 1;
        self.current_page = 1;
        self.from = 0;
        self.to = 100;
        self.num_per_page = 100;

		    self.showEmailEditForm = {};

		    self.hasRightToEdit = function () {
			    return $rootScope.can(['admin-administrate', 'orders-manage']);
		    };

        Order.query(function(result){
            self.orders = result;
            self.total = result.length;
            self.last_page = Math.ceil(result.length/self.num_per_page);
        },RequestError.handle);

        self.statusSelected = "Alle Anzeigen";
        self.statusSelectedCode = 0;

	        self.changeEmailAddress = function (order) {
		        $confirm({text: "Bist Du sicher dass Du diese E-Mail Adresse ändern willst?."}).then(function () {
			        order.$update();
			        self.showEmailEditForm[order.id] = false;
		        });
	        };

        self.filterByState = function(state) {
            self.statusSelectedCode = state;
            switch (state) {
                case 0 :
                    self.statusSelected = "Alle Anzeigen";
                    break;
                case 1 :
                    self.statusSelected = "Zahlung Ausstehend";
                    break;
                case 2 :
                    self.statusSelected = "Zahlung Erhalten";
                    break;
                case 3 :
                    self.statusSelected = "Ticket Versandt";
                    break;
            }
            self.repaginate();
        };

        self.statusFilter = function (order) {
            if (self.statusSelectedCode == 0) return true;
            if (self.statusSelectedCode == 1) return order.order_state_id < 6;
            if (self.statusSelectedCode == 2) return order.order_state_id == 6;
            if (self.statusSelectedCode == 3) return order.order_state_id == 7;
            return false;
        };

        self.repaginate = function() {
            self.allFiltered.length;
            self.last_page = Math.ceil(self.allFiltered.length/self.num_per_page);
            self.current_page = 1;
        };

        self.pageChanged = function () {
            self.from = (self.current_page - 1) * self.num_per_page;
        };

        /**
         * Updates the order state
         * @param order
         * @param id
         * @param state
         */
        self.updateStatus = function (order, id, state) {

	        if (self.hasRightToEdit()) {

		        var update = function () {
			        order.order_state_id = id;
			        order.order_state = state;
			        order.$update();
		        };

		        if (id == 6) {
			        $confirm({text: "Du bestätigst hiermit die Zahlung über " + parseFloat(order.order_total).toFixed(2) + "€ von " + order.order_info.billing_first_name + " " + order.order_info.billing_last_name + "."}).then(function () {
				        update();
			        });
		        } else {
			        update();
		        }

            }

        };


        self.delete = function(order,idx) {
            $confirm({text:"Möchtest Du diese Bestellung wirklich komplett löschen? Das kann NICHT Rückgängig gemacht werden."}).then(function(){
                order.$delete(function(){
                    self.orders.splice(idx, 1);
                });
            });
        };

        self.resend = function(order,idx) {
            $confirm({text:"Möchtest Du diesem Gast sein Ticket erneut per E-Mail zusenden?."}).then(function(){
                order.$resend(function(o){
                    order = o;
                });
            });
        };

        self.remindForPayment = function(order) {
            console.log(order);
            $confirm({text:"Du bist im Begriff die Zahlung für das Ticket anzumahnen. Fortfahren?"}).then(function(){
                order.$remind(function(r){
                    console.log(r);
                    console.log(order);
                    console.log(order.payment_reminder);
                    order.payment_reminder.push(r);
                });
            });
        };

    }]);