angular.module('ledger')
    .factory('LedgerCategory',["$resource",function ($resource) {
        return $resource('/api/web/ledger_categories/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            }
        });
    }]);