angular.module('j2storeOrders')
    .factory('Order', function ($resource) {
        return $resource('/api/web/orders/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            },
            resend : {
                method: 'GET',
                url: "/api/web/orders/resend/:id"
            },
            remind : {
                method: 'GET',
                url: "/api/web/orders/remind/:id"
            },
            cancel : {
                method: 'GET',
                url: "/api/web/orders/cancel/:id"
            }
        });
    }).$inject = ["$resource"];