/**
 * Directive that makes a dropdown that includes all the user roles that a user has
 */
festivalApp.directive("volunteerStatusFilter",["$filter","localStorageService",function($filter,localStorageService){

    return {
        restrict: "A",
        templateUrl: "/templates/volunteerStatusFilter.html?A",
        scope: {
            states: "=",
            filter: "=",
            trigger: "&"
        },
        link: function(scope) {

            scope.toggleFilter = function(state){

                var found = false;
                for(var i=0;i<scope.filter.length;i++) {
                    if (scope.filter[i] == state.name) {
                        scope.filter.splice(i,1);
                        found = true;
                    }
                }

                if (!found) scope.filter.push(state.name);

                localStorageService.set("volunteerStatusFilter",scope.filter);

                scope.trigger({});

            }
        }
    }

}]);