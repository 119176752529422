angular.module('users')
    .controller('usersCtrl',[
    "$rootScope", "$scope", "$uibModal", "$window", "$confirm", "RequestError", "User", "Role", "TeamMember",
    function ($rootScope, $scope, $uibModal, $window, $confirm, RequestError, User, Role, TeamMember) {

        /**
         * Get User (initially)
         */
        $scope.users = User.query(angular.noop,RequestError.noop);

        /**
         * Get Roles (initially)
         */
        $scope.roles = Role.query(angular.noop,RequestError.noop);

        $scope.hasRightToEdit = function(id){
            return ($rootScope.hasRole(["admin","team_manager"]) || id == $rootScope.myself.id);
        };

        $scope.teamMembers = [];
        $scope.assignMember = function(user) {
            if (!$scope.teamMembers.length) $scope.teamMembers = TeamMember.filtered({filter:"without",value:"user_id"});
            user.assignTeamMember = true
        };

        /**
         * Delete User
         * @param user
         * @param idx
         */
        $scope.delete = function(user,idx) {
            $confirm({text:"Möchtest Du diesen Benutzer wirklich löschen?"}).then(function(){
                user.$delete(function(){
                    $scope.users.splice(idx, 1);
                });
            });

        };


        /**
         * Update User
         * @param user
         */
        $scope.update = function(user) {
            user.$update(function(user){
                user.changePassword = false;
                user.assignTeamMember = false;
            });
        };

        /**
         * Just open the modal
         * @param view
         */
        $scope.openModal = function (view) {
            $uibModal.open({
                    animation: true,
                    templateUrl: view,
                    controller: 'usersCreateCtrl',
                }
            ).result.then(function (user) {
                $scope.users.push(user);
            });
        };


    }])
    .controller('usersCreateCtrl',
        ["$q", "$http", "$rootScope", "$scope", "$uibModalInstance", "RequestError", "User", "Role",
            function ($q, $http, $rootScope, $scope, $uibModalInstance, RequestError, User, Role) {

        $scope.user = new User;
        $scope.user.name = "";
        $scope.user.email = "";
        $scope.user.password = "";
        $scope.user.roles = [];
                $scope.error = false;

        $scope.roles = Role.query(angular.noop,RequestError.handle);

                var emailAddressOK = function (email) {
                    return $q(function (resolve, reject) {
                        if (!email.endsWith("@ancient-trance.de")) {
                            var error = {
                                status: 400,
                                data: {
                                    error: "E-Mail Adresse muss mit '@ancient-trance.de' enden!"
                                }
                            };
                            reject(error);
                        }

                        $http.get("/api/web/team_members/checkEmailAddress/" + email).then(resolve, reject);

                    });

                };

        $scope.save = function(user){
            emailAddressOK(user.email).then(function () {
                User.save(user, function (user) {
                    console.log("Success saving!");
                    $uibModalInstance.close(user);
                }, {});
            }, function (error) {
                console.log(error);
                $scope.error = error;
            });

        }

    }]);