(function(){

    "use strict";

    festivalApp.directive('statusSelector', statusSelector);

	statusSelector.$inject = ['$confirm', '$rootScope'];

	function statusSelector($confirm, $rootScope) {
       return {
           restrict: "AE",
           templateUrl: "/templates/volunteerStatusSelector.html?1",
           scope: {
               volunteer : "=",
               states : "=",
               type : "=",
               comments : "=",
               onSet: "&"
           },
           link: function(scope){

               scope.hasStateOfType = false;
               scope.applicationState = null; // Actual state object of application for given type

               var statusOf = statusOf;

               scope.init = init;
               scope.setState = setState;
               scope.confirmState = confirmState;

               scope.$watch("volunteer",scope.init, true);

               function statusOf(application) {
                   // Check if applicant has state of this type
                   if (application.states) {
                       for(var i = 0; i < application.states.length; i++) {
                           if (application.states[i].type == scope.type) {
                               scope.applicationState = application.states[i];
                               var state = {};
                               // Find associated state
                               for (var j = 0; j < scope.states.length; j++) {
                                   if (application.states[i].state == scope.states[j].id) {
                                       // We want the state with highest id
                                       state = scope.states[j];
                                       scope.applicationState = application.states[i];
                                       scope.hasStateOfType = true;
                                   }
                               }
                               return state;
                           }
                       }
                   } else {
                       return null;
                   }
               }

               function setState(state){

                   // Show modal and wait for confirmation if accepting or waiting
                   var action = '';
                   if (scope.type === 0 && (state.id === 1 || state.id === 2)) {
	                   if (state.id === 1 && $rootScope.can(['admin-administrate', 'volunteers-manage', 'volunteers-administrate'])) {
                           var text = "Möchtest Du diese Bewerbung annehmen? Der Helfer erhält eine automatische Infomail.";
                           action = 'accept';
	                   } else if ($rootScope.can(['admin-administrate', 'volunteers-administrate'])) {
                           var text = "Diese Bewerbung wird auf die Warteliste gesetzt. Der Helfer erhält eine Infomail.";
                           action = 'waiting';
	                   } else {
		                   alert("Du bist nicht berechtigt, diesen Status zu setzen. Bitte setze Dich mit der Helferbetreuung in Verbindung.");
		                   return;
	                   }
                       $confirm({text: text}).then(function() {
                           confirmState(state, action);
                       });
                   } else if (scope.type === 1 && $rootScope.can(['admin-administrate', 'volunteers-manage', 'volunteers-administrate'])) {
	                   confirmState(state, '');
                   } else if (scope.type === 2 && $rootScope.can(['admin-administrate', 'volunteers-manage', 'volunteers-administrate']) && state.id === 21) {
	                   confirmState(state, '');
                   } else if (
	                   scope.type === 3 &&
	                   $rootScope.can(['admin-administrate', 'volunteers-manage', 'volunteers-administrate']) &&
	                   ([30, 31, 35].indexOf(state.id) >= 0)
                   ) {
                       confirmState(state, '');
                   } else if ($rootScope.can(['admin-administrate', 'volunteers-administrate'])) {
	                   confirmState(state, '');
                   } else {
	                   alert("Du bist nicht berechtigt, diesen Status zu setzen. Bitte setze Dich ggf. mit der Helferbetreuung in Verbindung.");
                   }
               }

               function confirmState(state, action) {
                   // Add state to selector
                   scope.name = state.name;
                   scope.class = state.class;

                   // Create new state
                   var volunteerState = {
                       volunteer_id : scope.volunteer.id,
                       state: state.id,
                       type : scope.type,
                       name : state.name
                   };

                   // Save new state to existing state
                   if (scope.hasStateOfType) {
                       scope.applicationState.state = state.id;
                   } else {
                       // Or add new state
                       scope.volunteer.states.push(volunteerState);
                   }

                   scope.onSet({
                       volunteerState: volunteerState,
                       application: scope.volunteer,
                       action: action
                   });
               }

               function init() {
                   // init state of volunteer
                   scope.state = statusOf(scope.volunteer);

                   if (scope.state) {
                       scope.name = scope.state.name;
                       scope.class = scope.state.class;
                   } else {
                       // Set state to first option if none set yet
                       scope.name = scope.states[0].name;
                       scope.class = '';
                   }
               }
           }
       }
    }

})();
