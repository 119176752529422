angular.module('dashboards')
    .controller('appCtrl',
        ["$rootScope","$scope","$uibModal","$http","RequestError","Comment",
        function ($rootScope,$scope, $uibModal, $http, RequestError, Comment) {

        $scope.activeController = "dashboard";
        $scope.setActive = function(active) {
            $scope.activeController = active;
        };

        $scope.authenticated = true;

        $scope.user = {
            id : null
        };

        $rootScope.$on("notAuthenticated",function(){
            console.log("NOT AUTHENTICATED");
            $scope.authenticated = false;
            $scope.$apply;
        });

        $rootScope.$on("authenticated",function(){
            $scope.authenticated = true;
            $scope.$apply;
        });

        $scope.openModal = function (view) {
            $uibModal.open({
                    animation: true,
                    templateUrl: view

                }
            );
        };

        $scope.menu = {};

        $scope.user = {};

        $scope.user = $rootScope.myself;

        $http.get("/api/web/menu").then(function(result){
            $scope.menu = result.data;
        });
        $scope.getHref = function(item) {
            if (item.angular && item.url) {
                return "#"+item.url;
            } else if (item.url) {
                return item.url;
            } else {
                return "#";
            }
        }

        $scope.removeComment = function(comment,comments,idx) {
            var c = new Comment(comment);
            c.$delete(function(){
                comments.splice(idx, 1);
            });
        }

    }])
    .controller('modalInstanceCtrl',
        ["$scope","$uibModalInstance","item",
        function ($scope, $uibModalInstance, item) {
        $scope.item = item;
        $scope.ok = function () {
            $uibModalInstance.close($scope.item);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }]);
