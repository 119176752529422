/**
 *
 */
festivalApp.directive("commentDropper",["$rootScope","Comment",function($rootScope,Comment){

    return {
        restrict: "E",
        templateUrl: "/templates/commentDropper.html?1",
        scope: {
            model: "=",
            onAddUnshift: "@",
            formText: "@"
        },
        link: function (scope) {

            scope.messages = [];

            var dropSuccess = function() {
                scope.messages.push({message: "Nachricht Gespeichert.",type:"success"});
            };

            var dropError = function(response) {
                scope.messages.push({message: "Fehler ("+response.status+"): "+response.data.error,type:"danger"});
            };

            scope.submitForm = function() {

                var comment = new Comment();
                comment.text = scope.comment;
                comment.commentable_id = scope.model.id;
                comment.commentable_type = scope.model.model;

                comment.$save().then(
                    function(comment) {
                        if (!scope.onAddUnshift) {
                            scope.model.comments.push(comment);
                        } else {
                            scope.model.comments.unshift(comment);
                        }
                        dropSuccess();
                        scope.comment = "";
                    },
                    function(response) {
                        dropError(response);
                    }
                );


            };

        }
    }

}]);
