/**
 * That's the dashboard, babe
 */
festivalApp.directive("colorByIndex",function(){

    return {
        restrict: "A",
        templateUrl: "/templates/dashboard.html",
        link: function(scope,element,attribute) {
            var classes = ["success","primary","info","warning","danger"];
            var prefix = attribute.colorByIndexPrefix;
            var index = attribute.colorByIndex;
            element.addClass(prefix+"-"+classes[index%classes.length]);
        }
    }

});
