/**
 * Shows a Favicon
 */
festivalApp.directive("faIcon",function(){

    return {
        restrict: "A",
        template: "<i class='fa fa-{{icon}}' ng-if='icon'></i><span ng-transclude></span>",
        transclude: true,
        scope: true,
        link: function(scope,elem,attr) {
            scope.icon = attr.faIcon;
        }
    }

});
