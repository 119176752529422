/**
 * @ngdoc controller
 * @name volunteerResorts:volunteerResortsCtrl
 *
 * @description
 *
 *
 * */
angular.module('volunteers')
    .controller('volunteerResortsCtrl',
        ["$rootScope","$confirm","$routeParams","$uibModal","RequestError","VolunteerResort",
            function($rootScope,$confirm,$routeParams,$uibModal,RequestError,VolunteerResort){

                var self = this;

                self.volunteerResorts = VolunteerResort.query(angular.noop,RequestError.handle);
                self.volunteerResorts.$promise.then(function (resorts) {
                    self.sumAssigned = 0;
                    self.sumSuggested = 0;
                    self.sumRequired = 0;
                    angular.forEach(resorts, function(resort) {
                       self.sumAssigned += resort.count_assigned;
                       self.sumSuggested += resort.count_suggested;
                       self.sumRequired += resort.required_volunteers;
                    });
                });

                /**
                 * Performs the DELETE MEMBER action
                 * @param member
                 */
                self.delete = function(volunteerResort) {
                    $confirm({text:"Möchtest Du diesen Bereich wirklich löschen?"}).then(function(){
                        var id = volunteerResort.id;
                        volunteerResort.$delete(function(){
                            for (var i=0;i<self.volunteerResorts.length;i++)
                                if (self.volunteerResorts[i].id == id) self.volunteerResorts.splice(i,1);
                        });
                    });
                };

            }]);
