angular.module('invoices')
    .controller('invoiceCreatorCtrl',
        ["$uibModalInstance","$http","$window","invoice",
            function($uibModalInstance,$http,$window,invoice){

                var self = this;

	            var position = {
		            name: "",
		            factor: 1,
		            price_gross: null,
		            taxrate: 19,
	            };

                self.invoice = invoice;
	            self.invoice.positions = [angular.copy(position)];

	            self.newPosition = function () {
		            self.invoice.positions.push(angular.copy(position));
	            };

	            self.deletePosition = function (index) {
		            self.invoice.positions.splice(index, 1);
	            };

	            self.priceFor = function (position) {
		            return position.factor * parseFloat(position.price_gross);
	            };

	            self.netFor = function (position) {
		            return self.priceFor(position) / (1 + (position.taxrate / 100));
	            };

	            self.taxFor = function (position) {
		            return self.priceFor(position) - self.netFor(position);
	            };

	            self.getTotalGross = function () {
		            var result = 0;
		            for (var i = 0; i < self.invoice.positions.length; i++) {
			            result += self.priceFor(self.invoice.positions[i]);
		            }
		            return result;
	            };

	            self.getTotal7 = function () {
		            var result = 0;
		            for (var i = 0; i < self.invoice.positions.length; i++) {
			            if (self.invoice.positions[i].taxrate == 7) {
				            result += self.taxFor(self.invoice.positions[i]);
			            }
		            }
		            return result;
	            };

	            self.getTotal19 = function () {
		            var result = 0;
		            for (var i = 0; i < self.invoice.positions.length; i++) {
			            if (self.invoice.positions[i].taxrate == 19) {
				            result += self.taxFor(self.invoice.positions[i]);
			            }
		            }
		            return result;
	            };

	            self.getTotalNet = function () {
		            var result = 0;
		            for (var i = 0; i < self.invoice.positions.length; i++) {
			            result += self.netFor(self.invoice.positions[i]);
		            }
		            return result;
	            };

                self.create = function() {
                    $http.post("/api/web/invoices",self.invoice).then(function(result){
                        $window.open("/api/web/invoices/"+result.data.id+".pdf", '_blank');
                        $uibModalInstance.close();
                    });
                };

            }]);