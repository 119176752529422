angular.module('workshops')
    .factory('WorkshopBooking', function ($resource) {
        return $resource('/api/web/workshop_bookings/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            },
            moveUp : {
                url: '/api/web/workshop_bookings/:id/move_up',
                method: 'GET'
            },
            confirm : {
                url: 'api/web/workshop_bookings/:id/confirm',
                method: 'GET'
            },
            getEvents : {
                url: 'api/web/workshop_bookings/events',
                method: 'GET',
                isArray: true
            },
	        storeBackend: {
		        url: 'api/web/workshop_bookings/store_backend',
		        method: 'POST'
	        }
        });
    }).$inject = ["$resource"];