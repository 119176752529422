festivalApp.directive("genderSelector",function(){
   return {
       restrict: "E",
       templateUrl: "/templates/genderSelector.html",
       scope: {
           person: "="
       },
       link: function(scope){

       }
   }
});