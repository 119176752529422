angular.module('artists')
    .factory('Stage',["$resource",function ($resource) {
        return $resource('/api/web/stages/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            }
        });
    }]);