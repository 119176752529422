(function() {
	'use strict';

	angular.module('dashboards')
		.controller('dashboardCtrl', dashboardCtrl);


	dashboardCtrl.$inject = ['$rootScope', '$scope', "$uibModal", "RequestError", 'Dashboard', 'DashboardPanels'];

	function dashboardCtrl($rootScope, $scope, $uibModal, RequestError, Dashboard, DashboardPanels) {

		$scope.authenticated = true;

		$scope.dashboard = Dashboard.query(angular.noop, RequestError.handle);
		$scope.dashboardPanels = DashboardPanels;

        $rootScope.$watch("myself", function (nv) {
        });

		$scope.myself = $rootScope.myself;

		$scope.hasRole = function (roles) {

			for (var i = 0; i < roles.length; i++) {
				if ($rootScope.roles.indexOf(roles[i]) >= 0) {
					return true;
				}
			}

			return false;
		};

		$scope.newInvoice = function () {

			self.createInvoiceModal = $uibModal.open({
				animation: true,
				controller: "invoiceCreatorCtrl",
				controllerAs: "ctrl",
				templateUrl: "/templates/invoiceCreatorForm.html?2",
				resolve: {
					invoice: function () {
						return {};
					}
				}
			});

		};

		$scope.newParkTicket = function () {

			self.createParkTicketModal = $uibModal.open({
				animation: true,
				controller: "parkingPermissionGeneratorCtrl",
				controllerAs: "ctrl",
				templateUrl: "/templates/parkingPermissionGeneratorForm.html?3",
				resolve: {
					assignedPerson: function () {
						return null;
					}
				}
			});

		};

		$scope.modalWith = function (templateUrl) {
			self.standardModal = $uibModal.open({
				animation: true,
				controller: "staticModalCtrl",
				controllerAs: "ctrl",
				templateUrl: "/templates/" + templateUrl + "?4"
			});
		}

	}

})();