angular.module('market')
    .factory('MarketApplication',["$resource", function ($resource) {
        return $resource('/api/web/market_applications/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            },
	        past :{
		        method: 'GET',
		        isArray: true,
		        url: "/api/web/market_applications/past/:id"
	        },
        });
    }]);