(function () {

	"use strict";

	angular.module('pointOfSale').factory('PosProduct', PosProduct);

	PosProduct.$inject = ["$resource"];

	function PosProduct($resource) {
		return $resource('/api/web/pos/products/:id', {id: '@id'}, {
			update: {
				method: 'PUT'
			},
			query: {
				method: 'GET',
				isArray: true
			}
		});
	}


})();