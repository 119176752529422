/**
 * @ngdoc controller
 * @name shows:showsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('artists')
    .controller('showsCtrl',
        ["$rootScope","$scope","$confirm","$routeParams","$uibModal","RequestError","Show",
            function($rootScope,$scope,$confirm,$routeParams,$uibModal,RequestError,Show){

                $scope.shows = Show.query(angular.noop,RequestError.handle);

                $scope.show = ($routeParams.showId) ? Show.get({id:$routeParams.showId}) : null;


                /**
                 * Calls the NEW MEMBER form
                 */
                $scope.newShow = function(){ $scope.show= new Show(); };

                /**
                 * Calls the EDIT MEMBER form
                 * @param member
                 */
                $scope.editShow = function(show){ $scope.show = show; };

                /**
                 * Performs the SAVE MEMBER action
                 * @param member
                 */
                $scope.saveShow = function(show) {
                    console.log(show);
                    if (show.id) {
                        show.$update(function(){ $scope.show = null; });
                    } else {
                        show.$save(function(show){
                            $scope.shows.push(show);
                            $scope.show = null;
                        });
                    }
                };

                /**
                 * Performs the DELETE MEMBER action
                 * @param member
                 */
                $scope.deleteShow = function(show) {
                    $confirm({text:"Möchtest Du diese Bühne wirklich löschen?"}).then(function(){
                        var id = show.id;
                        show.$delete(function(){
                            for (var i=0;i<$scope.shows.length;i++)
                                if ($scope.shows[i].id == id) $scope.shows.splice(i,1);
                        });
                    });
                };

                $scope.backToList = function(){
                    $scope.show = null;
                };

            }]);
