angular.module('tickets')
    .factory('Ticket', function ($resource) {
        return $resource('/api/web/tickets/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                params: {page: '@page',filter: '@filter'},
                isArray: false
            }
        });
    }).$inject = ["$resource"];