/**
 * Alert
 */
festivalApp.directive("redactorArea", function () {

	return {
		restrict: "C",
		link: function () {

			console.log("redactorArea Started", $('.redactorArea'));

			$('.redactorArea').redactor({
				focus: true
			});

		}
	}

});
