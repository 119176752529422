angular.module('salesDashboardService', [])
    .factory('SalesDashboard', function ($resource) {
        return $resource('/api/web/advanceSale/:id', { id: '@id' }, {
            query: {
                method: 'GET',
                isArray: true
            },
            past: {
                url: '/api/web/advanceSale/:from/:to',
                params: {from: '@from', to: '@to'},
                method: "GET",
                isArray: true
            },
            places: {
                url: '/api/web/advanceSale/places',
                method: "GET",
                isArray: false
            }
        });
    }).$inject = ["$resource"];