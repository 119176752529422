angular.module('dashboards')
	.controller('staticModalCtrl',
		["$uibModalInstance","$http","$window",
			function($uibModalInstance,$http,$window){

				var self = this;

				/**
				 * On Close the modal
				 */
				self.close = function() {
					$uibModalInstance.dismiss('cancel');
				};

			}]);