/**
 * Shows a Favicon
 */
festivalApp.directive("moduleTitle",function($sanitize){

    return {
        restrict: "EA",
        template:
            "<h2>{{title}}<span ng-if='showBadge' class='badge' ng-bind='badge'></span></h2>" +
            "<div ng-bind-html='subline'></div>",
        scope: {
            title:"@",
            badge:"@",
            subline:"@"
        },
        link:function(scope) {
            scope.showBadge = (typeof scope.badge !== "undefined" && scope.badge.length) ? true : false;
        }
    }

});
