/**
 * @ngdoc controller
 * @name workshops:workshopResortDetailCtrl
 *
 * @description
 *
 *
 * @requires self
 * */
angular.module('volunteers')
    .controller('volunteerResortDetailCtrl',
        ["$rootScope","$scope","$routeParams","$filter","$uibModal","RequestError","VolunteerResort","TeamGroup","VolunteerShift","VolunteerShiftType","VolunteerApplication","volunteerStatusValue","Image",
            function($rootScope,$scope,$routeParams,$filter,$uibModal,RequestError,VolunteerResort,TeamGroup,VolunteerShift,VolunteerShiftType,VolunteerApplication,volunteerStatusValue, Image){

                var self = this;

                self.teamGroups = TeamGroup.query();

                self.shiftSheet = {};
                self.shiftDaysCount = 0;
                self.shiftTypes = VolunteerShiftType.queryByResort({id:$routeParams.id});

                self.exportVolunteers = function(){
                    return self.resort.volunteers;
                };

                self.deleteShiftType = function(type) {
                    new VolunteerShiftType(type).$delete(function(){
                        for (var i=0;i<self.shiftTypes.length;i++) {
                            if (type.id == self.shiftTypes[i].id) {
                                self.shiftTypes.splice(i,1);
                            }
                        }
                    });
                };

                if ($routeParams.id) {

                    self.resort = VolunteerResort.get({id:$routeParams.id});

                    self.editMode = false;

                } else {

                    self.resort = new VolunteerResort();
                    self.editMode = true;

                };

                self.displayName = function() {
                    var group = $filter('filter')(self.teamGroups,{'id':self.resort.team_group_id}, true);
                    return (group && group.length) ? group[0].longname : "";
                };

                self.toggleEditMode = function(){
                    self.editMode = !self.editMode;
                };

                /**
                 * Updates a resort
                 */
                self.save = function() {
                    if (self.resort.id) {
                        self.resort.$update();
                    } else {
                        self.resort.$save(function(resort){
                            self.resort = resort;
                        });
                    }
                    self.editMode = false;
                };

                self.deleteShift = function(shift,shifts,idx) {
                    new VolunteerShift(shift).$delete(function(){
                        shifts.splice(idx,1);
                    });
                    self.shiftSheet = createShiftSheet();
                };

                /**
                 * Shift Form Processor
                 * @param volunteer
                 */
                self.shift = function (volunteer) {

                    var day = new Date();
                    day.setUTCMonth(7);
                    day.setUTCFullYear(2016);
                    day.setUTCDate(12);

                    var start = new Date();
                    start.setUTCHours(12);
                    start.setUTCMinutes(0);
                    start.setUTCSeconds(0);
                    start.setUTCMilliseconds(0);

                    var end = new Date();
                    end.setUTCHours(12);
                    end.setUTCMinutes(0);
                    end.setUTCSeconds(0);
                    end.setUTCMilliseconds(0);

                    $scope.shift = {
                        start_time : start,
                        end_time : end,
                        date : day,
                        volunteer_id : volunteer.id,
                        volunteer_resort_id : self.resort.id,
                    };

                    $scope.shift_types = self.resort.shift_types;

                    $scope.togglePicker = function() {
                        $scope.datepicker.opened = !$scope.datepicker.opened;
                    };

                    $scope.datepicker = {
                        opened: false
                    };

                    $scope.datepickerOptions = {
                        initDate: new Date(2016,07,12),
                        startingDay: 1
                    };

                    $scope.setShiftType = function(id) {
                        $scope.shift.volunteer_shift_type_id = id;
                    };

                    $scope.isActiveShiftType = function(id) {
                        return ($scope.shift.volunteer_shift_type_id == id) ? 'btn-success' : 'btn-info'
                    };

                    $scope.save = function(){
                        var shift = new VolunteerShift($scope.shift);
                        shift.$save(function(shift){
                            volunteer.shifts.push(shift);
                            self.resort.shifts.push(shift);
                            $scope.modal.close();
                            self.shiftSheet = createShiftSheet();
                        });
                    };

                    $scope.volunteer = volunteer;

                    $scope.modal = $uibModal.open({
                        animation: true,
                        templateUrl: "/templates/shiftForm.html?7",
                        scope: $scope
                    });

                };

                self.shiftType = function(){

                    $scope.shiftType = {
                        name : "",
                        description : "",
                        volunteer_resort_id : self.resort.id
                    };

                    $scope.save = function(){
                        var shiftType = new VolunteerShiftType($scope.shiftType);
                        shiftType.$save(function(shiftType){
                            self.resort.shift_types.push(shiftType);
                            $scope.modal.close();
                            self.shiftSheet = createShiftSheet();
                            self.shiftTypes.push(shiftType);
                        });
                    };

                    $scope.modal = $uibModal.open({
                        animation: true,
                        templateUrl: "/templates/shiftTypeForm.html?4",
                        scope: $scope
                    });

                };

                self.shiftTypeOf = function(volunteer_shift_type_id){
                    for (var i=0;i<self.resort.shift_types.length;i++) {
                        if (volunteer_shift_type_id == self.resort.shift_types[i].id) {
                            return self.resort.shift_types[i];
                        }
                    }
                };

                self.volunteer = function(id) {
                    for (var i=0;i<self.resort.volunteers.length;i++) {
                        if (id == self.resort.volunteers[i].id) return self.resort.volunteers[i];
                    }
                    return false;
                };

                self.volunteerStateTypes = volunteerStatusValue;
                self.setVolunteerState = function(applicationState, application, action) {
                    if (action == 'accept') {
                        new VolunteerApplication(application).$accept();
                    } else if (action == 'waiting') {
                        new VolunteerApplication(application).$waiting();
                    }
                    VolunteerApplication.updateState(applicationState);
                };

                var createShiftSheet = function(){
                    var shiftDays = {};
                    self.shiftDaysCount = 0;

                    if (self.resort.shifts) {

                        for (var i=0;i<self.resort.shifts.length;i++) {

                            if (!shiftDays[self.resort.shifts[i].date]) {
                                shiftDays[self.resort.shifts[i].date] = {
                                    date:self.resort.shifts[i].date,
                                    shifts: {}
                                };
                                self.shiftDaysCount++;
                            }

                            if (!shiftDays[self.resort.shifts[i].date].shifts["shift_type_"+self.resort.shifts[i].volunteer_shift_type_id]) {
                                shiftDays[self.resort.shifts[i].date].shifts["shift_type_"+self.resort.shifts[i].volunteer_shift_type_id] = [];
                            }

                            shiftDays[self.resort.shifts[i].date].shifts["shift_type_"+self.resort.shifts[i].volunteer_shift_type_id].push(self.resort.shifts[i]);

                        }

                    }

                    return shiftDays;

                };

                /**
                 * Watch resort to create shift sheet properly
                 */
                $scope.$watch(
                    function(){return(self.resort);},
                    function(){self.shiftSheet = createShiftSheet();},
                    true
                );


                var hovering = false;
                var oldValue = null;
                self.ratingHover = function(volunteer){
                    if (!hovering) oldValue = volunteer.rating
                }
                self.ratingOut = function(volunteer){
                    if (volunteer.rating != oldValue)
                    new VolunteerApplication(volunteer).$update();
                    hovering = false;
                }

                self.showImageUploadDialog = function(id) {
                    $('#image-upload-' + id).trigger('click');
                };

                self.updateImage = function(file, model) {
                    var image = new Image();
                    image.src = file;
                    image.imageable_id = model.id;
                    image.imageable_type = model.model;
                    image.alt = model.fullname;

                    if (model.photo) {
                        image.id = model.photo.id;
                        image.$update();
                    } else {
                        image.$save(function (image) {
                            model.image = image.filename;
                            model.photo = image;
                        });
                    }
                    // Update image
                    model.imageSuffix = '?_ts=' + new Date().getTime();
                };

                self.getImageSrc = function(application) {
                    var baseURL = '/api/web/volunteer_applications/image/'+application.id+'/small';
                    return application.imageSuffix ? baseURL + application.imageSuffix : baseURL;
                };

            }]);
