/**
 * @ngdoc controller
 * @name stages:stagesCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('artists')
    .controller('stagesCtrl',
        ["$rootScope","$scope","$confirm","$routeParams","$uibModal","RequestError","Stage",
            function($rootScope,$scope,$confirm,$routeParams,$uibModal,RequestError,Stage){

                $scope.stages = Stage.query(angular.noop,RequestError.handle);

                $scope.stage = ($routeParams.stageId) ? Stage.get({id:$routeParams.stageId}) : null;


                /**
                 * Calls the NEW MEMBER form
                 */
                $scope.newStage = function(){ $scope.stage= new Stage(); };

                /**
                 * Calls the EDIT MEMBER form
                 * @param member
                 */
                $scope.editStage = function(stage){ $scope.stage = stage; };

                /**
                 * Performs the SAVE MEMBER action
                 * @param member
                 */
                $scope.saveStage = function(stage) {
                    console.log(stage);
                    if (stage.id) {
                        stage.$update(function(){ $scope.stage = null; });
                    } else {
                        stage.$save(function(stage){
                            $scope.stages.push(stage);
                            $scope.stage = null;
                        });
                    }
                };

                /**
                 * Performs the DELETE MEMBER action
                 * @param member
                 */
                $scope.deleteStage = function(stage) {
                    $confirm({text:"Möchtest Du diese Bühne wirklich löschen?"}).then(function(){
                        var id = stage.id;
                        stage.$delete(function(){
                            for (var i=0;i<$scope.stages.length;i++)
                                if ($scope.stages[i].id == id) $scope.stages.splice(i,1);
                        });
                    });
                };

                $scope.backToList = function(){
                    $scope.stage = null;
                };

            }]);
