/**
 * @ngdoc controller
 * @name artists:artistsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('artists')
    .controller('artistsCtrl',
    ["$rootScope","$scope","$confirm","$routeParams","$uibModal","RequestError","Artist",
    function($rootScope,$scope,$confirm,$routeParams,$uibModal,RequestError,Artist){

        $scope.artists = Artist.query(angular.noop,RequestError.handle);

        /**
         * Performs the DELETE MEMBER action
         * @param member
         */
        $scope.deleteArtist = function(artist) {
            $confirm({text:"Möchtest Du diese Band wirklich löschen?"}).then(function(){
                var id = artist.id;
                artist.$delete(function(){
                    for (var i=0;i<$scope.artists.length;i++)
                        if ($scope.artists[i].id == id) $scope.artists.splice(i,1);
                });
            });
        };
}]);
