/**
 * @ngdoc controller
 * @name workshops:workshopApplicationDetailCtrl
 *
 * @description
 *
 *
 * @requires self
 * */
angular.module('workshops')
    .controller('workshopApplicationDetailCtrl',
        ["$rootScope","$routeParams","$log","RequestError","WorkshopApplication","WorkshopRoom","WorkshopApplicationGenres","WorkshopEvent",
            function($rootScope,$routeParams,$log,RequestError,WorkshopApplication,WorkshopRoom,WorkshopApplicationGenres,WorkshopEvent){

                var self = this;

                self.rooms = WorkshopRoom.query();
                self.rooms.$promise.then(function (result) {
                    self.rooms = result;
                    self.rooms.unshift({id: null, name: 'Kein Ort'});
                });

	            self.hasRightToEdit = hasRightToEdit;

                if ($routeParams.id) {

                    WorkshopApplication.get({id:$routeParams.id},function(application){
                        self.application = application;
                        if (!self.application.date) self.application.date = new Date();
                        if (!self.application.start_time) self.application.start_time = new Date();
                        if (!self.application.end_time) self.application.end_time= new Date();
                    });
                    self.editMode = false;
                } else {

                    self.application = new WorkshopApplication();
                    self.application.room = {};
                    self.editMode = true;
                    if (!self.application.date) self.application.date = new Date();
                    if (!self.application.start_time) self.application.start_time = new Date();
                    if (!self.application.end_time) self.application.end_time= new Date();
                }

                self.displayPersons = false;
                self.displayMessage = false;

                self.toggleEditMode = function(){
                    self.editMode = !self.editMode;
                    if (self.editMode) {
                        // Init datepicker array
                        self.datepicker = [];
                        for (var i = 0; i <= self.application.events.length; i++)
                        {
                            self.datepicker.push({opened: false});
                        }
                    }
                };

                self.togglePersonDisplay = function(){
                    self.displayPersons = !self.displayPersons;
                    if (self.displayPersons && !self.application.persons.length) {
                        self.newPerson = {
                            firstname: self.application.vorname,
                            lastname: self.application.nachname
                        }
                    }
                };

                self.setRoom = function(event, room) {
                    event.room = room;
                    event.room_id = room.id;
                };

                /**
                 * Adds a new event to this workshop
                 */
                self.addEvent = function () {
                    self.application.events.push({
                        date: {},
                        start: {},
                        end: {},
                        room: {},
                        workshop_id: self.application.id,
                        max_reservations: {},
                        online_reservation: true
                    });
                    self.datepicker.push({opened: false});
                };

                /**
                 * Remove given event
                 *
                 * @param index
                 */
                self.removeEvent = function (index) {
                    if (self.application.events[index].id) {
                        WorkshopEvent.delete({id: self.application.events[index].id});
                    }
                    self.application.events.splice(index, 1);
                };

                /**
                 * Determines number of reservations for
                 *
                 * @param event
                 * @returns {max_reservations|{}}
                 */
                self.getMaxReservations = function(event) {
                    if (!event.online_reservation) { return 'keine Onlineanmeldung'; }
                    return event.max_reservations ? event.max_reservations : self.application.teilnehmerzahl;
                };

                /**
                 * Updates an application
                 * @param a - the Application
                 */
                self.saveApplication = function() {
                    if (self.application.id) {
                        self.application.$update(function(application){
                            application.message ? self.displayMessage = true : self.displayMessage = false;
                            console.log(self.displayMessage);
                        });
                    } else {
                        self.application.$save(function(application){
                            self.application = application;
                        });

                    }
                    self.editMode = false;
                };

                self.acceptApplication = function() {
                    self.application.status = "angenommen";
                    self.application.activated = 1;
                    self.saveApplication();
                }

                /**
                 * Toggles the state of one of the three pickers below
                 * @param which
                 */
                self.togglePicker = function(index) {
                    self.datepicker[index].opened = !self.datepicker[index].opened;
                }

                self.datepickerOptions = {
                    initDate: new Date(new Date().getFullYear(),07,12),
                    startingDay: 1
                };

                self.workshopApplicationGenres = WorkshopApplicationGenres;

                self.formatDate = function(date) {
                    return new Date(date);
                }

	            function hasRightToEdit() {
		            return $rootScope.can(['admin-administrate', 'workshops-manage']);
	            }

            }]);