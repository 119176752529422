festivalApp.directive("applicationAnswer",function(){
    return {
        restrict : "AE",
        template: "<span ng-class=\"[((customOption)?'em text-success':'')]\" ng-bind='description'></span>",
        scope: {
            question: "@",
            application: "="
        },
        link: function(scope) {

            var questions = {

                honesty: {
                    description: "Wie ehrlich bist du?",
                    values: {
                        "grundehrlich": "Ich bin grundehrlich auch wenn's schmerzt.",
                        "ehrlich": "Meistens bin ich ehrlich.",
                        "ausweichend": "Von mir kommt eher ein ,flotter Spruch oder etwas Ausweichendendes, alsdass ich meine ehrliche Meinung sage.",
                        "schwätzer": "Ich hab noch NIE in meinem Leben und werde es auch NIEMALS tun."
                    }
                },


                reliable: {
                    description: "Wie zuverlässig bist du?",
                    values: {
                        "100%": "Ich bin pünktlich, sorgfältig und 100% verlässlich.",
                        "75%": "Im großen und ganzen bin ich zuverlässig, auch wenn ich hin und wieder die akademische Viertelstunde in Anspruch nehme.",
                        "25%": "Was ist schon ein Tag Verspätung, immerhin bin ich hier, oder?",
                        "0%": "Ob heut, ob morgen, was soll's? Komm mir nicht mit Vereinbarungen! Ein kreatives Genie braucht seine Freiheit..."
                    }
                },


                consumist: {
                    description: "Wie ist Dein Konsumverhalten?",
                    values: {
                        "strong": "Einen Bauzaun aufstellen//Eine Blume des Lebens ausmalen//Ein Bier zapfen... kann ich auch morgens schon mit Pegel!",
                        "moderate": "Maßvoll heißt mein Motto, egal zu welcher Tageszeit.",
                        "recreative": "Das erste Bier//Die erste Jolle gibt's bei mir erst ,nach Feierabend.",
                        "straight": "Ich bin straight edge, bei mir gibt's nicht mal Kaffee!"
                    }
                },


                motivation: {
                    description: "Warum willst du beim Festival helfen?",
                    values: {
                        "money": "In erster Linie will ich das Geld für den Eintritt sparen.",
                        "social": "Helfen? Feiern? Egal, Hauptsache mit netten Leuten und Spaß bei der Sache!",
                        "interest": "Ich helfe immer gern, da hab ich was zu tun. Außerdem es ist spannend zu sehen wie so ein Festival von innen aussieht.",
                        "family": "Ich liebe das Ancient Trance und würde als Teil der Orga-Familie gern nachhaltig zum Erblühen des Festivals beitragen!"
                    }
                },


                stress: {
                    description: "Wie reagierst Du wenn's mal stressig wird?",
                    values: {
                        "positive": "Unter Stress blüh' ich erst richtig auf!",
                        "calm": "Ich lächle, atme durch und mache entspannt weiter wie zuvor.",
                        "sensitive": "Ich werde etwas unentspannt, komme aber trotzdem ganz gut, klar.",
                        "dislike": "Am liebsten bin ich ganz weit weg vom Stress. Beim Parkplatz bewachen kann ich z.B. wunderbar entspannen."
                    }
                },


                problem_solving: {
                    description: "Kannst Du selbstständig arbeiten und Probleme lösen?",
                    values: {
                        "dependent": "Ich brauche jederzeit den heißen Draht zu jemandem der sich wirklich auskennt.",
                        "fair": "Krieg ich schon hin, Hauptsache nicht zu kompliziert.",
                        "informed": "Solange mir vorher alles ausführlich erklärt wird, ,krieg ich auch komplexeste Aufgaben alleine hin.",
                        "tough": "Ach was kompliziert... Ich find mich schon zurecht!"
                    }
                },


                digital_ability: {
                    description: "Wie stehst Du zu Browser, Excel & co.?",
                    values: {
                        "poor": "Internetbrause? Hab ich noch nie getrunken!",
                        "dislike": "Mit dem Verwaltungskram kann man mich jagen!",
                        "ok": "Wenn dort Hilfe gebraucht wird, mach ich auch sowas.",
                        "great": "Kein Problem, her damit!"
                    }
                },


                craft_work: {
                    description: "Bist Du handwerklich begabt?",
                    values: {
                        "great": "Klar! Schrauben, sägen, nageln, bohren, tackern - das ist meine Welt!",
                        "good": "Wenn mir jemand sagt, was ich zu tun habe, kann ich gute Arbeit leisten.",
                        "fair": "Begabt würde ich nicht sagen, aber flexibel und nicht gänzlich ungeschickt...",
                        "no": "No Way! Ich hab zwei linke Hände!"
                    }
                },


                creativity: {
                    description: "Wie steht es um Deine Kreativität?",
                    values: {
                        "great": "Gebt mir ein leeres Stück Raum und etwas Material, und ich erschaffe euch einen zauberhaften Feenwald!",
                        "good": "Basteln, malen, mit allen möglichen Materialien improvisieren? Ja, bitte!",
                        "fair": "Sag mir genau was, wie und wo und ich tu' mein bestes.",
                        "no": "Ich kann nicht malen//Basteln ist für Kinder!"
                    }
                },


                social: {
                    description: "Hast Du gern viel Kontakt zu Menschen?",
                    values: {
                        "crafter": "Ich bastel lieber.",
                        "digital": "Ich kann mit Bildschirm und Tastatur mehr anfangen.",
                        "lover": "Immer gern, ich kann wunderbar mit Menschen jeder Art.",
                        "god": "Genau mein Ding! Ich ringe mit meiner herzlichen und zuvorkommenden Art selbst Miesepetern ein Lächeln ab!"
                    }
                },


                conflict: {
                    description: "Wie gehst du mit Konflikten um?",
                    values: {
                        "noconflict": "Nicht um... sondern aus dem Weg!",
                        "peaceful": "Ich laufe zwar nicht davon, versuche aber mich nicht reinziehen zu lassen.",
                        "active": "Ich schalte mich ein! Oft gelingt es mir zu schlichten, zuweilen streite ich am Ende mit.",
                        "mediator": "Dank meiner Erfahrung, Menschenkenntnis und sanften aber bestimmten Durchsetzungskraft gelingt es mir meist mit wohlgewählten Worten gegenseitiges Verständnis bei beiden Parteien zu erzeugen und die Agressivität eines Konfliktes aufzulösen."
                    }
                },


                english: {
                    description: "Wie sind Deine Englischkenntnisse?",
                    values: {
                        "no": "Ich spreche kein Englisch.",
                        "poor": "Wenn ich Hände und Füße dazunehme kann ich mich grob verständigen.",
                        "good": "Ich kann mich gut verständigen.",
                        "excellent": "Mein Englisch ist verhandlungssicher. / Ich bin Muttersprachler."
                    }
                },


                deutschkenntnisse: {
                    description: "Wie sind deine Deutschkenntnisse?",
                    values: {
                        "muttersprache": "Ich bin Muttersprachler.",
                        "sehrgut": "Ich spreche sehr gut Deutsch.",
                        "befriedigend": "Ich komme zurecht.",
                        "kaum": "A tiny little bit.",
                        "garnicht": "How do you say 'Hello'?"
                    }
                },


                muscle_work: {
                    description: "Wie stehst Du zu körperlicher Arbeit?",
                    values: {
                        "heavy": "Wo soll ich den Kühlschrank abstellen?",
                        "intermediate": "Macht mir Freude... solange ich keine Kühlschränke schleppen muss.",
                        "few": "Ist eher nicht so meins.",
                        "no": "Au, mein Rücken!"
                    }
                },

                help_when: {
                    description: "Wann kannst Du helfen?",
                    values: {
                        "immediately": "Ab sofort, ich bin in Leipzig und gern bereit mich schon langfristig vorher einzubringen.",
                        "setup_only": "Ausschließlich vor dem Festival",
                        "setup_during": "Vor und während des Festivals (Anreise bis spätestens Mittwoch abend, 10. Aug)",
                        "during_only": "Ausschließlich während des Festivals (Deine Lieblingsband musst du trotzdem nicht verpassen!)",
                        "during_after": "Während und nach dem Festival (Abreise frühestens Dienstag Nachmittag, 16. Aug)",
                        "after_only": "Ausschließlich nach dem Festival / Abbauphase (Abreise frühestens Mittwoch abend, 17. Aug)",
                        "setup_after": "Vor und nach dem Festival/ Auf-&Abbauphase (Insgesamt mindestens drei Tage)"
                    }
                },

                om_factor: {
                    description: "Wie gehst du damit um, wenn es auf deinem Posten mal nichts zu tun gibt?",
                    values: {
                        "needs_action": "Ich brauche ständig was zu tun, sonst vertrocknet meine Lebensfreude schneller als eine Seerose in der Sandwüste.",
                        "prefers_action": "Mir ist ständige Beschäftigung lieber, aber ich komm auch mit Nichtstun klar.",
                        "enjoys_breaks": "Ich weiß es zu schätzen wenn zwischendurch auch mal nichts passiert.",
                        "as_cool_as_a_cucumber": "Ich wäre auch dann vergnügt, wenn kaum ein Mensch vorbeikommt."
                    }
                }
            };

            if (questions[scope.question].values[scope.application[scope.question]]) {
                scope.description = questions[scope.question].values[scope.application[scope.question]];
                scope.customOption = false;
            } else {
                scope.description = scope.application[scope.question];
                scope.customOption = true;
            }

        }
    }
})