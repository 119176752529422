/**
 * Shows a table with shows
 */
festivalApp.directive("showOverview",["Artist","Show","Stage",function(Artist,Show,Stage){

    return {
        restrict: "A",
        templateUrl: "/templates/showOverview.html",
        scope: {
            model: "=",
        },
        link: function(scope) {

            scope.stages = Stage.query(angular.noop);
            scope.artists = Artist.query(angular.noop);
            scope.shows = Show.query(angular.noop);


            scope.newShow = {
            };

            console.log(scope.model.model);

            if (scope.model.model == 'App\\Artist') scope.newShow.artist_id = scope.model.id;

            /**
             * Function that runs when a person is newly registered.
             * It will create a new person.
             */
            scope.addShow = function() {
                var show = new Show(scope.newShow);
                if (show.id) {
                    show.$update(function(){
                        scope.newShow = null;
                    });
                } else {
                    show.$save(function(show){
                        scope.model.shows.push(show);
                        scope.newShow = {};
                    });
                }
            };

            scope.editShow = function(show) {
                scope.newShow = show;
            };

            scope.formReset = function(){
                scope.newShow = {};
            }

            scope.removeShow = function(index) {
                $confirm({text:"Möchtest Du diese Show wirklich löschen?"}).then(function(){
                    var id = artist.id;
                    artist.$delete(function(){
                        for (var i=0;i<$scope.artists.length;i++)
                            if ($scope.artists[i].id == id) $scope.artists.splice(i,1);
                    });
                });
            };


        }
    }

}]);
