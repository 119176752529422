angular.module('salesDashboardCtrl', [])
    .controller('salesDashboardCtrl',
        ['$rootScope','$scope','RequestError','SalesDashboard',
        function($rootScope,$scope,RequestError,SalesDashboard){

        $scope.current = {};
        $scope.past = {};
        $scope.pastSame = {};


        $scope.byCountry = {};
        $scope.byCity = {};


        $scope.currentDashboard = SalesDashboard.query(
        function(d){
            process(d,$scope.current);


        },RequestError.handle);

        $scope.pastDashboard = SalesDashboard.past({from:'2015-12-01',to:'2016-08-31'},
        function(d){
            process(d,$scope.past)
        },RequestError.handle);

        $scope.pastSameDashboard = SalesDashboard.past({from:'2015-12-01',to:moment(new Date()).subtract(1,"year").format("YYYY-MM-DD")},
	        function(d){
		        process(d,$scope.pastSame)
	        },RequestError.handle);

        $scope.salesByLocation = SalesDashboard.places(function (d) {
	        $scope.byCountry = d.countries;
	        $scope.byCity = d.cities;
	        console.log(d);
        });


        function process(d,scope) {
	        scope.total = 0;
	        scope.orders = 0;
	        scope.amount = 0;
	        scope.doughnut = {
		        totals : [],
		        amounts: [],
		        labels : []
	        };
	        var total, amount, order;
	        for (var i=0;i< d.length;i++) {
		        total = parseFloat(d[i].total);
		        order = parseInt(d[i].orders);
		        amount = parseInt(d[i].amount);
		        scope.total += total;
		        scope.orders += order;
		        scope.amount += amount;

		        scope.doughnut.totals.push(total);
		        scope.doughnut.amounts.push(amount);
		        scope.doughnut.labels.push(d[i].sku);

	        }
        }

    }]);