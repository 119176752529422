angular.module('volunteers')
    .factory('VolunteerResort',["$resource",function ($resource) {
        return $resource('/api/web/volunteer_resorts/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            }
        });
    }]);