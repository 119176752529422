/**
 * @ngdoc controller
 * @name stages:stagesCtrl
 *
 * @description
 *
 *
 * @requires self
 * */
angular.module('ledger')
    .controller('ledgerCtrl',
        ["$rootScope","$confirm","$uibModal","RequestError","Ledger",
            function($rootScope,$confirm,$uibModal,RequestError,Ledger){

                var self = this;

                self.items = Ledger.query(angular.noop,RequestError.handle);

                /**
                 * Performs the DELETE MEMBER action
                 * @param member
                 */
                self.delete = function(item) {
                    $confirm({text:"Möchtest Du diesen Eintrag wirklich löschen?"}).then(function(){
                        var id = item.id;
                        item.$delete(function(){
                            for (var i=0;i<self.items.length;i++)
                                if (self.items[i].id == id) self.items.splice(i,1);
                        });
                    });
                };

            }]);
