(function () {

	"use strict";

	/**
	 * Link to a telephone number
	 */
	festivalApp.directive("tel", tel);

	function tel() {
		return {
			restrict: "E",
			template: "<span class='nowrap'><i ng-if='icon' class='fa fa-phone-square'></i> <a ng-href=\"tel:{{number}}\" ng-bind='number'></a></span>",
			scope: {
				number: "=",
				icon: "="
			}
		}
	}


})();