angular.module('team').controller("photoUploadModalCtrl",[
    "$scope","$uibModalInstance","$http","$window","Image",'model',
    function($scope,$uibModalInstance,$http,$window,Image,model){

        var self = this;

        self.image = null;
        self.imageSrc = null;

        self.save = function(){

                var image = new Image();
                image.src = self.image;
                image.imageable_id = model.id;
                image.imageable_type = model.model;
                image.alt = "";

                image.$save(function(image){
                    model.image = image;
                    $uibModalInstance.close();
                });

        };

        self.updateImage = function(file){
            self.imageSrc = file;
            $scope.$apply();
        };

        self.closeModal = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }]);