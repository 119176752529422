/**
 * Handles a request error
 */
angular.module("festivalApp")
    .factory("RequestError",["$log","$rootScope",function($log,$rootScope){

        var srv = {};

        /**
         * The inner Error Handler
         * @param data
         * @param status
         * @param headers
         * @param config
         */
        srv.handleError = function(data, status, headers, config) {
            if (status == 401) {
                $rootScope.$emit("notAuthenticated");
            } else {
                $log.error(data);
                $log.error(status);
                $log.error(headers);
                $log.error(config);
            }
        };

        return {
            /**
             * Handles the error that was sent back from $http or $resource
             * @param e
             */
           handle: function(e){
               srv.handleError(e.data, e.status, e.headers, e.config);
           }
        }

    }]);