(function(){

    "use strict";

    angular.module('team')
        .controller('teamGroupsCtrl',teamGroupsCtrl);

    teamGroupsCtrl.$inject =  ["$rootScope", "$uibModal", "$window", "$confirm", "$filter", "RequestError", "TeamMember", "TeamGroup","TeamGroupRole"];

	function teamGroupsCtrl($rootScope, $uibModal, $window, $confirm, $filter, RequestError, TeamMember, TeamGroup, TeamGroupRole) {

	    var self = this;


		self.members = TeamMember.query();
        self.roles = {};

        TeamGroupRole.query(function(roles){
        	angular.forEach(roles,function(role){
        		self.roles[role.name] = role;
	        });
        });

		self.groups = TeamGroup.query(function (groups) {
			self.groups = groups;
			orderRecursively();
			console.log(self.groups);
		});

		self.hasSubGroup = hasSubGroup;
		self.getGroupById = getGroupById;
        self.getPositionsFromGroup = getPositionsFromGroup;
		self.levelLessThan = levelLessThan;
		self.hasRightToEdit = hasRightToEdit;

		function getPositionsFromGroup(position, group) {
			var positions = [];
			angular.forEach(group.group_members,function (member) {
				if (member.pivot.team_group_role_id == self.roles[position].id) {
					positions.push(member);
				}
			});
			return positions;
		}

		function hasSubGroup(group) {
			for(var i = 0; i<self.groups.length;i++) {
				if (group.id === self.groups[i].parent_id) return true;
			}
			return false;
		}

		function getGroupById(id) {
			for (var i=0;i< self.groups.length;i++) {
				if (self.groups[i].id === id) return self.groups[i];
			}
			return null;
		}

		function findRootGroup() {
			for (var i = 0; i < self.groups.length; i++) {
				if (self.groups[i].parent_id === null) return self.groups[i];
			}

			return false;
		}

		function levelLessThan(val) {
			return function (item) {
				console.log(item.level, item);
				return item.level < val;
			}
		}

		function orderRecursively(rootGroup, level) {

			if (!rootGroup) {
				// only first level
				rootGroup = findRootGroup();
				level = 1;
				if (!rootGroup) {
					console.log("No Root group found... Do we have groups at all?");
					return;
				}
			}

			rootGroup.subGroups = [];
			rootGroup.level = level;

			for (var i = 0; i < self.groups.length; i++) {
				if (self.groups[i].parent_id === rootGroup.id) {
					rootGroup.subGroups.push(self.groups[i]);
					orderRecursively(self.groups[i], level + 1);
				}
			}


		}

        //$scope.groupsChecked = [];
        //$scope.exportGroups = [];
        //
        //$scope.getCheckedMembers = function(){
        //    var members = [];
        //    var ids = [];
        //    for (var i = 0;i<$scope.groupsChecked.length;i++) {
        //        console.log($scope.groupsChecked[i]);
        //        //for (var j=0;j<$scope.groupsChecked[i].length;j++) {
        //        //    if (ids.indexOf($scope.groupsChecked[i][j].id)<0) {
        //        //        members.push($scope.groupsChecked[i][j])
        //        //        ids.push($scope.groupsChecked[i][j].id);
        //        //    }
        //        //}
        //    }
        //    return members;
        //};
        //
        ///**
        // * Member list for exportbutton
        // * @returns {*}
        // */
        //$scope.exportMembers = function(){
        //    return ($scope.membersChecked.length) ? $scope.membersChecked : $scope.members;
        //};

		function hasRightToEdit() {
			return $rootScope.can(['admin-administrate', 'team-groups-manage']);
		}

    }

})();
