angular.module('tickets')
    .controller(
        'ticketsCtrl',
	    ["$rootScope", "$http", "$uibModal", "$window", "$confirm", "RequestError", "Ticket", "Order",
		    function ($rootScope, $http, $uibModal, $window, $confirm, RequestError, Ticket, Order) {

        var self = this;

        self.tickets = [];

        self.last_page = 1;
        self.current_page = 1;
        self.total = null;
        self.from = 1;
        self.to = 100;
        self.issued_by = null;
        self.textFilter = null;
        self.statusSelected = "Alle Anzeigen";
        self.statusSelectedCode = -1;
			    self.can = $rootScope.can;

        self.checked = [];
        self.checkAll = function() {
            if (self.checked.length) {
                self.checked = [];
            } else {
                self.checked = [];
                for (var i=0;i<self.tickets.length;i++){
                    self.checked.push(self.tickets[i].id);
                }
            }
        };
        self.check = function(id) {
            var i = self.checked.indexOf(id);
            if (i >= 0) {
                self.checked.splice(i,1);
            } else {
                self.checked.push(id);
            }
        };
        self.updateChecked = function(tickets,status){
            $http.post("/api/web/tickets/mass_status",{status:status,ids:self.checked}).then(function(){

                for (var i = 0;i<tickets.length;i++){
                    if (self.checked.indexOf(tickets[i].id) >= 0) {
                        tickets[i].status = status;
                    }
                }
            });
        };

        var assignPagination = function(result) {
            self.last_page = result.last_page;
            self.current_page = result.current_page;
            self.total = result.total;
            self.tickets = result.data;
            self.from = result.from;
            self.to = result.to;
        };

            /**
             * Refreshes the list to paginate
             * @param term
             */
        self.fetchList = function() {

            self.checked = [];

            var params = {};
            params.page = self.current_page;

            if (self.statusSelectedCode >= 0) params.state = self.statusSelectedCode;
            if (self.textFilter) params.filter = self.textFilter;

            Ticket.query(params,assignPagination,RequestError.handle);
        };

        self.fetchList();

        /**
         * Triggered on fulltext search
         * @param term
         */
        self.search = function(term) {
            self.textFilter = term;
            self.fetchList();
        };

        self.updateStatus = function (ticket, id) {
            ticket.status = id;
            new Ticket(ticket).$update();
        };

        self.delete = function(ticket,idx) {
            $confirm({text:"Möchtest Du diesen Code wirklich komplett löschen?"}).then(function(){
                ticket.$delete(function(){
                    self.tickets.splice(idx, 1);
                });
            });

        };

        self.showAssignField = function(ticket,val) {
            self.orders = Order.query();
            ticket.showAssignField = val;
        }

        self.updateIssuer = function (ticket) {
            new Ticket(ticket).$update();
        };

        self.unlinkOrder = function(ticket) {
            $confirm({text:"Möchtest die Verknüpfung des Codes zu dieser Bestellung aufheben?"}).then(function(){
                ticket.order_id = null;
                new Ticket(ticket).$update();
            });
        };

        self.updateOrderId = function(ticket) {
            ticket.showAssignField = false;
            ticket.order_id = parseInt(ticket.order.order_id);
            new Ticket(ticket).$update();
        };

        self.openModal = function (view) {
            $uibModal.open({
                    animation: true,
                    templateUrl: view

                }
            );
        };

            /**
             * Filter by State
             * @param state
             */
        self.filterByState = function(state) {
            self.statusSelectedCode = state;
            if (state == -1) self.statusSelected = "Alle Anzeigen";
            if (state == 0) self.statusSelected = "Offen";
            if (state == 1) self.statusSelected = "Eingelöst";
            if (state == 2) self.statusSelected = "Storniert";
            if (state == 3) self.statusSelected = "Kommissioniert";

            self.fetchList();
        };

        self.statusFilter = function(ticket) {
            if (self.statusSelectedCode < 0) return true;
            else return ticket.status == self.statusSelectedCode;
        }

        self.printTicket = function (id) {
            $window.open("tickets/print/"+id, '_blank');
        }
}]);
