angular.module('groupware')
    .filter('dmy',["$filter",function($filter){
        return function(input) {
            if(input == null){
                return "";
            }

            var _date = moment(input).format('lll');

            return _date;

        };
    }])
    .filter("username",["Usernames",function(Usernames){
        return function(input) {
            if (Usernames.list()[input]) {
                return Usernames.list()[input];
            }
        }
    }])
    .filter("asDate", function () {
        return function (input) {
            return new Date(input);
        }
    });
