// Define all the modules here
angular.module('artists',[]);
angular.module('comments',[]);
angular.module('crud',[]);
angular.module('dashboards',[]);
angular.module("fees",[]);
angular.module("invoices",[]);
angular.module("ledger",[]);
angular.module("market",[]);
angular.module("messages",[]);
angular.module("persons",[]);
angular.module("pointOfSale", []);
angular.module("runs",[]);
angular.module("team",[]);
angular.module('tickets', []);
angular.module('users', []);
angular.module('volunteers', []);
angular.module('workshops', []);
angular.module('groupware', []);

moment.locale('de');

var festivalApp = angular.module('festivalApp', [
    'angular-clipboard',
    'angular-confirm',
	'angular-redactor',
	'cfp.hotkeys',
    'angularUtils.directives.dirPagination',
    'artists',
    "chart.js",
    'checklist-model',
    'comments',
    'crud',
    'dateConverter',
    'dashboards',
    'dndLists',
    'fees',
    'groupware',
    'invoices',
    'j2storeOrders',
    'ledger',
    'LocalStorageModule',
    'market',
    'messages',
    'ngAnimate',
    'ngCroppie',
    'ngDropzone',
    'ngResource',
    'ngRoute',
    'ngSanitize',
    'persons',
	'pointOfSale',
    'runs',
    'salesDashboardCtrl',
    'salesDashboardService',
    'team',
    'tickets',
    'ui.bootstrap',
    'users',
    'volunteers',
    'workshops'
]).config(["$routeProvider",function($routeProvider) {
    $routeProvider
        .when("/", {
	        templateUrl: '/templates/dashboard.html?ACDA',
            controller: 'dashboardCtrl',
        })
        .when('/artists',{
            templateUrl: '/templates/artistsIndex.html',
            controller: 'artistsCtrl',
        })
        .when('/artists/:artistId',{
            templateUrl: '/templates/artistDetail.html',
            controller: 'artistDetailCtrl',
        })
        .when('/ledger', {
            templateUrl: '/templates/ledgerIndex.html',
            controller: 'crudIndexCtrl',
            controllerAs: 'ctrl',
            resolve: {Resource : "Ledger"}
        })
        .when('/ledger/:id', {
            templateUrl: '/templates/ledgerDetail.html',
            controller: 'crudDetailCtrl',
            controllerAs: 'ctrl',
            resolve: {
                Resource: "Ledger",
                Lists: ['User','TeamGroup','LedgerCategory',function(User,TeamGroup,LedgerCategory) {
                    return {users: User.query(), teamGroups: TeamGroup.query(),ledgerCategories:LedgerCategory.query()}
                }]
            }
        })
        .when('/ledger_categories', {
            templateUrl: '/templates/categoryIndex.html',
            controller: 'crudIndexCtrl',
            controllerAs: 'ctrl',
            resolve: {
                Resource : "LedgerCategory"
            }
        })
        .when('/ledger_categories/:id', {
            templateUrl: '/templates/categoryDetail.html',
            controller: 'crudDetailCtrl',
            controllerAs: 'ctrl',
            resolve: {
                Resource: "LedgerCategory",
                Lists: ['LedgerCategory',function(LedgerCategory) {
                    return {categories: LedgerCategory.query()}
                }]
            }
        })
        .when('/runs/cars',{
            templateUrl: '/templates/carsIndex.html',
            controller: 'carsCtrl',
            controllerAs: 'ctrl'
        })
        .when('/runs/runs',{
            templateUrl: '/templates/runsIndex.html',
            controller: 'runsCtrl',
            controllerAs: 'ctrl'
        })
        .when('/market', {
	        templateUrl: '/templates/marketApplicationsIndex.html?ACDA',
            controller: 'marketApplicationsCtrl',
	        resolve: {
		        season: function(){ return 'current'; }
	        }
        })
	    .when('/market/:year', {
		    templateUrl: '/templates/marketApplicationsIndex.html?ACDA',
		    controller: 'marketApplicationsCtrl',
            resolve: {
		        season: function($route){ return $route.current.params.year; }
            }
	    })
        .when('/sales', {
	        templateUrl: '/templates/salesDashboard.html?ACDA',
            controller: 'salesDashboardCtrl'
        })
        .when('/sales/tickets',{
	        templateUrl: '/templates/ticketsIndex.html?ACDA',
            controller: 'ticketsCtrl',
            controllerAs: 'ctrl'
        })
        .when('/sales/orders',{
	        templateUrl: '/templates/ordersIndex.html?ACDA',
            controller: 'ordersCtrl',
            controllerAs: 'ctrl'
        })
        .when('/sales/guests',{
	        templateUrl: '/templates/guestsIndex.html?ACDA',
            controller: "guestsCtrl",
            controllerAs: 'ctrl'
        })
        .when('/shows',{
            templateUrl: '/templates/showsIndex.html',
            controller: 'showsCtrl',
        })
        .when('/shows/:stageId',{
            templateUrl: '/templates/showsIndex.html',
            controller: 'showsCtrl',
        })
        .when('/stages',{
            templateUrl: '/templates/stagesIndex.html',
            controller: 'stagesCtrl',
        })
        .when('/stages/:stageId',{
            templateUrl: '/templates/stagesIndex.html',
            controller: 'stagesCtrl',
        })
        .when('/team/members',{
	        templateUrl: '/templates/teamIndex.html?ACDA',
	        controller: 'teamMembersCtrl',
	        controllerAs: 'ctrl'
        })
        .when('/team/members/:teamMemberId',{
	        templateUrl: '/templates/teamDetail.html?ACDA',
            controller: 'teamMemberDetailCtrl',
	        controllerAs: 'ctrl'
        })
        .when('/team/groups',{
	        templateUrl: '/templates/groupsIndex.html?ACDA',
            controller: 'teamGroupsCtrl',
	        controllerAs: 'ctrl'
        })
        .when('/team/groups/:id',{
	        templateUrl: '/templates/groupsDetail.html?ACDA',
            controller: 'teamGroupsDetailCtrl',
	        controllerAs: 'ctrl'
        })
        .when('/persons/',{
	        templateUrl: '/templates/personsIndex.html?ACDA',
            controller: 'personsCtrl',
            controllerAs: 'ctrl'
        })
        .when("/pos/",{
	        templateUrl: "/templates/posDashboard.html?ACDA",
            controller: 'posDashboardCtrl',
            controllerAs: 'ctrl'
        })
	    .when("/pos/sales", {
		    templateUrl: "/templates/posSalesDashboard.html?ACDA",
		    controller: 'posSalesDashboardCtrl',
		    controllerAs: 'ctrl'
	    })
        .when('/users',{
            templateUrl: '/templates/usersIndex.html?ACDC',
            controller: 'usersCtrl',
        })
        .when('/volunteers/applications',{
	        templateUrl: '/templates/volunteerApplicationsIndex.html?ACDA',
            controller: 'volunteerApplicationsCtrl',
            controllerAs: 'ctrl',
            resolve: {currentEvent:function(){return true;}}
        })
        .when('/volunteers/applications/past',{
	        templateUrl: '/templates/volunteerApplicationsIndex.html?ACDA',
            controller: 'volunteerApplicationsCtrl',
            controllerAs: 'ctrl',
            resolve: {currentEvent:function(){return false;}}
        })
        .when('/volunteers/applications/new',{
	        templateUrl: '/templates/volunteerApplicationDetail.html?ACDA',
            controller: 'volunteerApplicationDetailCtrl',
            controllerAs: 'ctrl'
        })
        .when('/volunteers/applications/:id',{
	        templateUrl: '/templates/volunteerApplicationDetail.html?ACDA',
            controller: 'volunteerApplicationDetailCtrl',
            controllerAs: 'ctrl'
        })
        .when('/volunteers/confirmed',{
	        templateUrl: '/templates/volunteerApplicationsIndex.html?ACDA',
            controller: 'volunteerConfirmedCtrl',
            controllerAs: 'ctrl'
        })
        .when('/volunteers/confirmed/:id',{
	        templateUrl: '/templates/volunteerApplicationDetail.html?ACDA',
            controller: 'volunteerApplicationDetailCtrl',
            controllerAs: 'ctrl'
        })
        .when('/volunteers/resorts',{
	        templateUrl: '/templates/volunteerResortsIndex.html?ACDA',
            controller: 'volunteerResortsCtrl',
            controllerAs: 'ctrl'
        })
        .when('/volunteers/resorts/new',{
	        templateUrl: '/templates/volunteerResortDetail.html?ACDA',
            controller: 'volunteerResortDetailCtrl',
            controllerAs: 'ctrl'
        })
        .when('/volunteers/resorts/:id',{
	        templateUrl: '/templates/volunteerResortDetail.html?ACDA',
            controller: 'volunteerResortDetailCtrl',
            controllerAs: 'ctrl'
        })
        .when('/workshops/applications',{
	        templateUrl: '/templates/workshopApplicationsIndex.html?ACDA',
            controller: 'workshopApplicationsCtrl',
            controllerAs: 'ctrl',
            resolve: {currentEvent:function(){return true;}}
        })
        .when('/workshops/applications/past',{
	        templateUrl: '/templates/workshopApplicationsIndex.html?ACDA',
            controller: 'workshopApplicationsCtrl',
            controllerAs: 'ctrl',
            resolve: {currentEvent:function(){return false;}}
        })
        .when('/workshops/applications/new',{
	        templateUrl: '/templates/workshopApplicationDetail.html?ACDA',
            controller: 'workshopApplicationDetailCtrl',
            controllerAs: 'ctrl'
        })
        .when('/workshops/applications/:id',{
	        templateUrl: '/templates/workshopApplicationDetail.html?ACDA',
            controller: 'workshopApplicationDetailCtrl',
            controllerAs: 'ctrl'
        })
        .when('/workshops/rooms/:id',{
	        templateUrl: '/templates/workshopRoomDetail.html?ACDA',
            controller: 'workshopRoomDetailCtrl',
            controllerAs: 'ctrl'
        })
        .when('/workshops/rooms',{
	        templateUrl: '/templates/workshopRoomsIndex.html?ACDA',
            controller: 'workshopRoomsCtrl',
            controllerAs: 'ctrl'
        })
        .when('/workshops/bookings',{
            templateUrl: '/templates/workshopBookingsIndex.html',
            controller: 'workshopBookingsCtrl',
            controllerAs: 'ctrl'
        })
	    .when('/workshops/bookings/new', {
		    templateUrl: '/templates/workshopBookingsAdd.html',
		    controller: 'workshopBookingsAddCtrl',
		    controllerAs: 'ctrl'
	    })
}]).run(["$rootScope","$log","$http",function($rootScope,$log,$http) {

    $rootScope.getMyself = function(){
        return $http.get("api/web/myself");
    };

    $rootScope.hasRole = function(name) {

        if (typeof name == "string") {
            return $rootScope.roles.indexOf(name) > -1;
        } else {

            for (var i=0;i<name.length;i++) {
                if ($rootScope.roles.indexOf(name[i]) > -1) return true;
            }
            return false;
        }

    };

	$rootScope.can = function (permissions) {

		if (typeof permissions == "string") {
			return $rootScope.permissions.indexOf(permissions) > -1;
		} else {

			for (var i = 0; i < permissions.length; i++) {
				// console.log($rootScope.permissions,permissions[i],$rootScope.permissions.indexOf(permissions[i]) > -1);
				if ($rootScope.permissions.indexOf(permissions[i]) > -1) return true;
			}

			return false;

		}

	};

    $rootScope.roles = [];
	$rootScope.permissions = [];

    $rootScope.myself = {};

    $rootScope.getMyself().then(function(result){

        $rootScope.myself = result.data;

        angular.forEach(result.data.roles,function(role){
            $rootScope.roles.push(role.name);
        });

	    $rootScope.permissions = result.data.permissions;
	    console.log($rootScope.permissions);

    });

}]);

festivalApp.filter('reverse', function() {
    return function(items) {
        return items.slice().reverse();
    };
});