(function () {
	/**
	 * Shows a Favicon
	 */
	festivalApp.directive("volunteerAssignment", volunteerAssignment);

	volunteerAssignment.$inject = ["$rootScope", "$http"];

	function volunteerAssignment($rootScope, $http) {

		return {
			restrict: "EA",
			templateUrl: "/templates/volunteerAssignment.html?1",
			scope: {
				model: "=",
				resorts: "=",
				onSet: "&"
			},
			link: function (scope) {

				scope.btnColor = function (id, type) {
					if (scope.model[type]) {
						return (scope.model[type].indexOf(id) >= 0) ? (type == "resorts_assigned") ? "btn-success" : 'btn-primary' : 'btn-default';
					} else {
						return 'btn-default';
					}
				};

				scope.toggle = function (id, type) {
					if ($rootScope.can(['admin-administrate', 'volunteers-manage', 'volunteers-administrate'])) {
						var i = scope.model[type].indexOf(id);
						if (i >= 0) scope.model[type].splice(i, 1);
						else scope.model[type].push(id);
						update();
					}

				};

				var update = function () {
					var data = {
						resorts_suggested: scope.model.resorts_suggested,
						resorts_assigned: scope.model.resorts_assigned
					};
					$http.post("/api/web/volunteers/" + scope.model.id + "/assign", data).then(
						function (response) {
							scope.model.resorts_suggested = response.data.resorts_suggested;
							scope.model.resorts_assigned = response.data.resorts_assigned;
							scope.model.resorts = response.data.resorts;
							if (scope.model.resorts_assigned.length > 0) {
								scope.onSet({application: scope.model});
							}
						}
					);
				};
			}
		}

	}

})();