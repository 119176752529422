/**
 * Directive that makes a dropdown that includes all the user roles that a user has
 */
festivalApp.directive("userRoleSelector",["$log",function($log){

    return {
        restrict: "A",
        templateUrl: "/templates/userRoleSelector.html?B",
        scope: {
            user: "=",
            roles: "=",
            update: "&"
        },
        link: function(scope) {

            scope.userHasRole = function(role) {
                var result = -1;

                angular.forEach(scope.user.roles,function(uRole,key) {
                    if (role.id == uRole.id) result = key;
                });

                return result;
            };

            scope.rolesForUser = function (user) {
                return user.roles.length;
            };

            scope.roleIcon = function (role, user) {
                var classes = ['fa'];

                if (scope.userHasRole(role, user) > -1) {
                    classes.push('fa-check-square-o');
                    classes.push('text-success');
                } else {
                    classes.push('fa-square-o');
                    classes.push('text-danger');

                }

                return classes;
            };

            scope.editUserRole = function(role) {
                var key = scope.userHassRole(role);
                if (key > -1) {
                    scope.user.roles.splice(key,1);
                } else {
                    scope.user.roles.push(role);
                }
                scope.update(scope.user);
            };

        }
    }

}]);