/**
 * That's the dashboard, babe
 */
festivalApp.directive("dashboard",function(){

    return {
        restrict: "E",
        templateUrl: "/templates/dashboard.html",
    }

});
