(function () {

	"use strict";

	angular.module('team').controller('teamMemberDetailCtrl', teamMemberDetailCtrl);


    teamMemberDetailCtrl.$inject = ["$rootScope", "$scope", "$uibModal", "$location", "$confirm", "$routeParams", "RequestError", "TeamMember", "TeamGroup", "TeamGroupRole", "TeamMemberStatus", "Image", "$q", "$http"];

    function teamMemberDetailCtrl($rootScope, $scope, $uibModal, $location, $confirm, $routeParams, RequestError, TeamMember, TeamGroup, TeamGroupRole, TeamMemberStatus, Image, $q, $http) {

		var self = this;

		if ($routeParams.teamMemberId !== "new") {
			self.member = TeamMember.get({id: $routeParams.teamMemberId});
			self.editMode = false;
		} else {
			self.member = new TeamMember();
            self.create_user = true;
			self.editMode = true;
		}

		self.teamMemberStatus = TeamMemberStatus.query(angular.noop, RequestError.handle);

		self.imageSuffix = "";

		self.messages = [];



		self.hasRightToEdit = function () {
			return ((self.member && $rootScope.myself.team_member) && ($rootScope.hasRole(['admin', "team_manager"]) || (self.member.id == $rootScope.myself.team_member.id)));
		};

		self.hasRightToAdministrate = function () {
			return $rootScope.hasRole(['admin', "team_manager"]);
		};

		var dropSuccess = function () {
			self.messages.push({message: "Änderungen gespeichert.", type: "success"});
			console.log(self.messages);
		};

		var dropError = function (response) {
            var message;
            if (response.data.errors) {
                var messages = [];
                var key;
                for (key in response.data.errors) {
                    messages.push(response.data.errors[key][0]);
                }

                message = messages.join(" ");
            } else {
                message = (response.data && response.data.error && typeof response.data.error !== "object") ? response.data.error : response.data.error.error;
            }

            console.log("Error:", message, response);
            self.messages.push({message: "Fehler (" + response.status + "): " + message, type: "danger"});
		};

		self.memberStatusOf = function () {
			for (var i = 0; i < self.teamMemberStatus.length; i++) {
				if (self.teamMemberStatus[i].id == self.member.status_id) return self.teamMemberStatus[i].longname;
			}
		};

		self.statusIconOf = function () {
			for (var i = 0; i < self.teamMemberStatus.length; i++) {
				if (self.teamMemberStatus[i].id == self.member.status_id) return self.teamMemberStatus[i].icon;
			}
		};

		self.setTeamMemberStatus = function (id) {
			self.member.status_id = id;
		};


		/**
		 * Performs the SAVE MEMBER action
		 * @param member
		 */
		self.saveMember = function () {

			self.editMode = false;

			if (self.member.address && self.member.address.email_corporate && !self.member.address.email_corporate.indexOf("@")) {
				self.member.address.email_corporate = self.member.address.email_corporate + "@ancient-trance.de";
			}
			if (self.member.id) {
				self.member.$update(function (r) {
					self.member = r.resource;
					dropSuccess();
				}, function (r) {
					dropError(r);
				});
			} else {
                self.emailAddressOK(self.member.address.email_corporate).then(function () {

                    self.member.$save(function (r) {
                        self.member = r;
                        dropSuccess();
                        $location.path("/team/members/" + self.member.id);
                    }, function (r) {
                        dropError(r);
                    });

                }, function (r) {
                    dropError(r);
                });
			}

		};

		/**
		 * Performs the DELETE MEMBER action
		 * @param member
		 */
		self.deleteMember = function (member) {
			$confirm({text: "Möchtest Du dieses Mitglied wirklich löschen?"}).then(function () {
				var id = member.id;
				member.$delete(function () {
				});
			})
		};

		self.createUserForMember = function (member) {
			member.$update(function (member) {
				self.member = member;
			});
		};

		self.groupRoles = TeamGroupRole.query(angular.noop, RequestError.handle);
		self.getGroupRoleName = function (id) {
			for (var i = 0; i < self.groupRoles.length; i++) {
				if (self.groupRoles[i].id == id) return self.groupRoles[i].longname;
			}
		};

		self.triggerImageUpload = function () {
			$('#image-upload').trigger('click');
			return false;
		};

        self.emailAddressOK = function (email) {
            return $q(function (resolve, reject) {
                if (!email.endsWith("@ancient-trance.de")) {
                    var error = {
                        status: 400,
                        data: {
                            error: "E-Mail Adresse muss mit '@ancient-trance.de' enden!"
                        }
                    };
                    reject(error);
                }

                $http.get("/api/web/team_members/checkEmailAddress/" + email).then(resolve, reject);

            });

        };

		self.processImage = function (file) {
			self.imageCandidate = file;
			$scope.$apply();
		};

		self.updateImage = function () {

			self.savingImage = true;

			var image = new Image();
			image.src = self.processedImage;
			image.imageable_id = self.member.id;
			image.imageable_type = self.member.model;
			image.alt = self.member.fullname;

			if (self.member.image && self.member.image.id) {
				image.id = self.member.image.id;
				image.$update(function (image) {
					self.member.image = 'image/' + image.filename;
					self.member.photo = image;
					self.imageSuffix = '?_ts=' + new Date().getTime();
					self.savingImage = false;
					self.imageCandidate = null;
					self.processedImage = null;
				}, function () {
					self.savingImage = false;
				});
			} else {
				image.$save(function (image) {
					self.member.image = 'image/' + image.filename;
					self.member.photo = image;
					self.imageSuffix = '?_ts=' + new Date().getTime();
					self.savingImage = false;
					self.imageCandidate = null;
					self.processedImage = null;
				}, function () {
					self.savingImage = false;
				});
			}


		};

		self.groups = TeamGroup.query();

	}

})();