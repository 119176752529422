angular.module('workshops')
    .factory('WorkshopApplication', function ($resource) {
        return $resource('/api/web/workshop_applications/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            },
            past: {
                method: 'GET',
                isArray: true,
                url: "/api/web/workshop_applications/past"
            },
            cloneToCurrent: {
                method: 'POST',
                url: "/api/web/workshop_applications/cloneToCurrent",
            }
        });
    }).$inject = ["$resource"];