angular.module('volunteers')
    .factory('VolunteerShiftType', function ($resource) {
        return $resource('/api/web/volunteer_shift_types/:id', { id: '@id' }, {
            update : {
                method: 'PUT'
            },
            query: {
                method: 'GET',
                isArray: true
            },
            queryByResort: {
                method: 'GET',
                isArray: true,
                url: '/api/web/volunteer_shift_types/resort/:id',
                params: {id: '@id'}
            }
        });
    }).$inject = ["$resource"];