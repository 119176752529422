angular.module('tickets')
    .controller('guestAddModalCtrl',["$http","$uibModalInstance",function($http,$uibModalInstance){

        var self = this;

        self.changed = false;

        if (!self.assignables) {
            self.assignables = [];
            $http.get("/api/web/assignables").then(function(response){
                self.assignables = response.data;
                self.assignables = self.assignables;
            });
        } else {
            self.assignables = self.assignables;
        }

        self.range = function(min, max, step) {
            step = step || 1;
            var input = [];
            for (var i = min; i <= max; i += step) {
                input.push(i);
            }
            return input;
        };

        self.inviting = null;

        self.guest = {amount:0,names:[],days:null};

        self.addGuest = function(inviting,guest,mail){

            $http.post("/api/web/guests",{
                amount  : parseInt(guest.amount),
                names   : guest.names,
                guestable_id  : inviting.id,
                guestable_type  : inviting.model,
                mail_to : mail
            }).then(function(response){
                for(var i=0;i<response.data.length;i++) {
                    inviting.guests.push(response.data[i]);
                }
                self.createdCodes = response.data;
                self.changed = true;
            });

        };

        self.days = {
            "ALL" : null,
            "FR" : "2016-08-12",
            "SA" : "2016-08-13",
            "SO" : "2016-08-14"
        };

        self.setDay = function(day) {
            self.guest.days = self.days[day];
            console.log(self.guest.days);
        };

        self.btnState = function(value){
            return (self.days[value] === self.guest.days) ? "btn-success" : "btn-primary";
        };

        self.closeModal = function(){
            $uibModalInstance.close();
        };

    }
]);