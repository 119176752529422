(function () {

	"use strict";

	angular.module('pointOfSale').factory('PosClient', PosClient);

	PosClient.$inject = ["$resource"];

	function PosClient($resource) {
		return $resource('/api/web/pos/clients/:id', {id: '@id'}, {
			update: {
				method: 'PUT'
			},
			query: {
				method: 'GET',
				isArray: true
			}
		});
	}


})();