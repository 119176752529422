(function () {

	"use strict";

	angular.module('pointOfSale').controller('posDashboardCtrl', posDashboardCtrl);

	posDashboardCtrl.$inject = ["$rootScope", "$scope", "$confirm", "$routeParams", "$uibModal", "RequestError", "PosClient", "PosProduct"];

	function posDashboardCtrl($rootScope, $scope, $confirm, $routeParams, $uibModal, RequestError, PosClient, PosProduct) {

		var self = this;

		self.clients = PosClient.query();
		self.products = PosProduct.query();

		self.editClient = editClient;
		self.editProduct = editProduct;
		self.newProduct = newProduct;

		function editClient(client) {
			// openModal(product);
		}

		function editProduct(product) {
			console.log(product);
			openProductModal(product);
		}

		function newProduct() {
			openProductModal(new PosProduct());
		}

		function openProductModal(product) {
			self.productModal = $uibModal.open({
				animation: true,
				controller: "posProductDetailCtrl",
				controllerAs: "ctrl",
				templateUrl: "/templates/posProductDetailModal.html?12",
				resolve: {
					Product: function () {
						return product;
					}
				}
			});
			self.productModal.result.then(function (result) {
				if (null === result) {
					//self.products.splice(i, 1); ?? needed?
				}
			});
		}

	}

})();
