/**
 * Alert
 */
festivalApp.directive("alert",function(){

    return {
        restrict: "E",
        template: '<uib-alert type="{{alert.type}}" close="closeAlert($index)" ng-repeat="alert in messages track by $index">{{alert.message}}</uib-alert>',
        scope: {
            messages: "=",
        },
        link: function(scope){

            scope.closeAlert = function($index) {
                scope.messages.splice($index,1);
            };

        }
    }

});
