(function () {
	"use strict";

	/**
	 * @ngdoc controller
	 * @name volunteers:volunteerApplicationDetailCtrl
	 *
	 * @description
	 *
	 *
	 * @requires self
	 * */
	angular.module('volunteers').controller('volunteerApplicationDetailCtrl', volunteerApplicationDetailCtrl);

	volunteerApplicationDetailCtrl.$inject = ["$rootScope", "$routeParams", "$uibModal", "RequestError", "VolunteerApplication", "VolunteerResort", "volunteerStatusValue", "volunteerSkillsValue", "Image"];

	function volunteerApplicationDetailCtrl($rootScope, $routeParams, $uibModal, RequestError, VolunteerApplication, VolunteerResort, volunteerStatusValue, volunteerSkillsValue, Image) {

		var self = this;

		self.application = {};

		self.volunteerStates = volunteerStatusValue;
		self.volunteerSkills = volunteerSkillsValue;

		VolunteerResort.query(function (r) {
			self.resorts = r;
		}, RequestError.handle);

		self.backlink = function () {
			return (self.application.confirmed) ? "#/volunteers/resorts" : "#/volunteers/applications";
		};

		if ($routeParams.id) {
			self.application = VolunteerApplication.get({id: $routeParams.id}, function () {
				self.imageURL = self.imageBaseURL = '/api/web/volunteer_applications/image/' + self.application.id + '/small';
			});
			self.editMode = false;
		} else {
			self.application = new VolunteerApplication();
			self.editMode = true;
		}

		self.toggleEditMode = function () {
			self.editMode = !self.editMode;
		};

		self.saveApplication = function (a) {
			if (a.id) {
				a.$update(function(application) {
					self.application = application;
				});
			} else {
				a.$save(function (application) {
					self.application = application;
				});
			}
			self.editMode = false;
		};

		self.deleteApplication = function (application) {
			application.$delete(function (response) {
				if (response.success) {
					self.message = 'Helfer wurde erfolgreich entfernt.';
				} else {
					self.message = 'Helfer konnte nicht gelöscht werden.';
				}
			});
		};

        self.setVolunteerState = function(applicationState, application, action) {
            if (action == 'accept') {
                application.$accept(function(result) {
                    self.application = result;
                    console.log(application);
                });
            } else if (action == 'waiting') {
                application.$waiting(function(result) {
                	application = result;
				});
            } else {
				VolunteerApplication.updateState(applicationState, function(result) {
                    applicationState.model = result.model;
                    applicationState.id = result.id;
                    applicationState.comments = result.comments;

                    // Update free ticket if applicable
                    if (applicationState.name == 'Ticketbefreit') {
                        application.free_ticket = 1;
                        self.saveApplication(application, function (result) {
                            application = result;
                        });
                    } else if (applicationState.type == 3 && application.free_ticket == 1) {
                        application.free_ticket = 0;
                        self.saveApplication(application, function (result) {
                            application = result;
                        });
                    }
                });
            }
		};

        self.volunteerAssigned = function(application) {
            // check if application has contact state
            var hasContactState = false;
            angular.forEach(application.states, function(state) {
                if (state.type == 1) {
                    state.state = 14;
                    hasContactState = true;
                }
            });
            // If not create
            if (!hasContactState) {
                var volunteerState = {
                    volunteer_id : application.id,
                    type : 1,
                    state: 14
                };
                VolunteerApplication.updateState(volunteerState, function() {
                    self.application.states.push(volunteerState);
                });
            }
        };

        // Checks if application has states given in array
        self.volunteerHasState = function(states) {
        	var hasState = false;
        	angular.forEach(states, function(query) {
                angular.forEach(self.application.states, function(state) {
                	if (state.state == query) { hasState = true; }
                });
			});
        	return hasState;
		};

		self.getSkillLabel = function (name) {
			var skill = $.grep(self.volunteerSkills, function (skill) {
				return skill.name === name;
			});
			if (skill) {
				return skill[0].label;
			} else {
				return skill;
			}
		};

		self.back = function () {
			history.back();
		};

		self.triggerImageUpload = function () {
			$('#image-upload').trigger('click');
		};

		self.updateImage = function (file) {
			var image = new Image();
			image.src = file;
			image.imageable_id = self.application.id;
			image.imageable_type = self.application.model;
			image.alt = self.application.fullname;

			if (self.application.photo) {
				image.id = self.application.photo.id;
				image.$update(function (image) {
					self.application.image = 'image/' + image.filename;
					self.imageURL = self.imageBaseURL + '?_ts=' + new Date().getTime();
				});
			} else {
				image.$save(function (image) {
					self.application.image = 'image/' + image.filename;
					self.application.photo = image;
					model.imageSuffix = '?_ts=' + new Date().getTime();
				});
			}
		};

        self.printPass = function(application) {
            self.printPassModal = $uibModal.open({
                animation: true,
                controller: "passGeneratorCtrl",
                controllerAs: "ctrl",
                templateUrl: "/templates/passGenerator.html?7",
                resolve: {
                    currentPerson: function(){
                        return application.persons[0];
                    },
                    passType: function(){

	                    console.log(application);

                        if (application.persons[0].pass) return application.persons[0].pass;

                        var resort = null;
                        for(var i=0;i<self.resorts.length;i++){
                            if (self.resorts[i].id == application.resorts_assigned[0]) {
                                resort = self.resorts[i].display_name;
                                break;
                            }
                        }
                        return {
                            catering_options : {
                                catering_pre:0,
                                catering_friday:[],
                                catering_saturday:[],
                                catering_sunday:[],
                                catering_post:0
                            },
                            days:[],
                            name: application.firstname,
                            access: "crew2",
                            type: "crew",
	                        imageSrc: (application.persons[0].image) ? application.persons[0].image.src : (application.photo && application.photo.src) ? application.photo.src : null,
                            person_id: application.persons[0].id,
                            resort: resort
                        };

                    }
                }
            });
        };

		self.can = $rootScope.can;

	}

})();