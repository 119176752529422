(function () {
	"use strict";

	/**
	 * @ngdoc controller
	 * @name workshops:workshopBookingsAddCtrl
	 *
	 * @description
	 *
	 *
	 * */
	angular.module('workshops').controller('workshopBookingsAddCtrl', workshopBookingsAddCtrl);

	workshopBookingsAddCtrl.$inject = ["$rootScope", "$confirm", "RequestError", "localStorageService", "WorkshopBooking", "WorkshopEvent"];

	function workshopBookingsAddCtrl($rootScope, $confirm, RequestError, localStorageService, WorkshopBooking, WorkshopEvent) {

		var self = this;

		self.events = WorkshopEvent.query();

		self.isBookedOut = isBookedOut;

		self.isInCart = isInCart;
		self.addToCart = addToCart;
		self.removeFromCart = removeFromCart;
		self.calculateCosts = calculateCosts;
		self.finishBooking = finishBooking;

		self.booking = {
			cart: []
		};

		self.messages = [];

		function finishBooking() {
			self.booking.cart = self.booking.cart;

			new WorkshopBooking(self.booking).$storeBackend(function (r) {
				console.log(r);
				self.messages.push({type: r.type, message: r.message});
				self.events = r.events;
			});

			self.booking = {
				cart: []
			};

			jQuery(document).scrollTop(0);
		}

		function calculateCosts() {
			var amount = 0;
			self.confirmMessage = '';

			angular.forEach(self.booking.cart, function (event) {
				amount += (parseInt(event.workshop.kosten) * event.reserve_amount);
				if (event.reserve_amount > (event.max_reservations - event.count_confirmed)) {
					self.confirmMessage = 'ACHTUNG: Ein oder mehrere Workshops werden hiermit überbucht.';
				}
			});

			return amount;
		}

		function addToCart(event) {
			event.reserve_amount = 1;
			self.booking.cart.push(event);
		}

		function isInCart(event) {
			for (var i = 0; i < self.booking.cart.length; i++) {
				if (event.id === self.booking.cart[i].id) return true;
			}
			return false;
		}

		function removeFromCart(event) {
			// jQuery("#search").focus();
			for (var i = 0; i < self.booking.cart.length; i++) {
				if (event.id === self.booking.cart[i].id) {
					delete event.reserve_amount;
					self.booking.cart.splice(i, 1);
					break;
				}
			}
		}

		function isBookedOut(event) {
			// $this->getMaxReservations() <= $this->countConfirmed;
		}

	}
})();

