(function(){
    "use strict";

    /**
     * @ngdoc controller
     * @name workshops:workshopBookingsCtrl
     *
     * @description
     *
     *
     * */
    angular.module('workshops').controller('workshopBookingsCtrl',workshopBookingsCtrl);

	workshopBookingsCtrl.$inject = ["$rootScope", "$confirm", "$filter", "RequestError", "localStorageService", "WorkshopBooking"];

	function workshopBookingsCtrl($rootScope, $confirm, $filter, RequestError, localStorageService, WorkshopBooking) {

        var self = this;
        self.stateFilter = false;
        self.workshopFilter = '';
        self.workshopFilterDisplay = '';
        self.bookings = WorkshopBooking.query(angular.noop,RequestError.handle);
        self.events = WorkshopBooking.getEvents();
        self.itemsPerPage = localStorageService.get('itemsPerPage') || 20;
        self.paginator = [5, 10, 20, 50, 100];

        self.filteredBookings = filteredBookings;

        self.setStateFilter = setStateFilter;
        self.stateOptionsClass = stateOptionsClass;
        self.stateOptionsName = stateOptionsName;
        self.setBookingState = setBookingState;

        self.setWorkshopFilter = setWorkshopFilter;
        self.setItemsPerPage = setItemsPerPage;

	    self.hasRightToEdit = hasRightToEdit;
        self.deleteBooking = deleteBooking;
		self.updateBooking = updateBooking;

		self.resetCheckboxes = resetCheckboxes;
		self.toggleCheckbox = toggleCheckbox;
		self.isChecked = isChecked;
		self.somethingChecked = somethingChecked;
		self.checkedBookings = checkedBookings;
		self.checkedBoxes = [];


        self.stateOptions = [
            {value: "reserviert", name: "Reserviert", css:"info"},
            {value: "bestätigt", name: "Bestätigt", css:"primary"},
            {value: "abgeholt", name: "Abgeholt", css:"success"},
            {value: "Warteliste", name: "Warteliste", css:"warning"},
            {value: "abgelehnt", name: "Abgelehnt", css:"danger"}
        ];


	    function hasRightToEdit() {
		    return $rootScope.can(['admin-administrate', 'workshops-manage']);
	    }

        /**
         * Setter for state filter
         * @param state
         */
        function setStateFilter(state) {
            self.stateFilter = state;
        }

        /**
         * Returns the value for the color of the state options
         * @param value
         * @return {*}
         */
        function stateOptionsClass(value) {
            for (var i=0;i<self.stateOptions.length;i++) {
                if (self.stateOptions[i].value == value) return "btn-"+self.stateOptions[i].css;
            }
            return "btn-default";
        }

        /**
         * Returns the value for the name of the state options
         * @param value
         * @return {*}
         */
        function stateOptionsName(value) {
            for (var i=0;i<self.stateOptions.length;i++) {
                if (self.stateOptions[i].value == value) return self.stateOptions[i].name;
            }
            return "Status setzen!";
        }

        /**
         * This is the filter that is used here
         * @return {*}
         */
        function filteredBookings() {
            if (self.stateFilter === false) return self.bookings;
            var bookings = [];
            angular.forEach(self.bookings, function(booking) {
                if (!self.stateFilter || booking.status == self.stateFilter.value) {
                    bookings.push(booking);
                }
            });
	        return bookings;
        }

		function getBookings(ids) {
			var bookings = [];

			angular.forEach(self.filteredBookings(), function (booking) {
				if (ids.indexOf(booking.id) >= 0) {
					bookings.push(booking);
				}
			});

			return bookings;
		}

		function updateBooking(booking) {

			booking.$update(function () {
				console.log("success");
			}, function () {
				alert("Achtung: es ist etwas schiefgelaufen beim Speichern!");
			});
		}

        /**
         * Setter for the application state
         * @param booking
         * @param state
         */
        function setBookingState(ids, state)
        {

	        var bookings;

	        if (ids.id) {
		        bookings = [ids];
	        } else {
		        bookings = getBookings(ids);
	        }

            // Transition from waiting to reserved
	        if (state == 'reserviert' && bookings.status == 'Warteliste') {
                $confirm({
                    text: 'Teilnehmer bekommt eine Email mit Zahlungsdaten zugeschickt'
                }).then(function () {
	                var messages = "";
	                for (var i = 0; i < bookings.length; i++) {
		                bookings[i].$moveUp().then(function (result) {
			                bookings[i] = result;
			                if (bookings[i].message) {
				                messages += "\n" + bookings[i].message;
			                }
		                });
	                }
	                if (messages.length) alert(messages);
                });
                // Workshop has been payed
            } else if (state == 'bestätigt') {

		        console.log(bookings);
		        var confirmFor = [];

		        for (var i = 0; i < bookings.length; i++) {
			        confirmFor.push(
				        'Zahlungseingang für ' + bookings[i].vorname + ' ' + bookings[i].nachname + ' bestätigen ' +
				        '(Verwendungszweck: ' + bookings[i].reference + ', Betrag: ' +
				        bookings[i].workshop_event.workshop.kosten + '€). ');
		        }

                $confirm({
	                text: confirmFor.join("\n") + 'Eine Bestätigungsmail wird versandt.'
                }).then(function () {
	                var messages = "";
	                for (var i = 0; i < bookings.length; i++) {
		                bookings[i].$confirm().then(function (result) {
			                bookings[i] = result;
			                if (bookings[i].message) {
				                messages += "\n" + bookings[i].message;
			                }
		                });
	                }
	                if (messages.length) alert(messages);
                });
	        } else if (state == 'abgelehnt') {
		        for (var i = 0; i < bookings.length; i++) {
			        bookings[i].setToCancelled = true;
			        bookings[i].status = state;
			        bookings[i].$update();
		        }
	        } else {
		        for (var i = 0; i < bookings.length; i++) {
			        bookings[i].status = state;
			        bookings[i].$update();
		        }
	        }

	        resetCheckboxes();

        }

        function setWorkshopFilter(workshop)
        {
            // Extract workshop name & display from full description
            if (workshop.length > 40) {
                self.workshopFilterDisplay = workshop.substring(0, 40) + '...';
            } else {
                self.workshopFilterDisplay = workshop;
            }
            self.workshopFilter = workshop;
	        resetCheckboxes();
        }

        function setItemsPerPage(itemsPerPage) {
            self.itemsPerPage = itemsPerPage;
            localStorageService.set("itemsPerPage", itemsPerPage);
	        resetCheckboxes();
        }

        /**
         * deletes a workshop booking
         * @param booking
         * @param idx
         */
        function deleteBooking(booking,idx) {
            $confirm({text:"Möchtest Du diesen Eintrag wirklich komplett löschen?"}).then(function(){
                booking.$delete(function(){
                    self.bookings.splice(idx, 1);
                });
            });
	        resetCheckboxes();
        }


		function toggleCheckbox($index) {
			if (isChecked($index)) {
				self.checkedBoxes.splice(self.checkedBoxes.indexOf($index), 1);
			} else {
				self.checkedBoxes.push($index);
			}
			return false;
		}

		function isChecked($index) {
			return self.checkedBoxes.indexOf($index) >= 0;
		}

		function resetCheckboxes() {
			self.checkedBoxes = [];
		}

		function somethingChecked() {
			return self.checkedBoxes.length;
		}

		function checkedBookings() {
			return getBookings(self.checkedBoxes);
		}

    }
})();

