/**
 * Renders photos for marktanmeldung
 */
festivalApp.directive("applicationFoto",function(){

    return {
        restrict: "A",
        template: "<img ng-src='{{src}}' style='width: 100%;float:left;'>",
        scope: {},
        link: function(scope, elem, attr){
            var foto = angular.fromJson(attr.applicationFoto)[0];
            scope.src = "https://ancient-trance.de"+foto.file;
        }
    }

});
