angular.module('team').controller("groupMessageModalCtrl",[
    "$uibModalInstance","clipboard","members","fields",function($uibModalInstance,clipboard,members,fields){
        var self = this;

        var ids = [];

        self.members = members;

        var mobile = [];
        var email = [];

        angular.forEach(members,function(member) {
            if (member[fields.mobile] && member[fields.mobile].length) mobile.push(member[fields.mobile]);
            if (member[fields.email] && member[fields.email].length) email.push(member[fields.email]);
        });

        self.export = {
            mobile : mobile.join(","),
            email : email.join(",")
        };

        self.copy = function(e) {
            clipboard.copyText(e);
        };

        self.closeModal = function() {
            $uibModalInstance.dismiss('cancel');
        };

}]);