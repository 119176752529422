angular.module('persons').controller("passGeneratorCtrl",[
    "$scope","$uibModalInstance","$http","$window","Person","VolunteerResort","Pass","Image",'currentPerson','passType',
    function($scope,$uibModalInstance,$http,$window,Person,VolunteerResort,Pass,Image,currentPerson,passType){
        var self = this;

        console.log(passType);

        self.currentPerson = currentPerson;
        self.persons = null;
        self.volunteerResorts = [];
        self.pass = passType;
        self.image = null;

        if (self.pass.type == "crew") {
            self.volunteerResorts = VolunteerResort.query();
        }

        var days = ["pre","friday","saturday","sunday","post"];


        self.toggleCateringSelection = function(name,value){
            var i = self.pass.catering_options[name].indexOf(value);
            if (i > -1) {
                self.pass.catering_options[name].splice(i,1);
            } else {
                self.pass.catering_options[name].push(value);
            }
        };

        if (self.pass.id) {
            self.passImage = null;
            $http.get("/api/web/passes/image/"+self.pass.id).then(function(r){
                self.pass.imageSrc = r.data.src;
                self.passImage = passType.imageSrc;
                self.image = r.data;
            });

        } else {
            self.passImage = passType.imageSrc?passType.imageSrc:null;
        }

        /**
         * Returns the flags on the preview
         * @param name
         * @param value
         * @returns {*}
         */
        self.mealClassOf = function(name,value) {
            if (name == "catering_pre" || name == "catering_post") {
                return (self.pass.catering_options[name] == 1) ? true : false;
            } else {
                return (self.pass.catering_options[name].indexOf(value) >= 0) ? "hasMeal":"";
            }
        };

        self.passTypeName = function(){
            switch (self.pass.type) {
                case "stage" :
                    return "STAGE";
                case "press" :
                    return "PRESS";
                case "artist" :
                    return "ARTIST";
                case "crew"   :
                    return "CREW";
                case "staff"  :
                    return "ORGA";
            }
        };

        self.resetPassType = function(){
            if (self.pass.type == "staff") {
                self.pass.days = days;
                self.pass.access = "staff";
            } else if (self.pass.type == "artist") {
                self.pass.days = [];
                self.pass.access = "stage";
            } else if (self.pass.type == "stage") {
                self.pass.days = ["friday","saturday","sunday"];
                self.pass.access = "stage";
            } else if (self.pass.type == "crew") {
                self.pass.days = [];
                self.pass.access = "crew1";
                if (!self.volunteerResorts.length) {
                    self.volunteerResorts = VolunteerResort.query();
                }
            } else if (self.pass.type == "press") {
                self.pass.days = [];
                self.pass.access = "press";
            }
            self.resetCateringDays();
        };

        self.resetCateringDays = function(){

            for (var i=0;i<days.length;i++) {
                if (self.pass.days.indexOf(days[i]) > -1) {
                    if (days[i] == "pre" || days[i] == "post") {
                        self.pass.catering_options["catering_"+days[i]] = 1;
                    } else {
                        self.pass.catering_options["catering_"+days[i]] = [1,2,3];
                    }
                } else {
                    if (days[i] == "pre" || days[i] == "post") {
                        self.pass.catering_options["catering_"+days[i]] = 0;
                    } else {
                        self.pass.catering_options["catering_"+days[i]] = [];
                    }
                }
            }
        };

        /**
         * On Update the image
         * @param file
         */
        self.updateImage = function(file){
            self.pass.imageSrc = file;
            $scope.$apply();
        };

        /**
         * On Change the form
         */
        self.onChangePersonScope = function(){
            if (!self.persons) self.persons = Person.query();
        };

        /**
         * On Save the pass
         */
        self.save = function(format){
            console.log('Saving pass.');
            var pass = new Pass(self.pass);
            console.log(pass);
            if (pass.id) {
                pass.$update(function(pass){
                    if (self.passImage){
                        var image = new Image(self.image);
                        console.log(image);
                        image.src = self.passImage;
                        image.imageable_id = pass.id;
                        image.imageable_type = pass.model;
                        image.alt = self.pass.name;
                        if (image.id) {
                            image.$update(function () {
                                $window.open('/passes/'+format+'/'+pass.id+'.pdf');
                                $uibModalInstance.close();
                            });
                        } else {
                            image.$save(function(image){
                                $window.open('/passes/'+format+'/'+pass.id+'.pdf');
                                $uibModalInstance.close();
                            });
                        }
                    } else {
                        $window.open('/passes/'+format+'/'+pass.id+'.pdf');
                        $uibModalInstance.close();
                    }
                });
            } else {
                pass.$save(function(pass){

                    if (self.passImage){

                        var image = new Image();
                        image.src = self.passImage;
                        image.imageable_id = pass.id;
                        image.imageable_type = pass.model;
                        image.alt = self.pass.name;
                        image.$save(function(image){
                            $window.open('/passes/'+format+'/'+pass.id+'.pdf');
                            $uibModalInstance.close();
                        });

                    } else {
                        $window.open('/passes/'+format+'/'+pass.id+'.pdf');
                        $uibModalInstance.close();
                    }
                });

            }

        };

        /**
         * On Close the modal
         */
        self.closeModal = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }]);