(function () {

	/**
	 * Shows a Favicon
	 */
	festivalApp.directive("ctrlUpFocus", ctrlUpFocus);

	ctrlUpFocus.$inject = ["hotkeys"];

	function ctrlUpFocus(hotkeys) {

		return {
			restrict: "A",
			scope: true,
			link: function (scope, elem, attr) {
				hotkeys.add({
					combo: 'ctrl+up',
					description: 'Search again',
					callback: function () {
						console.log(elem[0]);
						elem[0].focus();
					}
				});
			}
		}

	}

})();
