// describe('Controller: artistsCtrl.artistsCtrl', function () {
//
//     // load the controller's module
//     beforeEach(module('artistsCtrl'));
//
//     var ctrl,
//         scope;
//
//     // Initialize the controller and a mock scope
//     beforeEach(inject(function ($controller, $rootScope) {
//         scope = $rootScope.$new();
//         ctrl = $controller('artistsCtrl', {
//             $scope: scope
//         });
//     }));
//
//     it('should be defined', function () {
//         expect(ctrl).toBeDefined();
//     });
// });
