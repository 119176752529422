angular.module('volunteers')
    .value("volunteerRatingValue",[
        {
            id:1,
            name:"bad",
            short_description:"Festivalschädigend",
            description:"Hat sich festivalschädigend verhalten",
            icon: "ban",
            class: "danger"
        },
        {
            id:2,
            name:"poor",
            short_description:"Kein Ticket",
            description:"Hat zwar mitgemacht, aber für Ticketerstattung noch nicht ausreichend geholfen",
            icon: "star-o",
            class: "warning"
        },
        {
            id:3,
            name:"ok",
            short_description:"Ausreichend",
            description:"Hat zufriedenstellend geholfen",
            icon: "star-half-o",
            class: "default"
        },
        {
            id:4,
            name:"good",
            short_description:"Gut",
            description:"Hat gut geholfen",
            icon: "star",
            class: "info"
        },
        {
            id:5,
            name:"excellent",
            short_description:"Exzellent",
            description:"Hat hervorragend geholfen",
            icon: "trophy",
            class: "success"
        }
    ]);