angular.module('messages')
	.controller('messageCreatorCtrl',
		["$uibModalInstance", "$http", "$window", "route", "message",
			function ($uibModalInstance, $http, $window, route, message) {

				var self = this;

				self.message = "";
				self.placeholder = "Hier hast Du Platz für eine (zusätzliche) Nachricht an den Empfänger der Mail.";
				self.title = "Nachricht an den Empfänger";
				self.button = "Nachricht Versenden";

				if (message) {
					if (message.message) self.message = message.message;
					if (message.placeholder) self.placeholder = message.placeholder;
					if (message.title) self.title = message.title;
					if (message.button) self.button = message.button;
				}

				self.create = function() {
					$http.post(route,{message:self.message}).then(function(result){
						$uibModalInstance.close();
					});
				};

				self.close = function () {
					$uibModalInstance.close();
				}

		}]);