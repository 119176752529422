/**
 * Shows a Favicon
 */
festivalApp.directive("merchantType",function(){

    return {
        restrict: "A",
        template: "<span ng-repeat='typ in type' uib-tooltip='{{typ}}'><i class='fa {{typeIcon(typ)}}'></i></span>",
        scope: {
            types: "@"
        },
        link: function(scope) {
            scope.type = angular.fromJson(scope.types);
            scope.typeIcon = function(type) {
                switch (type) {
                    case "selbstproduzent" : return "fa-gavel";
                    case "mitmachangebot" : return "fa-child";
                    case "teilproduzent" : return "fa-star-half-o";
                    case "gastronom" : return "fa-cutlery";
                    case "regional" : return "fa-home";
                    case "haendler" : return "fa-industry";
                }
            }
        }
    }

});
