/**
 * Renders an icon for a gender
 */
festivalApp.directive("gendericon",function(){

    return {
        restrict: "E",
        template: "<i ng-class='[\"fa\",genderIconClass]'></i>",
        scope: {gender:"="},
        link: function(scope){
            switch (scope.gender) {
                case "m" :
                    scope.genderIconClass = "fa-mars";
                    break;
                case 'w' :
                    scope.genderIconClass = "fa-venus";
                    break;
                default :
                    scope.genderIconClass = "fa-genderless";
            }

        }
    }

});
