/**
 * @ngdoc controller
 * @name stages:stagesCtrl
 *
 * @description
 *
 *
 * @requires self
 * */
angular.module('crud')
    .controller('crudIndexCtrl',
        ["$rootScope","$confirm","$uibModal","RequestError","Resource",
            function($rootScope,$confirm,$uibModal,RequestError,Resource){

                var self = this;

                self.items = Resource.query(angular.noop,RequestError.handle);

                /**
                 * Performs the DELETE MEMBER action
                 * @param member
                 */
                    self.delete = function(item) {
                        $confirm({text:"Möchtest Du diesen Eintrag wirklich löschen?"}).then(function(){
                            var id = item.id;
                            item.$delete(function(){
                                for (var i=0;i<self.items.length;i++)
                                    if (self.items[i].id == id) self.items.splice(i,1);
                            });
                        });
                    };

            }]);
