/**
 * @ngdoc controller
 * @name market:marketApplicationsCtrl
 *
 * @description
 *
 *
 * @requires $scope
 * */
angular.module('market')
    .controller('marketApplicationsCtrl',
	    ["$rootScope", "$scope", "$http", "$confirm", "$uibModal", "localStorageService", "RequestError", "MarketApplication", "season", "$log",
		    function ($rootScope, $scope, $http, $confirm, $uibModal, localStorageService, RequestError, MarketApplication, season, $log) {

        $scope.application = {};
        $scope.title = "";
        if (season == "current") {
            $scope.applications = MarketApplication.query(function(d){},RequestError.handle);
            $scope.title = "Aktuelle Marktanmeldungen";
        } else {
	        $scope.applications = MarketApplication.past({id:season},function(d){},RequestError.handle);
	        $scope.title = "Marktanmeldungen <strong>"+season+"</strong>";
        }

			    $scope.pageChanged = function () {
            $scope.from = ($scope.currentPage - 1) * $scope.numPerPage;

			    }

        $scope.stateOptions = [
	        {value: -3, name: "Abgesagt", icon: 'fa-ban', class: 'danger'},
                {value: -2,name: "Abgelehnt",icon:'fa-thumbs-down',class:'danger'},
                {value: -1,name: "Warteliste",icon:'fa-clock-o',class:'warning'},
                {value: 0,name: "Unbearbeitet",icon:'fa-spinner',class:'default'},
                {value: 1,name: "Angenommen",icon:'fa-thumbs-up',class:'primary'},
                {value: 2,name: "Vertrag zurück",icon:'fa-certificate',class:'success'},
                {value: 3,name: "Vorausz. erhalten",icon:'fa-money',class:'success'},
                {value: 4,name: "Vertr. & Vorausz.",icon:'fa-check',class:'success'},
                {value: 5,name: "Abgerechnet",icon:'fa-flag-checkered',class:'info'}
        ];

        $scope.stateFilter = localStorageService.get("marketApplicationsStateFilter") ? localStorageService.get("marketApplicationsStateFilter") : false;
        $scope.setStateFilter = function(state) {
            $scope.stateFilter = state;
            localStorageService.set("marketApplicationsStateFilter",state);
        };

        $scope.filteredApplications = function() {
            if ($scope.stateFilter === false) return $scope.applications;
            var applications = [];
            angular.forEach($scope.applications,function(application) {
                if ($scope.stateFilter.value > 0) {
                    if (parseInt(application.bewerbung_angenommen) >= parseInt($scope.stateFilter.value)) applications.push(application);
                } else {
                    if (parseInt(application.bewerbung_angenommen) == parseInt($scope.stateFilter.value)) applications.push(application);
                }
            });

            return applications;

        };

        $scope.tableType = !localStorageService.get("marketApplicationsTableType") ? "offer" : localStorageService.get("marketApplicationsTableType");
        $scope.tableDetails = function(type){
            $scope.tableType = type;
            localStorageService.set('marketApplicatiosnTableType',type);
        };
        $scope.detailButtonClass = function(type) {
            return $scope.tableType == type ? "active" : "";
        };

        $scope.setApplicationState = function(application,state) {
            application.bewerbung_angenommen = state;
            application.$update();
        };

        $scope.applicationStateOf = function(application) {
            for (var i=0;i<$scope.stateOptions.length;i++) {
                if ($scope.stateOptions[i].value === application.bewerbung_angenommen) return $scope.stateOptions[i];
            }
        };

        $scope.details = function (application) {
            $scope.application = application;
            $uibModal.open({
                animation: true,
                templateUrl: "/templates/applicationView.html?as",
                scope: $scope
            }).result.then(function(){
                $scope.update(application);
            });
        };

        $scope.update = function(application) {
            application.$update(function(a){
                application = a;
            });
        };

        $scope.isAccepted = function(application) {
            return application.bewerbung_angenommen >= 1;
        };

        $scope.delete = function(application,idx) {
            $confirm({text:"Möchtest Du diesen Eintrag wirklich komplett löschen?"}).then(function(){
                application.$delete(function(){
                    $scope.applications.splice(idx, 1);
                });
            });
        };

	        $scope.messageTo = function (application) {
		        self.createEmailNotificationModal = $uibModal.open({
			        animation: true,
			        controller: "messageCreatorCtrl",
			        controllerAs: "ctrl",
			        templateUrl: "/templates/messageCreatorForm.html?2",
			        resolve: {
				        route: function () {
					        return "/api/web/market_applications/" + application.id + "/send_mail/plain";
				        },
				        message: function () {
					        return {
						        placeholder: "Gebe hier Deine Nachricht ein.",
						        button: "Abschicken",
						        title: "Deine Mail",
					        };
				        }
			        }
		        });
	        };

	        $scope.infomail = function (application) {
		        self.createEmailNotificationModal = $uibModal.open({
			        animation: true,
			        controller: "messageCreatorCtrl",
			        controllerAs: "ctrl",
			        templateUrl: "/templates/messageCreatorForm.html?3",
			        resolve: {
				        route: function () {
					        return "/api/web/market_applications/" + application.id + "/send_mail/info";
				        },
				        message: function () {
					        return {
						        placeholder: "Gebe hier Deine Nachricht ein.",
						        button: "Abschicken (inkl. Anhänge)",
						        title: "Infomail (mit Park-Karte und Anfahrt)",
						        message: "bla bla"
					        };
				        }
			        }
		        });
	        };


        $scope.invoice = function(application) {
            self.createInvoiceModal = $uibModal.open({
                animation: true,
                controller: "invoiceCreatorCtrl",
                controllerAs: "ctrl",
                templateUrl: "/templates/invoiceCreatorForm.html?2",
                resolve: {
                    invoice: function(){
                        return {
                            company:application.firma,
                            firstname:application.vorname,
                            lastname:application.nachname,
                            address1:application.strasse,
                            zip:application.postleitzahl,
                            place:application.ort
                        };
                    }
                }
            });
        };

        $scope.notificate = function(application) {
            self.createEmailNotificationModal = $uibModal.open({
                animation: true,
                controller: "messageCreatorCtrl",
                controllerAs: "ctrl",
                templateUrl: "/templates/messageCreatorForm.html?2",
                resolve: {
                    application: function(){
                        return application;
                    },
                    route: function() {
                        $log.info(application);
                        var type;
                        if (application.bewerbung_angenommen == -1) {
                            type = "waiting";
                        } else if (application.bewerbung_angenommen == -2) {
                            type = "rejection";
                        } else {
                            type = "confirmation";
                        }
                        return "/api/web/market_applications/"+application.id+"/send_mail/"+type;
                    },
	                message: function () {
		                return {
			                placeholder: "Gebe hier zusätzliche Infos für den Händler ein.",
			                button: "Abschicken",
			                title: "Vertrag bereitstellen",
		                };
	                }
                }
            });
        };

        $scope.hasRightToEdit = function(id){
            return ($rootScope.hasRole(["admin","market_manager"]) || id == $rootScope.myself.id);
        };

        $scope.setAdditionalTickets = function(application,count) {
            application.zusatztickets = count;
            $scope.update(application);
        }

			    $scope.sendWelcomeMail = function () {
				    $http.post("/api/web/market_applications/send_mail/info", {},
					    function (d) {
						    alert("Die Mails werden nun versandt.");
					    },
					    function (d) {
						    alert("Fehler! Bitte informiere Deinen Admin");
					    });
			    }

    }]);