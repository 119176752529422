/**
 * @ngdoc controller
 * @name workshopRooms:workshopRoomsCtrl
 *
 * @description
 *
 *
 * */
angular.module('workshops')
    .controller('workshopRoomsCtrl',
        ["$rootScope","$confirm","$routeParams","$uibModal","RequestError","WorkshopRoom",
            function($rootScope,$confirm,$routeParams,$uibModal,RequestError,WorkshopRoom){

                var self = this;

                self.rooms = WorkshopRoom.query(angular.noop,RequestError.handle);

	            self.hasRightToEdit = function () {
		            return $rootScope.can(['admin-administrate', 'workshops-manage']);
	            }

            }]);
