angular.module('team')
	.factory('Address', ["$resource", function ($resource) {
		return $resource('/api/web/addresses/:id', {id: '@id'}, {
			update: {
				method: 'PUT'
			},
			query: {
				method: 'GET',
				isArray: true
			}
		});
	}]);