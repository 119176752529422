(function () {

	"use strict";

	angular.module('pointOfSale').controller('posSalesDashboardCtrl', posSalesDashboardCtrl);

	posSalesDashboardCtrl.$inject = ["$rootScope", "$scope", "$confirm", "localStorageService", "$routeParams", "$uibModal", "RequestError", "PosClient", "PosProduct", "PosTransactions"];

	function posSalesDashboardCtrl($rootScope, $scope, $confirm, localStorageService, $routeParams, $uibModal, RequestError, PosClient, PosProduct, PosTransactions) {

		var self = this;

		self.clients = PosClient.query();
		self.products = PosProduct.query();

		self.allDaily = {};
		self.tableOrder = {};
		self.displayOptions = {};

		self.totalForDayByChannel = totalForDayByChannel;
		self.splitProductsByChannel = splitProductsByChannel;
		self.channelName = channelName;
		self.orderForTable = orderForTable;
		self.setOrderForTable = setOrderForTable;
		self.joinProductsByLocation = joinProductsByLocation;
		self.getStateForOption = getStateForOption;
		self.toggleView = toggleStateForOption;

		function getStateForOption(day, option) {

			if (typeof self.displayOptions[day + option] === "undefined") {
				self.displayOptions[day + option] = true;
			}

			return self.displayOptions[day + option] ? 'active' : '';
		}

		function toggleStateForOption(day, option) {
			self.displayOptions[day + option] = !self.displayOptions[day + option];
		}

		function channelName(id) {
			if (id == 1) return "<i class='fa fa-ticket'></i>";
			if (id == 2) return "<i class='fa fa-glass'></i>";
			if (id == "total") return "<i class='fa fa-globe'></i>";
		}

		/**
		 * Returns the order for a particular table.
		 * Creates the order object if not existent.
		 * @param tableName
		 * @param id
		 * @return {*}
		 */
		function orderForTable(tableName, id) {

			if (!self.tableOrder[tableName]) {
				self.tableOrder[tableName] = {};
			}

			if (!self.tableOrder[tableName][id]) {
				self.tableOrder[tableName][id] = 'position';
			}

			return self.tableOrder[tableName][id];
		}

		/**
		 * Sets a particular table order
		 * @param tableName
		 * @param id
		 * @param order
		 */
		function setOrderForTable(tableName, id, order) {
			self.tableOrder[tableName][id] = (self.tableOrder[tableName][id] == order) ? "-" + order : order;
		}

		function splitProductsByChannel(products) {

			var channels = {};

			angular.forEach(products, function (product) {
				if (!channels[product.channel_id]) {
					channels[product.channel_id] = [];
				}

				channels[product.channel_id].push(product);

			});

			return channels;

		}

		/**
		 * Generalized function to merge products and add the values together
		 * @param source
		 * @param target
		 * @param assoc
		 */
		function joinProducts(source, target, assoc) {

			angular.forEach(source, function (product) {
				if (!assoc[product.name]) {
					target.push(product);
				} else {
					var offset = assoc[product.name];
					target[offset].amount += product.amount;
					target[offset].sum_gross += product.sum_gross;
					target[offset].sum_net += product.sum_net;
				}

			});

		}

		function joinProductsByLocation(clients) {

			var productToArrayOffset = {};

			var locationFor = function (name) {
				return name.substr(0, (name.length - 1));
			};

			var locations = {};

			angular.forEach(clients, function (client) {

				var name = locationFor(client.name);

				if (!locations[name]) {

					locations[name] = [];
					productToArrayOffset[name] = {};

				}

				joinProducts(client.products, locations[name], productToArrayOffset[name]);

			});

			console.log(locations);

			return locations;

		}

		/**
		 * Calculates the DayResult for a particular sales channel (for example bar or tickets)
		 * @param dayResult
		 * @return {{total: {amount: number, sum_gross: number, sum_net: number}}}
		 */
		function totalForDayByChannel(dayResult) {
			var byChannel = {
				total: {
					amount: 0,
					sum_gross: 0,
					sum_net: 0
				}
			};

			angular.forEach(dayResult.products, function (product) {
				if (!byChannel[product.channel_id]) {
					byChannel[product.channel_id] = {
						amount: 0,
						sum_gross: 0,
						sum_net: 0
					};
				}

				byChannel.total.sum_gross += product.sum_gross;
				byChannel[product.channel_id].sum_gross += product.sum_gross;
				byChannel.total.sum_net += product.sum_net;
				byChannel[product.channel_id].sum_net += product.sum_net;
				byChannel.total.amount += product.amount;
				byChannel[product.channel_id].amount += product.amount;

			});

			return byChannel;

		}

		PosTransactions.allDaily(function (result) {
			self.allDaily = result;
			console.log(result);
		});


	}

})();
