angular.module('volunteers')
    .value("volunteerStatusValue",[
        {
            id:0,
            name: 'Anmeldestatus',
            states: [
                {
                    id: 0,
                    description: 'Unbestätigt',
                    name: 'Unbestätigt',
                    class: 'info'
                },
                {
                    id: 1,
                    description: 'Bestätigt',
                    name: 'Bestätigt',
                    class: 'success'
                },
                {
                    id: 2,
                    description: 'Warteliste',
                    name: 'Warteliste',
                    class: 'warning'
                },
                {
                    id: 3,
                    description: 'Abgesagt',
                    name: 'Abgesagt',
                    class: 'danger'
                },
                {
                    id: 4,
                    description: 'No show',
                    name: 'No show',
                    class: 'danger'
                },
            ]
        },
        {
            id:1,
            name:"Kontaktstatus",
            states: [
                {
                    id: 10,
                    description: 'Noch nicht kontaktiert',
                    name: 'Nicht kontaktiert',
                    class: 'info'
                },
                {
                    id: 11,
                    description: 'Persönlicher Kontakt für Zuordnung eingeleitet',
                    name: 'Kontakt eingeleitet',
                    class: 'primary'
                },
                {
                    id: 13,
                    description: 'Seit zwei Wochen keine Antwort',
                    name: 'Keine Antwort (2 Wo.)',
                    class: 'danger'
                },
                {
                    id: 12,
                    description: 'Helfer hat vorgeschlagenen Bereich abgelehnt',
                    name: 'Bereich abgelehnt',
                    class: 'warning'
                },
                {
                    id: 14,
                    description: 'Bereich fest',
                    name: 'Bereich fest',
                    class: 'success'
                },
                {
                    id: 15,
                    description: 'Hat noch Kapazitäten für weitere Aufgaben',
                    name: 'Hat noch Kapazitäten',
                    class: 'info'
                }
            ]
        },
        {
            id:2,
            name:"Checkin-Status",
            states: [
                {
                    id: 20,
                    description: 'Noch nicht eingecheckt',
                    name: 'Nicht eingecheckt',
	                class: 'danger'
                },
                {
                    id: 21,
                    description: 'Ist vor Ort',
                    name: 'vor Ort',
	                class: 'warning'
                },
                {
                    id: 22,
                    description: 'Bändchen erhalten',
                    name: 'Bändchen erhalten',
                    class: 'primary'
                },
                {
                    id: 23,
                    description: 'Ausweis erhalten',
                    name: 'Ausweis erhalten',
                    class: 'primary'
                },
	            {
		            id: 24,
		            description: 'Bändchen & Ausweis erhalten',
		            name: 'Bändchen & Ausweis erhalten',
		            class: 'info'
	            },
	            {
		            id: 25,
                    description: 'Nachbereitung abgeschlossen',
                    name: 'Nachbereitung abgeschlossen',
                    class: 'success'
                },
            ]
        },
        {
            id:3,
            name:"Erstattungsstatus",
            states: [
                {
                    id: 30,
                    description: 'Nicht erstattet',
                    name: 'Nicht erstattet',
                    class: 'warning'
                },
                {
                    id: 31,
                    description: 'Mithilfe von Fokulizer bestätigt',
                    name: 'Mithilfe bestätigt',
                    class: 'info'
                },
                {
                    id: 34,
                    description: 'Bar ausgezahlt',
                    name: 'Bar ausgezahlt',
                    class: 'success'
                },
                {
                    id: 35,
                    description: 'Ticketbefreit',
                    name: 'Ticketbefreit',
                    class: 'success'
                },
                {
                    id: 32,
                    description: 'Überweisung in Auftrag gegeben',
                    name: 'Überweisung beauftragt',
                    class: 'primary'
                },
                {
                    id: 33,
                    description: 'Überweisung bestätigt / in Bar erstattet',
                    name: 'Überweisung bestätigt',
                    class: 'success'
                }
            ]
        }
    ]);