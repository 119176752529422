(function () {

	"use strict";

	festivalApp.directive("inlineFieldEditor", inlineFieldEditor);

	function inlineFieldEditor() {
		return {

			restrict: "E",
			templateUrl: "/templates/inlineFieldEditor.html?1",
			transclude: true,
			scope: {
				model: "=",
				field: "@",
				editButtonCaption: "@",
				showButtonWhen: "=",
				onSave: "="
			},
			link: function (scope) {

				scope.showEditForm = false;

				scope.saveChanges = function () {
					scope.onSave(scope.model);
					scope.showEditForm = false;
				}

				scope.abort = function () {
					scope.showEditForm = false;
					if (scope.model[scope.field] != scope.oldValue) {
						scope.model[scope.field] = scope.oldValue;
					}
				}

				scope.doShowEditForm = function () {
					scope.oldValue = scope.model[scope.field];
					scope.showEditForm = true;
				}

			}
		}
	}

})();