angular.module('comments')
    .factory('Comment', function ($resource,DateConverter) {
        return $resource('/api/web/comments/:id', { id: '@id' }, {
            update : {
                method: 'PUT',
                interceptor: { response: DateConverter.parseResponseDates}
            },
            query: {
                method: 'GET',
                isArray: true,
                interceptor: { response: DateConverter.parseResponseDates}
            }
        });
    }).$inject = ["$resource","DateConverter"];