angular.module('users')
    .factory('Authentication',["$http", function ($http) {

        var _user = null;

        $http.get("/api/web/myself").then(function(result){
            _user = result;
        });

        return {

            user:function(){
                return _user;
            }

        };
    }]);