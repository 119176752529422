/**
 * Shows a table with persons
 */
festivalApp.directive("personOverview",["$filter","Person","PersonAccessRight","PersonType","PersonRelation",function($filter,Person,PersonAccessRight,PersonType,PersonRelation){

    return {
        restrict: "A",
        templateUrl: "/templates/personOverview.html",
        scope: {
            model: "=",
            addType: "@",
            addRight: "@",
            newPerson: "=",
            syncPersons: "&",
            multiple: "@"
        },
        link: function(scope) {

            /**
             * This one gives out a relation's property
             * given the foreign key and the a
             * @param relation - relation to lookup the attribute
             * @param attribute - attribute to show
             * @param foreign_key - integer of the foreign key constraint
             * @returns {*} the attribute
             */
            scope.attributeFromRelation = function(relation,attribute,foreign_key) {
                if (relation) {
                    for (var i=0;i<relation.length;i++) {
                        if (relation[i]["id"] == parseInt(foreign_key)) return relation[i][attribute];
                    }
                }
            };

            if (!scope.model.persons) scope.persons = [];

            // Multiple mode means that the model can have multiple persons attached.
            if (!scope.multiple && scope.model.persons.length && !scope.newPerson) {
                scope.newPerson = scope.persons[0];
            } else if (!scope.newPerson) {
                scope.newPerson = {};
            }

            PersonAccessRight.query(function(accessRights){
                scope.personAccessRights = accessRights;
                if (scope.addRight) {
                    var accessRight = $filter("filter")(scope.personAccessRights,{name:scope.addRight});
                    if (accessRight.length) {
                        scope.newPerson.personAccessRight = accessRight[0];
                        scope.newPerson.person_access_right_id = accessRight[0].id;
                    }
                }

            });


            PersonType.query(function(personTypes){
                // When addType exists in scope, we get the id from db.
                scope.personTypes = personTypes;
                if (scope.addType) {
                    var personType = $filter("filter")(scope.personTypes,{name:scope.addType});
                    if (personType.length) {
                        scope.newPerson.personType = personType[0];
                        scope.newPerson.person_type_id = personType[0].id;
                    }
                }

            });

            /**
             * Assigns the id's of that relation and rights
             * @param person - the person to process
             */
            var assignRelationIds = function(person) {
                person.person_access_right = person.personAccessRight.id;
                person.person_type = person.personType.id;
                person.personable_id = scope.model.id;
                person.personable_type = scope.model.model;
            };

            /**
             * Function that runs when a person is newly registered.
             * It will create a new person.
             */
            scope.addPersonToModel = function() {
                var person = new Person(scope.newPerson);
                assignRelationIds(person);

                person.$save(function(person){
                    scope.model.persons.push(person);
                    scope.newPerson = {};
                });

            };

            /**
             * Updates an existing person
             */
            scope.updatePerson = function(){

                var person = new Person(scope.newPerson);
                assignRelationIds(person);

                person.$update(function(person){
                    // update person in model
                    for (var i=0;i<scope.model.persons.length;i++) {
                        if (scope.model.persons[i].id == person.id) {
                            scope.model.persons[i] = person;
                            break;
                        }
                    }
                    scope.newPerson = {};
                });
            }

            /**
             * This is called when we want to edit an existing entry
             * @param person
             */
            scope.editPerson = function(person) {
                scope.newPerson = person;
                scope.newPerson.personable_role = person.pivot.personable_role;
            }

            /**
             * Detaches a person from the current relation
             * ie remove a teacher from a workshop
             * @param index
             */
            scope.removePersonFromModel = function(index) {
                var person = scope.model.persons[index];

                var id = person.id+"|"+person.pivot.personable_id+"|"+scope.model.model.substr(4);
                // Create only a personable relation
                // and construct their mixed key.
                new PersonRelation({id:id})
                    .$delete(
                        function(response){
                            if (response.success) {
                                scope.model.persons.splice(index,1);
                            }
                            // todo: re use the person in another place?
                        },
                        function(response){
                            // log error
                            console.log(response);
                        }
                    );
            };


        }
    }

}]);
